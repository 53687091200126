import { locale } from '@/util'

export default function lockUnlockStatusDesc(value) {
  let desc = ''

  if (typeof value === 'number') {
    desc = locale.getMessage(`enumLockUnlockStatus[0].${value}`)
  }
  if (typeof value === 'boolean') {
    desc = locale.getMessage(`enumLockUnlockStatus[1].${value}`)
  }

  return desc
}
