<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.44736 11.4561C3.34026 11.4561 3.27604 11.5416 3.27604 11.6274V12.6125C3.27604 12.6981 3.36162 12.7838 3.44736 12.7838H10.7707C11.0277 12.2913 11.3703 11.8416 11.7558 11.4562L3.44736 11.4561Z"
      fill="black"
    />
    <path
      d="M3.44736 8.05127C3.34026 8.05127 3.27604 8.13686 3.27604 8.2226V9.20769C3.27604 9.31479 3.36162 9.37901 3.44736 9.37901H13.5545C13.6616 9.37901 13.7258 9.29343 13.7258 9.20769V8.2226C13.7258 8.1155 13.6402 8.05127 13.5545 8.05127H3.44736Z"
      fill="black"
    />
    <path
      d="M10.1283 14.8608H1.99125C1.62724 14.8608 1.32748 14.561 1.32748 14.197V6.05994H15.653V9.74304H15.8457C16.2311 9.74304 16.6166 9.78591 16.9806 9.85014V3.55461C16.9806 3.06214 16.7879 2.59104 16.4667 2.24839V2.22703H16.4453C16.0813 1.82014 15.5459 1.56325 14.9677 1.56325H13.5117V0.663776C13.5117 0.299768 13.2119 0 12.8479 0H11.8415C11.4775 0 11.1777 0.29978 11.1777 0.663776V1.54176H5.78155V0.663776C5.78155 0.299768 5.48177 0 5.11778 0H4.11135C3.74734 0 3.44757 0.29978 3.44757 0.663776V1.54176H1.99154C1.41334 1.54176 0.878006 1.79868 0.513978 2.20554H0.492469V2.2269C0.19269 2.56955 0 3.04065 0 3.53311V14.1756C0 15.2891 0.899362 16.1669 1.99137 16.1669H10.1285C10.1071 15.9314 10.0856 15.7172 10.0856 15.4816C10.0857 15.2891 10.1071 15.0749 10.1285 14.8609L10.1283 14.8608Z"
      fill="black"
    />
    <path
      d="M15.8457 11.0063C13.3617 11.0063 11.3489 13.0192 11.3489 15.5032C11.3489 17.9871 13.3618 20 15.8457 20C18.3297 20 20.3425 17.9871 20.3425 15.5032C20.3425 13.0192 18.3296 11.0063 15.8457 11.0063ZM15.8457 18.6723C14.1113 18.6723 12.6766 17.259 12.6766 15.5032C12.6766 13.7472 14.0899 12.3341 15.8457 12.3341C17.6017 12.3341 19.0149 13.7474 19.0149 15.5032C19.015 17.259 17.6017 18.6723 15.8457 18.6723Z"
      fill="black"
    />
    <path
      d="M17.4303 14.7752L16.5952 14.6468C16.5309 14.6468 16.4881 14.6039 16.4452 14.5397L16.0598 13.7688C15.9743 13.5761 15.7172 13.5761 15.6101 13.7688L15.2461 14.5183C15.2247 14.5826 15.1605 14.6039 15.0961 14.6254L14.2397 14.7539C14.047 14.7752 13.9612 15.0323 14.1112 15.1821L14.7108 15.7603C14.7536 15.8032 14.775 15.8674 14.775 15.9316L14.625 16.7881C14.5822 16.9808 14.7964 17.1521 14.989 17.045L15.7387 16.6383C15.8029 16.6169 15.8672 16.6169 15.91 16.6383L16.6596 17.0452C16.8309 17.1307 17.0449 16.9809 17.0236 16.7882L16.8736 15.9531C16.8736 15.8889 16.8736 15.8247 16.9378 15.7818L17.5588 15.1822C17.7087 15.0536 17.623 14.7967 17.4303 14.7752V14.7752Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'SpecialIcon'
}
</script>

<style scoped></style>
