import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper } from '@/util'

Vue.use(VueRouter)
Vue.use(VueMeta)
const homePage = { name: ROUTE_NAME.HOME }

const loginGuard = function(to, from, next) {
  uiHelper.isAuthenticated() ? next() : next(homePage)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/home/', redirect: { name: ROUTE_NAME.HOME }, pathToRegexpOptions: { strict: true } },
    { path: '/home', redirect: { name: ROUTE_NAME.HOME }, pathToRegexpOptions: { strict: true } },
    {
      path: '/:lang?/sc',
      name: ROUTE_NAME.SHORTCUT,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/sc/shortcut.vue')
    },
    {
      path: '/:lang?/reset-password',
      name: ROUTE_NAME.RESET_PASSWORD,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/resetPassword.vue')
    },
    {
      path: '/:lang?/contact-verification',
      name: ROUTE_NAME.CONTACT_VERIFICATION,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/contactVerification.vue')
    },
    {
      path: '/:lang?/register',
      name: ROUTE_NAME.REGISTER,
      meta: { layout: 'layout-master' },
      props: { registerErrorMsg: '', openRegisterDialog: () => {}, isMobileRegistration: true },
      component: () => import('@/components/member/register.vue')
    },
    {
      path: '/:lang?/login',
      name: ROUTE_NAME.LOGIN,
      meta: { layout: 'layout-master' },
      props: { loginErrorMsg: '', openLoginDialog: () => {}, openLanguageDialog: () => {}, closeAction: () => {}, openForgotPasswordDialog: () => {}, isMobileLogin: true },
      component: () => import('@/components/member/login.vue')
    },
    {
      path: '/:lang?/register-mobile',
      name: ROUTE_NAME.REGISTER_MOBILE,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/registerMobile.vue')
    },
    {
      path: '/:lang?/affiliate/referral',
      name: ROUTE_NAME.AFFILIATE_REFFERAL,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/affiliate/referral.vue')
    },
    {
      path: '/:lang?/sportsbook',
      component: () => import('@/views/sportsbook/sportsbookLayout.vue'),
      children: [
        {
          path: '/:lang?/sportsbook',
          name: ROUTE_NAME.SPORTSBOOK,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'sb' },
          component: () => import('@/views/game/game.vue')
        },
        {
          path: ':provider',
          name: ROUTE_NAME.SPORTSBOOK_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'sb' },
          component: () => import('@/views/game/game.vue')
        }
      ]
    },
    {
      path: '/:lang?/fishing',
      component: () => import('@/views/fishing/fishingLayout.vue'),
      children: [
        {
          path: '/:lang?/fishing',
          name: ROUTE_NAME.FISHING,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'fishing' },
          component: () => import('@/views/game/game.vue')
        },
        {
          path: ':provider',
          name: ROUTE_NAME.FISHING_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'fishing' },
          component: () => import('@/views/game/game.vue')
        }
      ]
    },
    {
      path: '/:lang?/crash',
      component: () => import('@/views/crash/crashLayout.vue'),
      children: [
        {
          path: '/:lang?/crash',
          name: ROUTE_NAME.CRASH,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'crash' },
          component: () => import('@/views/game/game.vue')
        },
        {
          path: ':provider',
          name: ROUTE_NAME.CRASH_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'crash' },
          component: () => import('@/views/game/game.vue')
        }
      ]
    },
    {
      path: '/:lang?/special',
      component: () => import('@/views/special/specialLayout.vue'),
      children: [
        {
          path: '/:lang?/special',
          name: ROUTE_NAME.SPECIAL,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/special/special.vue')
        },
        {
          path: ':provider',
          name: ROUTE_NAME.SPECIAL_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/special/special.vue')
        }
      ]
    },
    {
      path: '/:lang?/casino',
      component: () => import('@/views/casino/casinoLayout.vue'),
      children: [
        {
          path: '/:lang?/casino',
          name: ROUTE_NAME.CASINO,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'ld' },
          component: () => import('@/views/game/game.vue')
        },
        {
          path: ':provider',
          name: ROUTE_NAME.CASINO_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'ld' },
          component: () => import('@/views/game/game.vue')
        }
      ]
    },
    {
      path: '/:lang?/slot',
      component: () => import('@/views/slot/slotLayout.vue'),
      children: [
        {
          path: '/:lang?/slot',
          name: ROUTE_NAME.SLOT,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'rng' },
          component: () => import('@/views/slot/slot.vue')
        },
        {
          path: ':provider',
          name: ROUTE_NAME.SLOT_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'rng' },
          component: () => import('@/views/slot/slot.vue')
        },
        {
          path: ':provider/:gamename',
          name: ROUTE_NAME.SLOT_BY_PROVIDER_AND_GAMENAME,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'rng' },
          component: () => import('@/views/slot/slot.vue')
        },
        {
          path: 'game/:gamename',
          name: ROUTE_NAME.SLOT_BY_GAMENAME,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'rng' },
          component: () => import('@/views/slot/slot.vue')
        }
      ]
    },
    {
      path: '/:lang?/tablegames',
      component: () => import('@/views/table/tableLayout.vue'),
      children: [
        {
          path: '/:lang?/tablegames',
          name: ROUTE_NAME.TABLE_GAMES,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'table' },
          component: () => import('@/views/game/game.vue')
        },
        {
          path: ':provider',
          name: ROUTE_NAME.TABLE_GAMES_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'table' },
          component: () => import('@/views/game/game.vue')
        }
      ]
    },
    {
      path: '/:lang?/lottery',
      component: () => import('@/views/lottery/lotteryLayout.vue'),
      children: [
        {
          path: '/:lang?/lottery',
          name: ROUTE_NAME.LOTTERY,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'lottery' },
          component: () => import('@/views/game/game.vue')
        },
        {
          path: ':provider',
          name: ROUTE_NAME.LOTTERY_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'lottery' },
          component: () => import('@/views/game/game.vue')
        }
      ]
    },
    {
      path: '/:lang?/cricket',
      component: () => import('@/views/cricket/cricketLayout.vue'),
      children: [
        {
          path: '/:lang?/cricket',
          name: ROUTE_NAME.CRICKET,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'cricket' },
          component: () => import('@/views/game/game.vue')
        },
        {
          path: ':provider',
          name: ROUTE_NAME.CRICKET_BY_PROVIDER,
          meta: { layout: 'layout-master' },
          props: { providerSelected: 'cricket' },
          component: () => import('@/views/game/game.vue')
        }
      ]
    },
    {
      path: '/:lang?/playgame/:provider/:type',
      name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
      meta: { layout: 'blank-layout-master' },
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      },
      component: () => import('@/views/game/play.vue')
    },
    {
      path: '/:lang?/deposit_vendor',
      name: ROUTE_NAME.VENDOR_POPUP,
      meta: { layout: 'blank-layout-master' },
      component: () => import('@/views/bank/vendorPopUp.vue')
    },
    {
      path: '/:lang?/bank',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/bank/bankLayout.vue'),
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      },
      children: [
        {
          path: 'deposit',
          name: ROUTE_NAME.DEPOSIT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/bank/deposit.vue')
        },
        {
          path: 'deposit-success',
          name: ROUTE_NAME.DEPOSIT_SUCCESS,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/bank/depositSuccess.vue')
        },
        {
          path: 'withdrawal',
          name: ROUTE_NAME.WITHDRAWAL,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/bank/withdrawal.vue')
        },
        {
          path: 'transaction',
          name: ROUTE_NAME.TRANSACTION,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/bank/transaction.vue')
        },
        {
          path: 'voucher',
          name: ROUTE_NAME.CLAIM_VOUCHER,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/bank/claimVoucher.vue')
        },
        {
          path: 'transfer',
          name: ROUTE_NAME.TRANSFER,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/bank/transfer.vue')
        },
        {
          path: 'vip',
          name: ROUTE_NAME.VIP_PROFILE,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/vipPage.vue')
        },
        {
          path: 'affiliate/bonus',
          name: ROUTE_NAME.AFFILIATE_REFERRAL,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/affiliateBonus.vue')
        },
        {
          path: 'transaction-history',
          name: ROUTE_NAME.TRANSACTION_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/transactionHistory.vue')
        },
        {
          path: 'redemption-history',
          name: ROUTE_NAME.REDEMPTION_HISTORY,
          meta: { layout: 'layout-master' },
          component: require('@/views/profile/transactionHistory.vue').default
        },
        {
          path: 'turnover-history',
          name: ROUTE_NAME.TURNOVER,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/turnoverHistory.vue')
        },
        {
          path: 'player-history',
          name: ROUTE_NAME.PLAYER_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/history/historyOverview.vue')
        }
      ]
    },
    {
      path: '/:lang?/payment/process',
      name: ROUTE_NAME.PROCESS_TRANSACTION,
      meta: { layout: 'blank-layout-master' },
      component: () => import('@/views/bank/process.vue')
    },
    {
      path: '/:lang?/payment/qrPayProcess',
      name: ROUTE_NAME.QRPROCESS_TRANSACTION,
      meta: { layout: 'blank-layout-master' },
      component: () => import('@/views/bank/qrPayProcess.vue')
    },
    {
      path: '/:lang?/payment/pay-secure',
      name: ROUTE_NAME.PAY_SECURE,
      meta: { layout: 'blank-layout-master' },
      component: () => import('@/views/bank/paysecure.vue')
    },
    {
      path: '/:lang?/profile/menu',
      name: ROUTE_NAME.PROFILE_MENU,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/menu.vue'),
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/profile/logout',
      name: ROUTE_NAME.PROFILE_LOGOUT,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/logout.vue')
    },
    {
      path: '/:lang?/profileAccount',
      name: ROUTE_NAME.MOBILE_ACCOUNT,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/mobileAccount'),
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/profileHistory',
      name: ROUTE_NAME.MOBLE_HISOTRY,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/mobileHistoryLayout'),
      children: [
        {
          //wallet history
          path: 'transaction-history',
          name: ROUTE_NAME.MOBILE_TRANSACTION_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/transactionHistory.vue')
        },
        {
          //turnover
          path: 'turnover-history',
          name: ROUTE_NAME.MOBILE_TO_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/turnoverHistory.vue')
        },
        {
          //player-history
          path: 'betting-history',
          name: ROUTE_NAME.MOBILE_BETTING_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/history/historyOverview.vue')
        },
        {
          path: 'promo-history',
          name: ROUTE_NAME.MOBILE_PROMO_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/promotion/history.vue')
        }
      ],
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      }
    },
    {
      path: '/:lang?/profile',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/profileLayout.vue'),
      children: [
        {
          path: 'referral',
          name: ROUTE_NAME.PROFILE_REFERRAL,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/referral/referral.vue'),
          children: [
            {
              path: 'tier',
              name: ROUTE_NAME.PROFILE_REFERRAL_TIER,
              meta: { layout: 'layout-master' },
              component: () => import('@/views/profile/referral/referralTier.vue')
            },
            {
              path: 'referral-report',
              name: ROUTE_NAME.PROFILE_REFERRAL_REPORT,
              meta: { layout: 'layout-master' },
              component: () => import('@/views/profile/referral/referralReport.vue')
            },
            {
              path: 'redeem-history',
              name: ROUTE_NAME.PROFILE_REFERRAL_REDEEM_HISTORY,
              meta: { layout: 'layout-master' },
              component: () => import('@/views/profile/referral/redeemHistory.vue')
            },
            {
              path: 'downline-report',
              name: ROUTE_NAME.PROFILE_REFERRAL_DOWNLINE_HISTORY,
              meta: { layout: 'layout-master' },
              component: () => import('@/views/profile/referral/downlineHistory.vue')
            }
          ]
        }
      ]
    },
    {
      path: '/:lang?/reward',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/profileLayout.vue'),
      children: [
        {
          path: '',
          name: ROUTE_NAME.REWARDSTORE,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/rewardStore.vue'),
          children: [
            {
              path: 'rewardStore',
              name: ROUTE_NAME.REWARD_STORE_ITEM,
              meta: { layout: 'layout-master' },
              component: () => import('@/views/profile/rewardStoreItem.vue')
            }
          ]
        }
      ]
    },
    {
      path: '/:lang?/profile',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/profile/profileLayout.vue'),
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      },
      children: [
        {
          path: 'account',
          name: ROUTE_NAME.PROFILE,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/account.vue')
        },
        {
          path: 'personal',
          name: ROUTE_NAME.PERSONAL,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/personal.vue')
        },
        {
          path: 'message',
          name: ROUTE_NAME.ANNOUNCEMENT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/announcement.vue')
        },
        {
          path: 'change-password',
          name: ROUTE_NAME.CHANGE_PASSWORD,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/changePassword.vue')
        },
        {
          path: 'bank-account',
          name: ROUTE_NAME.BANK_ACCOUNT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/bankAccount.vue')
        },
        {
          path: 'inbox',
          name: ROUTE_NAME.INBOX,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/inbox.vue')
        },
        {
          path: 'rewards',
          name: ROUTE_NAME.REWARDS,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/profile/rewards.vue')
        }
        // ,
        // {
        //   path: 'referral',
        //   name: ROUTE_NAME.PROFILE_REFERRAL,
        //   meta: { layout: 'layout-master' },
        //   component: () => import('@/views/profile/referral/referral.vue'),
        //   children: [
        //     {
        //       path: 'tier',
        //       name: ROUTE_NAME.PROFILE_REFERRAL_TIER,
        //       meta: { layout: 'layout-master' },
        //       component: () => import('@/views/profile/referral/referralTier.vue')
        //     },
        //     {
        //       path: 'referral-report',
        //       name: ROUTE_NAME.PROFILE_REFERRAL_REPORT,
        //       meta: { layout: 'layout-master' },
        //       component: () => import('@/views/profile/referral/referralReport.vue')
        //     },
        //     {
        //       path: 'redeem-history',
        //       name: ROUTE_NAME.PROFILE_REFERRAL_REDEEM_HISTORY,
        //       meta: { layout: 'layout-master' },
        //       component: () => import('@/views/profile/referral/redeemHistory.vue')
        //     },
        //     {
        //       path: 'downline-report',
        //       name: ROUTE_NAME.PROFILE_REFERRAL_DOWNLINE_HISTORY,
        //       meta: { layout: 'layout-master' },
        //       component: () => import('@/views/profile/referral/downlineHistory.vue')
        //     }
        //   ]
        // }
      ]
    },
    {
      path: '/:lang?/rewards',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/rewards/rewardLayout.vue'),
      children: [
        {
          path: ':type',
          name: ROUTE_NAME.REWARDS_BY_TYPE,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/rewards/rewards.vue')
        }
      ]
    },
    {
      path: '/:lang?/promotion',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/promotion/promotionLayout.vue'),
      children: [
        {
          path: '/:lang?/promotion',
          name: ROUTE_NAME.PROMOTION,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/promotion/promotion.vue')
        },
        {
          path: 'in-progress',
          name: ROUTE_NAME.PROMOTION_IN_PROGRESS,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/promotion/inprogress.vue'),
          beforeEnter: (to, from, next) => {
            loginGuard(to, from, next)
          }
        },
        {
          path: 'history',
          name: ROUTE_NAME.PROMOTION_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/promotion/history.vue'),
          beforeEnter: (to, from, next) => {
            loginGuard(to, from, next)
          }
        }
      ]
    },
    {
      path: '/:lang?/info',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/info/infoLayout.vue'),
      children: [
        {
          path: 'faq',
          name: ROUTE_NAME.FAQ,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/info/faq.vue')
        },
        {
          path: 'about-us',
          name: ROUTE_NAME.ABOUT_US,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/info/aboutUs.vue')
        },
        {
          path: 'contact-us',
          name: ROUTE_NAME.CONTACT_US,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/info/contactUs.vue')
        },
        {
          path: 'responsible-gaming',
          name: ROUTE_NAME.RESPONSIBLE_GAMING,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/info/responsibleGaming.vue')
        },
        {
          path: 'tnc',
          name: ROUTE_NAME.TNC,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/info/tnc.vue')
        }
      ]
    },
    // {
    //   path: '/:lang?/viptiers',
    //   meta: { layout: 'layout-master' },
    //   component: require('@/views/viptier/viptiers.vue').default,
    //   children: [
    //     {
    //       path: 'vip-tier',
    //       name: ROUTE_NAME.VIPTIER,
    //       meta: { layout: 'layout-master' },
    //       component: require('@/views/viptier/viptiers.vue').default
    //     }
    //   ]
    // },
    {
      path: '/:lang?/affiliate/home',
      name: ROUTE_NAME.AFFILIATE_HOME,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/affiliate/home.vue')
    },
    {
      path: '/:lang?/affiliate',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/affiliate/affiliateLayout.vue'),
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      },
      children: [
        {
          path: 'members',
          name: ROUTE_NAME.AFFILIATE_MEMBER_REPORT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliate/memberReport.vue')
        },
        {
          path: 'members-detail',
          name: ROUTE_NAME.AFFILIATE_MEMBER_DETAIL_REPORT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliate/memberDetailReport.vue')
        },
        {
          path: 'games-report',
          name: ROUTE_NAME.AFFILIATE_GAME_REPORT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliate/gameReport.vue')
        },
        {
          path: 'agent-report',
          name: ROUTE_NAME.AFFILIATE_AGENT_REPORT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliate/agentReport.vue')
        },
        {
          path: 'link',
          name: ROUTE_NAME.AFFILIATE_LINK,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliate/link.vue')
        }
      ]
    },
    {
      path: '/:lang?/affiliate/bank',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/affiliatePayment/bankLayout.vue'),
      beforeEnter: (to, from, next) => {
        loginGuard(to, from, next)
      },
      children: [
        {
          path: 'withdrawal',
          name: ROUTE_NAME.AFFILIATE_WITHDRAWAL,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliatePayment/withdrawal.vue')
        },
        {
          path: 'transaction',
          name: ROUTE_NAME.AFFILIATE_TRANSACTION,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliatePayment/transaction.vue')
        },
        {
          path: 'account',
          name: ROUTE_NAME.AFFILIATE_BANK_ACCOUNT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/affiliatePayment/account.vue')
        }
      ]
    },
    {
      path: '/:lang?/luckywheel',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/rewards/WheelOfFortune.vue'),
      name: ROUTE_NAME.WOF
    },
    {
      path: '/:lang?/siluckywheel',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/rewards/SingleWheelOfFortune.vue'),
      name: ROUTE_NAME.SingleWOF
    },
    {
      path: '/:lang?/simluckywheel',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/rewards/SingleMobileWheelOfFortune.vue'),
      name: ROUTE_NAME.SingleMWOF
    },
    {
      path: '/:lang?/mluckywheel',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/rewards/MobileWheelOfFortune.vue'),
      name: ROUTE_NAME.MWOF
    },
    {
      path: '/:lang?/grab-coin',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/rewards/GrabCoin.vue'),
      name: ROUTE_NAME.GRAB_COIN
    },
    {
      path: '/:lang?/card-mania',
      meta: { layout: 'layout-master' },
      component: require('@/views/rewards/CardMania.vue').default,
      name: ROUTE_NAME.CARD_MANIA
    },
    {
      path: '/:lang?/referralPreview',
      name: ROUTE_NAME.REFERRAL_PREVIEW,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/referral/referralBeforeLogin.vue')
    },
    {
      path: '/:lang?/referral',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/referral/referralLayout.vue'),
      children: [
        {
          path: 'tier',
          name: ROUTE_NAME.REFERRAL_TIER,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/referral/referralTier.vue')
        },
        {
          path: 'summary',
          name: ROUTE_NAME.REFERRAL_SUMMARY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/referral/referralSummary.vue'),
          children: [
            {
              path: 'report',
              name: ROUTE_NAME.REFERRAL_SUMMARY_REPORT,
              meta: { layout: 'layout-master' },
              component: () => import('@/views/referral/referralReport.vue')
            },
            {
              path: 'history',
              name: ROUTE_NAME.REFERRAL_SUMMARY_HISTORY,
              meta: { layout: 'layout-master' },
              component: () => import('@/views/referral/referralHistory.vue')
            }
          ]
        },
        {
          path: 'downlinereport',
          name: ROUTE_NAME.REFERRAL_DOWNLINE_REPORT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/referral/downlineReport.vue')
        },
        {
          path: '/:lang?/referral/report',
          name: ROUTE_NAME.REFERRAL_REPORT,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/referral/referralReport.vue')
        },
        {
          path: '/:lang?/referral/history',
          name: ROUTE_NAME.REFERRAL_HISTORY,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/referral/referralHistory.vue')
        }
      ]
    },
    {
      path: '/:lang?/vip',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/vip/vipLayout.vue'),
      children: [
        {
          path: 'vipProfile',
          name: ROUTE_NAME.VIP,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/vip/vip.vue')
        }
      ]
    },
    {
      path: '/:lang?/vipEvent',
      meta: { layout: 'layout-master' },
      component: () => import('@/views/iplVip/iplVipLayout.vue'),
      children: [
        {
          path: 'IPL2024',
          name: ROUTE_NAME.IPLVIP,
          meta: { layout: 'layout-master' },
          component: () => import('@/views/iplVip/iplVip.vue')
        }
      ]
    },
    {
      path: '/:lang?/error',
      component: () => import('@/views/error/errorLayout.vue'),
      children: [
        {
          path: 'ip-restriction',
          name: ROUTE_NAME.ERROR_403,
          meta: { layout: 'blank-layout-master' },
          component: () => import('@/views/error/403.vue')
        },
        {
          path: 'page-not-found',
          name: ROUTE_NAME.ERROR_404,
          meta: { layout: 'blank-layout-master' },
          component: () => import('@/views/error/404.vue')
        }
      ]
    },
    {
      path: '/maintenance',
      name: ROUTE_NAME.MAINTENANCE,
      meta: { layout: 'blank-layout-master' },
      component: () => import('@/views/error/maintenance.vue')
    },
    { path: '/:lang?/home', redirect: { name: ROUTE_NAME.HOME }, pathToRegexpOptions: { strict: true } },
    { path: '/:lang?/home/', redirect: { name: ROUTE_NAME.HOME }, pathToRegexpOptions: { strict: true } },
    {
      path: '/:lang?',
      name: ROUTE_NAME.HOME,
      meta: { layout: 'layout-master' },
      component: () => import('@/views/home.vue')
    },
    {
      path: '/',
      redirect: { name: ROUTE_NAME.HOME }
    },
    {
      path: '*',
      redirect: { name: ROUTE_NAME.ERROR_404 }
    }
  ],
  /* TO AUTO BACK TO TOP */
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
