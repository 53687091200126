import { locale } from '@/util'

export default function isActiveStatusDesc(value, isVerb = false) {
  let desc = ''

  if (typeof value === 'number') {
    desc = !isVerb ? locale.getMessage(`enumActivationStatus[0].${value}`) : locale.getMessage(`enumActivationStatus[1].${value}`)
  } else if (typeof value === 'boolean') {
    desc = locale.getMessage(`enumActivationStatus[2].${value}`)
  }

  return desc
}
