import moment from 'moment'
import { TIME } from '@/constants/constants'

export default function halftimeFormat(value) {
  let result = ''

  if (value != undefined && value != null && value != '') {
    var local_date= moment(value).local().format("MMM DD, YYYY HH:mm:ss Z");
    var date1 = local_date.substr(0, local_date.indexOf("+"))
    var date2 = local_date.substr(local_date.indexOf("+"), local_date.length)
    return date1 + "(GMT " + date2 + ")"
  }

  return result
}
