import Vue from 'vue'

import currencyFormat from './currency-format'
import shortDateFormat from './date-format'
import longDateFormat from './date-time-format'
import emptyDataFormat from './empty-data-format'
import isActiveStatusDesc from './is-active-status-desc'
import lockUnlockStatusDesc from './lock-unlock-status-desc'
import yesNoStatusDesc from './yes-no-status-desc'
import genderDesc from './gender-desc'
import isVerifiedStatusDesc from './is-verified-status-desc'
import stripHtml from './strip-html'
import timeFormat from './time-format'
import timerFormat from './timer-format'
import dateWeekFormat from './date-week-format'
import maskSensitiveInfo from './mask-sensitive-info'
import utcToLocalTime from './utc-to-local-time.js'
import floatParser from './float-parser'
import maskNumber from './mask-number'
import shortTimeFormat from '@/filters/short-time-format'

Vue.filter('maskNumber', maskNumber)
Vue.filter('currency', currencyFormat)
Vue.filter('shortDate', shortDateFormat)
Vue.filter('longDate', longDateFormat)
Vue.filter('emptyDataFormat', emptyDataFormat)
Vue.filter('isActiveStatusDesc', isActiveStatusDesc)
Vue.filter('lockUnlockStatusDesc', lockUnlockStatusDesc)
Vue.filter('yesNoStatusDesc', yesNoStatusDesc)
Vue.filter('genderDesc', genderDesc)
Vue.filter('isVerifiedStatusDesc', isVerifiedStatusDesc)
Vue.filter('stripHtml', stripHtml)
Vue.filter('time', timeFormat)
Vue.filter('shortTime', shortTimeFormat)
Vue.filter('timer', timerFormat)
Vue.filter('weekDate', dateWeekFormat)
Vue.filter('maskInfo', maskSensitiveInfo)
Vue.filter('utcToLocalTime', utcToLocalTime)
Vue.filter('floatParser', floatParser)
