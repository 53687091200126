import { apiHelper } from '@/util'
import { SHARED } from '@/constants/constants'

/* SERVICE EXPORT FUNCTION NAME */
export const inboxService = {
  getInboxMsg,
  readInboxMsg,
  deleteInboxMsg,
  readBulkInboxMsg
}

/* SERVICE URL PATH */
const INBOX = {
  INBOX: 'inbox',
  READ: 'read/message',
  BULK: 'bulk/message',
  MESSAGE: 'get/message',
  REMOVE: 'remove/message'
}

function getInboxMsg() {
  return apiHelper().get(`${INBOX.INBOX}/${INBOX.MESSAGE}`)
}

function readInboxMsg(obj) {
  return apiHelper().post(`/${INBOX.INBOX}/${INBOX.READ}/${obj.msgId}`)
}

function deleteInboxMsg(obj) {
  return apiHelper().delete(`/${INBOX.INBOX}/${INBOX.REMOVE}/${obj.msgId}`)
}

function readBulkInboxMsg(obj) {
  return apiHelper().post(`/${INBOX.INBOX}/${INBOX.BULK}/${obj.msgId}`)
}
