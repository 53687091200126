<template>
  <v-overlay opacity="0.8" z-index="300" :value="loading || isLoading">
    <!--    <v-progress-circular indeterminate size="100" width="10" color="primary"></v-progress-circular>-->
    <div class="pulse">
      <img src="/static/svg/bb88_logo_w_sponsor.svg" width="215"/>
    </div>
  </v-overlay>
</template>

<script>
import {SHARED_LOADING, SHARED_UNLOADING} from '@/store/shared.module'

export default {
  name: 'appLoadingProgress',
  data: () => ({loading: false}),

  watch: {
    /*isLoading() {
      if (this.isLoading != null && this.isLoading == true) {
        this.loading = true
      } else {
        this.loading = false
      }
    }*/
  },
  computed: {
    isLoading() {
      return this.$store.state.shared.isLoading
    }
  },
  methods: {
    open() {
      this.$store.dispatch(`${SHARED_LOADING}`)
    },
    close() {
      this.$store.dispatch(`${SHARED_UNLOADING}`)
    }
  }
}
</script>
<style>
.pulse {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.1875rem;
  height: 12.1875rem;
  border-radius: 9.375rem;
  background: rgba(255,255,255,.9);
}

.pulse:before,
.pulse:after {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid #fddd04;
  border-radius: 50%;
  animation: animate 2s linear infinite
}

.pulse:after {
  animation-delay: 1s;
}

@keyframes animate {
  0% {
    box-shadow: 0 0 0.0625rem #fddd04;
  }
  30%, 35% {
    box-shadow: 0 0 2.1875rem #fddd04;
  }
  60%, 65% {
    box-shadow: 0 0 0 #fddd04;
  }
  100% {
    box-shadow: 0 0 1px #fddd04;
  }
}


</style>


