import { inboxService } from '@/services'
import { SHARED_LOADING, SHARED_UNLOADING } from '@/store/shared.module'
import { SESSION, SHARED } from '@/constants/constants'
import router from '@/router'
import { ROUTE_NAME } from '@/constants/route.constants'

//define module name
const MODULE_NAME = 'inbox/'

const A_GET_INBOX_MSG = 'getInboxMsg'
const A_READ_INBOX_MSG = 'readInboxMsg'
const A_READ_BULK_INBOX_MSG = 'readBulkInboxMsg'
const A_DELETE_INBOX_MSG = 'deleteInboxMsg'

export const INBOX_GET_MSG = MODULE_NAME + A_GET_INBOX_MSG
export const INBOX_READ_MSG = MODULE_NAME + A_READ_INBOX_MSG
export const INBOX_DELETE_MSG = MODULE_NAME + A_DELETE_INBOX_MSG
export const INBOX_READ_BULK_MSG = MODULE_NAME + A_READ_BULK_INBOX_MSG

const M_GET_INBOX_MSG = 'setInboxMsg'
const M_READ_INBOX_MSG = 'setReadInboxMsg'
const M_DELETE_INBOX_MSG = 'setDeleteInboxMsg'

const state = {
  inboxMsg: [],
  readInboxMsg: {},
  readBulkInboxMsg: {},
  deleteInboxMsg: {}
}

//to retrieve data from store state (called by component)
const getters = {}

//to perform some actions related with updating state (called by component)
const actions = {
  [A_GET_INBOX_MSG]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    inboxService.getInboxMsg().then(
      data => {
        let result = data
        commit(M_GET_INBOX_MSG, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      },
      error => {
        let result = error
        commit(M_GET_INBOX_MSG, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      }
    )
  },
  [A_READ_INBOX_MSG]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    inboxService.readInboxMsg(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      }
    )
  },
  [A_READ_BULK_INBOX_MSG]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    inboxService.readBulkInboxMsg(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      }
    )
  },
  async [A_DELETE_INBOX_MSG]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    await inboxService.deleteInboxMsg(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      }
    )
  }
}

//to update state values (called by actions)
const mutations = {
  [M_GET_INBOX_MSG](state, { result }) {
    if (result.success) {
      state.inboxMsg = result.data
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
