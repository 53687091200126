import {SHARED} from '@/constants/constants'
import {IsActiveStatus, LockUnlockStatus, YesNoStatus, Gender} from '@/constants/enums'
import {
    SlotsProvider,
    SportsProvider,
    CricketProvider,
    LiveProvider,
    TableProvider, CrashProvider, FishingProvider
} from '@/constants/enums/provider.enum'
import {locale} from './localization-helper'

export const ddlHandler = {
  getOptionAll,
  getPageSize,
  getIsActiveStatus,
  getLockUnlockStatus,
  getYesNoStatus,
  getGenders,
  getLanguage,
  getSportsProvider,
  getLiveProvider,
  getSlotsProvider,
  getTableProvider,
  getCricketProvider,
  getCrashProvider,
  getFishingProvider,
  getAffiliateMemberIsActiveStatus
}

function getOptionAll(isStringVal = false) {
    return {
        displayText: locale.getMessage('label.all'),
        value: isStringVal ? '' : SHARED.DEFAULT_ALL_VALUE
    }
}

function getAffiliateMemberIsActiveStatus() {
    let ddl = []

    ddl.push(getOptionAll(true))

    ddl.push({
        displayText: locale.getMessage(`affilateStatus.1`),
        value: 1
    })
    ddl.push({
        displayText: locale.getMessage(`affilateStatus.0`),
        value: 0
    })

    return ddl
}

function getPageSize(hasOptionAll) {
    let ddl = SHARED.PAGE_SIZE_LIST

    if (hasOptionAll) {
        ddl.push(getOptionAll())
    }

    return ddl
}

function getIsActiveStatus(hasOptionAll) {
    let ddl = []

    if (hasOptionAll) {
        ddl.push(getOptionAll())
    }

    ddl.push({
        text: locale.getMessage(`enumActivationStatus[0].${IsActiveStatus.ACTIVE}`),
        value: IsActiveStatus.ACTIVE
    })
    ddl.push({
        text: locale.getMessage(`enumActivationStatus[0].${IsActiveStatus.INACTIVE}`),
        value: IsActiveStatus.INACTIVE
    })

    return ddl
}

function getLockUnlockStatus(hasOptionAll) {
    let ddl = []

    if (hasOptionAll) {
        ddl.push(getOptionAll())
    }

    ddl.push({
        text: locale.getMessage(`enumLockUnlockStatus[0].${LockUnlockStatus.LOCKED}`),
        value: LockUnlockStatus.LOCKED
    })
    ddl.push({
        text: locale.getMessage(`enumLockUnlockStatus[0].${LockUnlockStatus.UNLOCKED}`),
        value: LockUnlockStatus.UNLOCKED
    })

    return ddl
}

function getYesNoStatus(hasOptionAll) {
    let ddl = []
    if (hasOptionAll) {
        ddl.push(getOptionAll())
    }
    ddl.push({
        text: locale.getMessage(`enumYesNoStatus[0].${YesNoStatus.YES}`),
        value: YesNoStatus.YES
    })
    ddl.push({
        text: locale.getMessage(`enumYesNoStatus[0].${YesNoStatus.NO}`),
        value: YesNoStatus.NO
    })

    return ddl
}

function getGenders(isStringValue = false) {
    let ddl = []

    ddl.push({
        text: locale.getMessage(`enumGender[0].${Gender.MALE}`),
        value: isStringValue ? Gender.MALE.toString() : Gender.MALE
    })
    ddl.push({
        text: locale.getMessage(`enumGender[0].${Gender.FEMALE}`),
        value: isStringValue ? Gender.FEMALE.toString() : Gender.FEMALE
    })

    return ddl
}

function getLanguage() {
    let ddl = []

    SHARED.SUPPORTED_LANGUAGES.forEach(lang => {
        ddl.push({
            text: locale.getMessage(`enumLanguage[0].${lang}`),
            value: lang
        })
    })

    return ddl
}

function getSportsProvider() {
    let ddl = []

    ddl.push({
        text: locale.getMessage(`enumSportsProvider[0].${SportsProvider.BTI}`),
        value: SportsProvider.BTI
    })

    ddl.push({
        text: locale.getMessage(`enumSportsProvider[0].${SportsProvider.IBC}`),
        value: SportsProvider.IBC
    })

    return ddl
}

function getLiveProvider() {
    let ddl = []

    ddl.push({
        text: locale.getMessage(`enumLiveProvider[0].${LiveProvider.SEXY_V2}`),
        value: LiveProvider.SEXY_V2
    })
    ddl.push({
        text: locale.getMessage(`enumLiveProvider[0].${LiveProvider.PP}`),
        value: LiveProvider.PP
    })
    ddl.push({
        text: locale.getMessage(`enumLiveProvider[0].${LiveProvider.EVO}`),
        value: LiveProvider.EVO
    })
    ddl.push({
        text: locale.getMessage(`enumLiveProvider[0].${LiveProvider.EZUGI}`),
        value: LiveProvider.EZUGI
    })
    ddl.push({
        text: locale.getMessage(`enumLiveProvider[0].${LiveProvider.AURA}`),
        value: LiveProvider.AURA
    })
    ddl.push({
        text: locale.getMessage(`enumLiveProvider[0].${LiveProvider.ROYAL}`),
        value: LiveProvider.ROYAL
    })
    ddl.push({
        text: locale.getMessage(`enumLiveProvider[0].${LiveProvider.PT}`),
        value: LiveProvider.PT
    })

    return ddl
}

function getSlotsProvider() {
    let ddl = []

    ddl.push({
        text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.PNG}`),
        value: SlotsProvider.PNG
    })
    ddl.push({
        text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.PP}`),
        value: SlotsProvider.PP
    })
    ddl.push({
        text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.SPG}`),
        value: SlotsProvider.SPG
    })
    ddl.push({
        text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.RT}`),
        value: SlotsProvider.RT
    })
    ddl.push({
        text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.PT}`),
        value: SlotsProvider.PT
    })
    ddl.push({
        text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.ONE}`),
        value: SlotsProvider.ONE
    })
    ddl.push({
        text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.JDB}`),
        value: SlotsProvider.JDB
    })
    ddl.push({
        text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.JILI}`),
        value: SlotsProvider.JILI
    })
    ddl.push({
        text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.BOOONGO}`),
        value: SlotsProvider.BOOONGO
    })
    ddl.push({
        text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.NETENT}`),
        value: SlotsProvider.NETENT
    })
    ddl.push({
        text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.NOLIMIT}`),
        value: SlotsProvider.NOLIMIT
    })
    ddl.push({
        text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.RELAX}`),
        value: SlotsProvider.RELAX
    })
    ddl.push({
        text: locale.getMessage(`enumSlotsProvider[0].${SlotsProvider.PG}`),
        value: SlotsProvider.PG
    })

    return ddl
}

function getTableProvider() {
    let ddl = []

    ddl.push({
        text: locale.getMessage(`enumTableProvider[0].${TableProvider.SPRIBE}`),
        value: TableProvider.SPRIBE
    })

    ddl.push({
        text: locale.getMessage(`enumTableProvider[0].${TableProvider.JILI}`),
        value: TableProvider.JILI
    })

    ddl.push({
        text: locale.getMessage(`enumTableProvider[0].${TableProvider.KING_MAKER}`),
        value: TableProvider.KING_MAKER
    })

    ddl.push({
        text: locale.getMessage(`enumTableProvider[0].${TableProvider.LUDO}`),
        value: TableProvider.LUDO
    })

    ddl.push({
        text: locale.getMessage(`enumTableProvider[0].${TableProvider.SPG}`),
        value: TableProvider.SPG
    })

    return ddl
}

function getCricketProvider() {
    let ddl = []

    ddl.push({
        text: locale.getMessage(`enumCricketProvider[0].${CricketProvider.BETSWIZ}`),
        value: CricketProvider.BETSWIZ
    })

    ddl.push({
        text: locale.getMessage(`enumCricketProvider[0].${CricketProvider.SAP}`),
        value: CricketProvider.SAP
    })

    return ddl
}

function getCrashProvider() {
    let ddl = []

    ddl.push({
        text: locale.getMessage(`enumCrashProvider[0].${CrashProvider.SPRIBE}`),
        value: CrashProvider.SPRIBE
    })

    return ddl
}

function getFishingProvider() {
    let ddl = []

    ddl.push({
        text: locale.getMessage(`enumFishingProvider[0].${FishingProvider.JILI}`),
        value: FishingProvider.JILI
    })

    ddl.push({
        text: locale.getMessage(`enumFishingProvider[0].${FishingProvider.SPG}`),
        value: FishingProvider.SPG
    })

    return ddl
}