<template>
  <v-container class="fill-height">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card-text class="pa-0 pb-2 font-weight-bold title text-center">{{ $t(`label.dontQuitJustYet`) }}</v-card-text>
        <v-card-text class="pa-0 pb-2 body-1 text-center">{{ $t(`label.signUpPromptWait`) }}</v-card-text>
        <app-button :title="$t(`label.tryAgain`)" :action="openRegisterDialog"></app-button>
        <p class="mt-5 text-center body-1">
          {{ $t(`message.alreadyHaveAccount`) }}
          <v-btn text height="auto" @click="openLoginDialog" class="pa-0 text-uppercase font-weight-bold secondary--text">{{ $t(`label.signIn`) }}</v-btn>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AppWaitRegister',
  props: {
    openRegisterDialog: {
      type: Function,
      default: () => ({})
    },
    openLoginDialog: {
      type: Function,
      default: () => ({})
    }
  }
}
</script>
