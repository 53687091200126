import i18n from '@/plugins/i18n'

export const locale = {
  getMessage,
  getArrayMessage,
  getName,
  getCurrentLanguage,
  setCurrentLanguage
}

function getCurrentLanguage() {
  return i18n.locale
}

function setCurrentLanguage(language) {
  i18n.locale = language
}

function getMessage(key, dynamicValue = null) {
  return dynamicValue == null ? i18n.t(key) : i18n.t(key, dynamicValue)
}

function getArrayMessage(key, array) {
  let result = []

  for (let i = 0; i < array.length; i++) {
    result.push(i18n.t(`${key}.${array[i].toLowerCase()}`))
  }

  return result.join(', ')
}

function getName(value) {
  let result = ''

  if (value != undefined && value != null && value != '') {
    let str = typeof value === 'string' ? value : JSON.stringify(value)
    let localeStartIndex = str.indexOf(`"${i18n.locale}"`)

    if (localeStartIndex > 0) {
      let localeStr = str.slice(localeStartIndex + 6, str.length)
      let localeEndIndex = localeStr.indexOf('"')

      result = localeStr.slice(0, localeEndIndex)
    } else if (localeStartIndex == -1) {
      //get value from other language
      localeStartIndex = str.indexOf(`":"`)
      let localeStr = str.slice(localeStartIndex + 3, str.length)
      let localeEndIndex = localeStr.indexOf('"')

      result = localeStr.slice(0, localeEndIndex)
    }
  }

  return result
}
