import { apiHelper } from '@/util'
import { API, KEY } from '@/constants/constants'
import md5 from 'md5'
import format from 'string-format'

const AFFILIATE_PAYMENT = {
  MERCHANTS: 'Merchants',
  TRANSACTION: 'Transactions',
  MEMBER: 'Members',
  CURRENCY: 'Currency',
  CANCEL: 'Cancel',
  BANKS: 'Banks',
  MEMBER_BANK_ACCOUNTS: 'MemberBankAccounts',
  MERCHANTS_BANK_ACCOUNTS: 'BankAccounts',
  AVAILABLE_METHODS: 'AvailableMethods',
  PROCESS_TRANSACTION: 'ProcessTransaction',
  GATEWAY_SUPPORTED_BANK: 'GatewaySupportedBank',
  API_URL: API.PAYMENT_URL,
  MERCHANTS_CODE: KEY.AFFILIATE_PAYMENT_MERCHANTS_CODE,
  MERCHANTS_KEY: KEY.AFFILIATE_PAYMENT_MERCHANTS_KEY
}

export const affiliatePaymentService = {
  getBank,
  getAvailableMethod,
  getMerchantBankAccount,
  getOnlinePaymentSupportedBank,

  /* Member Bank */
  getMemberBankAccount,
  putMemberBankAccount,
  deleteMemberBankAccount,
  newMemberBankAccount,

  /*Transaction*/
  getTransaction,
  cancelTransaction,
  postTransaction,
  processTransaction
}

function getBank(obj) {
  let requestOptions = {
    params: {
      Name: obj.bankName,
      BankCode: obj.bankCode,
      CurrencyCode: obj.currency,
      Page: obj.page,
      ItemsPerpage: obj.itemsPerPage,
      orderBy: obj.orderBy,
      order: obj.order
    }
  }
  return apiHelper(AFFILIATE_PAYMENT.API_URL).get(`/${AFFILIATE_PAYMENT.BANKS}`, requestOptions)
}

function getAvailableMethod(obj) {
  let requestOptions = {
    params: {
      MerchantCode: AFFILIATE_PAYMENT.MERCHANTS_CODE,
      MemberCode: obj.memberCode,
      MemberRealName: obj.realName,
      CurrencyCode: obj.currency
    }
  }

  return apiHelper(AFFILIATE_PAYMENT.API_URL).get(`/${AFFILIATE_PAYMENT.AVAILABLE_METHODS}`, requestOptions)
}

function getMerchantBankAccount(obj) {
  return apiHelper(AFFILIATE_PAYMENT.API_URL).get(
    `/${AFFILIATE_PAYMENT.MERCHANTS}/${AFFILIATE_PAYMENT.MERCHANTS_CODE}/${AFFILIATE_PAYMENT.MEMBER}/${obj.memberCode}/${AFFILIATE_PAYMENT.CURRENCY}/${obj.currency}/${AFFILIATE_PAYMENT.MERCHANTS_BANK_ACCOUNTS}`
  )
}

function getOnlinePaymentSupportedBank(obj) {
  return apiHelper(AFFILIATE_PAYMENT.API_URL).get(
    `/${AFFILIATE_PAYMENT.MERCHANTS}/${AFFILIATE_PAYMENT.MERCHANTS_CODE}/${AFFILIATE_PAYMENT.MEMBER}/${obj.memberCode}/${AFFILIATE_PAYMENT.GATEWAY_SUPPORTED_BANK}`
  )
}

/* MEMBER BANK ACCOUNT */
function getMemberBankAccount(obj) {
  return apiHelper(AFFILIATE_PAYMENT.API_URL).get(
    `/${AFFILIATE_PAYMENT.MERCHANTS}/${AFFILIATE_PAYMENT.MERCHANTS_CODE}/${AFFILIATE_PAYMENT.MEMBER}/${obj.memberCode}/${AFFILIATE_PAYMENT.MEMBER_BANK_ACCOUNTS}`
  )
}

function putMemberBankAccount(obj) {
  let requestOptions = {
    accountName: obj.memberBankAccountName,
    accountNumber: obj.memberBankAccountNumber,
    province: obj.memberBankProvince,
    branch: obj.memberBankBranch,
    city: obj.memberBankCity,
    bankName: obj.memberBankName,
    updatedBy: obj.memberCode
  }
  return apiHelper(AFFILIATE_PAYMENT.API_URL).put(`/${AFFILIATE_PAYMENT.MEMBER_BANK_ACCOUNTS}/${obj.memberBankAccountId}`, requestOptions)
}

function deleteMemberBankAccount(obj) {
  return apiHelper(AFFILIATE_PAYMENT.API_URL).delete(`/${AFFILIATE_PAYMENT.MEMBER_BANK_ACCOUNTS}/${obj.memberBankAccountId}`)
}

function newMemberBankAccount(obj) {
  let requestOptions = {
    accountName: obj.memberBankAccountName,
    accountNumber: obj.memberBankAccountNumber,
    province: obj.memberBankProvince,
    branch: obj.memberBankBranch,
    city: obj.memberBankCity,
    bankName: obj.memberBankName,
    createdBy: obj.memberCode
  }

  return apiHelper(AFFILIATE_PAYMENT.API_URL).post(
    `/${AFFILIATE_PAYMENT.MERCHANTS}/${AFFILIATE_PAYMENT.MERCHANTS_CODE}/${AFFILIATE_PAYMENT.MEMBER}/${obj.memberCode}/${AFFILIATE_PAYMENT.MEMBER_BANK_ACCOUNTS}`,
    requestOptions
  )
}

/* END MEMBER BANK ACCOUNT */

/* TRANSACTION */
function getTransaction(obj) {
  let unsign_signature = format('merchantcode={0}&membercode={1}&currencycode={2}&key={3}', AFFILIATE_PAYMENT.MERCHANTS_CODE, obj.memberCode, obj.currency, AFFILIATE_PAYMENT.MERCHANTS_KEY)
  let requestOptions = {
    params: {
      fromdate: obj.fromDate,
      todate: obj.toDate,
      transtype: obj.transType,
      signature: md5(unsign_signature),
      page: obj.page,
      itemsperpage: obj.itemsPerPage
    }
  }
  return apiHelper(AFFILIATE_PAYMENT.API_URL).get(
    `/${AFFILIATE_PAYMENT.MERCHANTS}/${AFFILIATE_PAYMENT.MERCHANTS_CODE}/${AFFILIATE_PAYMENT.MEMBER}/${obj.memberCode}/${AFFILIATE_PAYMENT.CURRENCY}/${obj.currency}/${AFFILIATE_PAYMENT.TRANSACTION}`,
    requestOptions
  )
}

function cancelTransaction(obj) {
  let unsign_signature = format('merchantcode={0}&transid={1}&key={2}', AFFILIATE_PAYMENT.MERCHANTS_CODE, obj.transactionId, AFFILIATE_PAYMENT.MERCHANTS_KEY)
  let requestOptions = {
    params: {
      transid: obj.transactionId,
      merchantcode: AFFILIATE_PAYMENT.MERCHANTS_CODE,
      signature: md5(unsign_signature)
    }
  }

  return apiHelper(AFFILIATE_PAYMENT.API_URL).put(
    `/${AFFILIATE_PAYMENT.TRANSACTION}/${AFFILIATE_PAYMENT.MERCHANTS_CODE}/${AFFILIATE_PAYMENT.TRANSACTION}/${obj.transactionId}/${AFFILIATE_PAYMENT.CANCEL}?signature=${requestOptions.params.signature}`,
    requestOptions
  )
}

function postTransaction(obj) {
  let unsign_signature = format(
    'transtypename={0}&methodcode={1}&memberrealname={2}&currencycode={3}&amount={4}&oricurrencycode={5}&oriamount={6}&gatewayaccountid={7}&bankaccountshortcode={8}&memberbankaccountid={9}&memberaccountname={10}&memberaccountnumber={11}&memberaccountbankname={12}&memberaccountprovince={13}&memberaccountbranch={14}&memberaccountcity={15}&memberbankdepositedtime={16}&gatewaybankid={17}&memberemail={18}&membercontactidornumber={19}&merchantfrontendurl={20}&attachmenttype={21}&ipaddress={22}&savebankaccountoptions={23}&key={24}',
    obj.transactionType,
    obj.methodCode,
    obj.memberRealName,
    obj.currency,
    obj.amount,
    obj.oriCurrency,
    obj.oriAmount,
    obj.gatewayAccountId,
    obj.merchantBankCode,
    obj.memberBankAccountId,
    obj.memberBankAccountName,
    obj.memberBankAccountNumber,
    obj.memberBankName,
    obj.memberBankProvince,
    obj.memberBankBranch,
    obj.memberBankCity,
    obj.depositDateTime,
    obj.gatewayAccountBankId,
    obj.memberEmail,
    obj.memberContactIdorNumber,
    obj.transactionSuccessUrl,
    obj.attachmentFileType,
    obj.ipAddress,
    obj.saveNewBank,
    AFFILIATE_PAYMENT.MERCHANTS_KEY
  )
  const requestOptions = {
    transTypeName: obj.transactionType,
    methodCode: obj.methodCode,
    memberRealName: obj.memberRealName,
    currencyCode: obj.currency,
    amount: obj.amount,
    oriCurrencyCode: obj.oriCurrency,
    oriAmount: obj.oriAmount,
    gatewayAccountID: obj.gatewayAccountId,
    bankAccountShortCode: obj.merchantBankCode,
    memberBankAccountID: obj.memberBankAccountId,
    memberAccountName: obj.memberBankAccountName,
    memberAccountNumber: obj.memberBankAccountNumber,
    memberAccountBankName: obj.memberBankName,
    memberAccountProvince: obj.memberBankProvince,
    memberAccountBranch: obj.memberBankBranch,
    memberAccountCity: obj.memberBankCity,
    memberBankDepositedTime: obj.depositDateTime,
    gatewayBankID: obj.gatewayAccountBankId,
    memberEmail: obj.memberEmail,
    memberContactIdOrNumber: obj.memberContactIdorNumber,
    merchantFrontendURL: obj.transactionSuccessUrl,
    attachmentType: obj.attachmentFileType,
    attachment: obj.attachment,
    ipAddress: obj.ipAddress,
    saveBankAccountOptions: obj.saveNewBank ? 1 : 0,
    language: obj.language,
    platformType: obj.platform,
    signature: md5(unsign_signature)
  }
  return apiHelper(AFFILIATE_PAYMENT.API_URL).post(
    `/${AFFILIATE_PAYMENT.MERCHANTS}/${AFFILIATE_PAYMENT.MERCHANTS_CODE}/${AFFILIATE_PAYMENT.MEMBER}/${obj.memberCode}/${AFFILIATE_PAYMENT.TRANSACTION}`,
    requestOptions
  )
}

function processTransaction(obj) {
  return apiHelper(AFFILIATE_PAYMENT.API_URL).get(`/${AFFILIATE_PAYMENT.TRANSACTION}/${obj.transactionId}/${AFFILIATE_PAYMENT.PROCESS_TRANSACTION}`)
}

/* END TRANSACTION */
