import { apiHelper } from '@/util'
import { SHARED } from '@/constants/constants'

/* SERVICE EXPORT FUNCTION NAME */
export const rewardService = {
  getRewardWalletLog,
  getDailyCheckInRewardPrizeList,
  getCheckInDay,
  getRewardConfiguration,
  postCheckInDay,
  getChallengeByMember,
  acceptMemberChallenge,
  getRewardItem,
  claimRewardItem,
  verifyClaim,
  getRedeemHistory
}

/* SERVICE URL PATH */
const REWARD = {
  REWARD: 'reward',
  REWARD_WALLET: 'rewardWallet/log',
  DAILY_CHECKIN: 'dailycheckin',
  CHECK_IN_DAY: 'checkInDay',
  CHECK_IN_DAILY: 'dailycheckin',
  GET_MEMBER_CHALLENGE: 'getChallengeByMember',
  ACCEPT: 'accept',
  CONFIGURATION: 'configuration/reward',
  REWARD_ITEM: 'reward-item',
  CLAIM: 'rewardStore/claim',
  HISTORY: 'rewardStore/history',
  VERIFY: 'rewardStore/verify'
}

function getRewardItem(filter, categoryId) {
  const requestOptions = {
    params: {
      currency: SHARED.DEFAULT_CURRENCY,
      size: filter.size,
      page: filter.page
    }
  }
  return apiHelper().get(`${REWARD.REWARD}/${REWARD.REWARD_ITEM}/${categoryId}`, requestOptions)
}
function getRedeemHistory() {
  const requestOptions = {
    // params: {
    //     page: data.page,
    //     size: data.itemsPerPage
    // }
  }
  return apiHelper().get(`/${REWARD.HISTORY}`, requestOptions)
}
function claimRewardItem(filter) {
  const requestBody = {
    rewardId: filter.rewardId,
    rewardItem: filter.rewardItem,
    rewardCategory: filter.rewardCategory,
    rewardVariation: filter.rewardVariation,
    points: filter.points,
    credit_given: filter.credit_given,
    turnover: filter.turnover_multiplier,
    name: filter.name,
    mobile: filter.mobile,
    email: filter.email,
    city: filter.city,
    postcode: filter.postcode,
    address1: filter.address1,
    address2: filter.address2
  }
  return apiHelper().post(`${REWARD.CLAIM}`, requestBody)
}

function verifyClaim(filter) {
  const requestOptions = {
    params: {
      rewardId: filter.reward_id
    }
  }
  return apiHelper().get(`${REWARD.VERIFY}`, requestOptions)
}
function getRewardWalletLog(data) {
  const requestBody = {
    params: {
      page: data.page,
      start_time: data.startTime,
      end_time: data.endTime,
      size: data.size
    }
  }
  return apiHelper().get(`/${REWARD.REWARD_WALLET}`, requestBody)
}

function getChallengeByMember() {
  return apiHelper().get(`${REWARD.REWARD}/${REWARD.GET_MEMBER_CHALLENGE}`)
}

function acceptMemberChallenge(obj) {
  const requestBody = {
    challenge_id: obj.id
  }
  return apiHelper().post(`${REWARD.REWARD}/${REWARD.ACCEPT}`, requestBody)
}

function getCheckInDay() {
  return apiHelper().get(`${REWARD.REWARD}/${REWARD.CHECK_IN_DAY}`)
}

function postCheckInDay(data) {
  const requestBody = {
    checkin_day: data.checkinDay
  }
  return apiHelper().post(`${REWARD.REWARD}/${REWARD.CHECK_IN_DAILY}`, requestBody)
}

function getDailyCheckInRewardPrizeList(obj) {
  const requestOptions = {
    params: {
      id: obj.id
    }
  }
  return apiHelper().get(`${REWARD.REWARD}/${REWARD.CHECK_IN_DAILY}`, requestOptions)
}

function getRewardConfiguration(filter) {
  const requestOptions = {
    params: {
      key: filter.currency,
      enabled: filter.enabled
    }
  }
  return apiHelper().get(`/${REWARD.CONFIGURATION}`, requestOptions)
}
