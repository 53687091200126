import {apiHelper} from '@/util'
import {SHARED} from '@/constants/constants'

export const bonusService = {
    getCategory,
    getAnonBonusList,
    getManualBonusList,
    getMemberAvailableBonusList,
    getBonusHistory,

    getOverallBonusStatus,
    getBonusStatus,
    claimBonus,
    applyBonus,

    checkVoucherBonus,
    applyVoucherBonus,

    checkVipBonus,
    applyVipBonus,
    getRewardCoins,
    getCoinsLogs,
    grabCoinNow,

    claimProgressReward,

    getVipSummary,
    getRebateHistory,
    claimRebate,
    getIPLVipSummary,
    claimIPLProgressReward,
    getIPLEventStatus,
    getMinimumBonusDeposit,

    flipCardNow,
    getCardManiaLogs,
    getCardMania,
    getCardManiaUserInfo
}

//API URLs
const BONUS = {
    BONUS: 'bonus',
    CATEGORY: 'category',
    OVERALLSTATUS: 'overallstatus',
    HISTORY: 'history',
    STATUS: 'status',
    CLAIM: 'claim',
    VOUCHER: 'voucher',
    APPLY: 'apply',
    CHECK: 'check',
    MEMBER: 'member',
    VIPBONUS: 'vipBonus',
    VIPPROGRESS: 'vipProgress',
    IPLVIPPROGRESS: 'vipEventProgress',
    GRAB_COIN_SETTINGS: '/reward/grab-coin',
    COIN_LOGS: '/reward/coin-logs',
    GRAB_COIN: '/reward/grab',
    VIP_REWARDS_SUMMARY: 'vipRewardsSummary',
    IPL_VIP_REWARDS_SUMMARY: 'vipEventRewardsSummary',
    REBATE_HISTORY: 'rebate/all',
    REWARD_WALLET: 'rewardWallet/log',
    IPLEVENTDATE: 'ipl/eventDate',
    MINIMUMDEPOSIT: '/percentage/minimumDeposit',
    CARD_MANIA_SETTINGS: '/reward/card-mania',
    CARD_MANIA_LOGS: '/reward/card-mania-logs',
    CARD_MANIA_FLIP: '/reward/flipCard',
    CARD_MANIA_USER_INFO: '/reward/card-mania-user-info'
}

function getMinimumBonusDeposit(){
    return apiHelper().get(`/${BONUS.BONUS}${BONUS.MINIMUMDEPOSIT}`)
}
function grabCoinNow() {
    return apiHelper().post(`${BONUS.GRAB_COIN}`)
}

function getCoinsLogs(filter) {
    const requestOptions = {
        params: {
            currency: filter.currency
        }
    }
    return apiHelper().get(`${BONUS.COIN_LOGS}`, requestOptions)
}

function getRewardCoins(filter) {
    const requestOptions = {
        params: {
            currency: filter.currency
        }
    }
    return apiHelper().get(`${BONUS.GRAB_COIN_SETTINGS}`, requestOptions)
}

function getBonusHistory(data) {
    const requestOptions = {
        params: {
            overall_status: data.overallStatus,
            apply_start_time: data.fromDate,
            apply_end_time: data.toDate,
            page: data.page,
            size: data.itemsPerPage
        }
    }
    return apiHelper().get(`/${BONUS.BONUS}/${BONUS.HISTORY}`, requestOptions)
}

function getAnonBonusList() {
    const requestBody = {
        params: {
            currency: SHARED.DEFAULT_CURRENCY
        }
    }
    return apiHelper(null, false).get(`/${BONUS.BONUS}`, requestBody)
}

function getManualBonusList() {
    return apiHelper(`/`, false).get(`${SHARED.BONUS_JSON_URL}`)
}

function getMemberAvailableBonusList() {
    const requestBody = {
        params: {
            currency: SHARED.DEFAULT_CURRENCY
        }
    }
    return apiHelper().get(`/${BONUS.BONUS}`, requestBody)
}

function getCategory() {
    return apiHelper().get(`/${BONUS.BONUS}/${BONUS.CATEGORY}`)
}

function applyBonus(applyBonusObj) {
    const requestOptions = {
        bonus_id: applyBonusObj.bonusId,
        fund_in_amount: applyBonusObj.depositAmount,
        fund_in_trx_id: applyBonusObj.depositTransactionId
    }
    return apiHelper().post(`${BONUS.BONUS}`, requestOptions)
}

function applyVoucherBonus(voucherCodeObj) {
    const requestOptions = {
        code: voucherCodeObj.code
    }
    return apiHelper().post(`${BONUS.VOUCHER}/${BONUS.APPLY}`, requestOptions)
}

function checkVoucherBonus(voucherCodeObj) {
    const requestOptions = {
        code: voucherCodeObj.code
    }
    return apiHelper().post(`${BONUS.VOUCHER}/${BONUS.CHECK}`, requestOptions)
}

function getOverallBonusStatus() {
    return apiHelper().get(`${BONUS.BONUS}/${BONUS.OVERALLSTATUS}`)
}

function getBonusStatus() {
    return apiHelper().get(`${BONUS.BONUS}/${BONUS.STATUS}`)
}

function claimBonus(id) {
    return apiHelper().post(`${BONUS.BONUS}/${id}/${BONUS.CLAIM}`)
}

function checkVipBonus() {
    return apiHelper().get(`${BONUS.MEMBER}/${BONUS.VIPBONUS}`)
}

function applyVipBonus() {
    return apiHelper().post(`${BONUS.MEMBER}/${BONUS.VIPBONUS}`)
}

function claimProgressReward() {
    return apiHelper().post(`${BONUS.MEMBER}/${BONUS.VIPPROGRESS}`)
}

function claimIPLProgressReward() {
    return apiHelper().post(`${BONUS.MEMBER}/${BONUS.IPLVIPPROGRESS}`)
}

function getIPLEventStatus() {
    return apiHelper().get(`${BONUS.IPLEVENTDATE}`);
}

function getVipSummary() {
    const requestBody = {
        params: {
            currency: SHARED.DEFAULT_CURRENCY
        }
    }
    return apiHelper().get(`${BONUS.MEMBER}/${BONUS.VIP_REWARDS_SUMMARY}`, requestBody)
}

function getIPLVipSummary() {
    const requestBody = {
        params: {
            currency: SHARED.DEFAULT_CURRENCY
        }
    }
    return apiHelper().get(`${BONUS.MEMBER}/${BONUS.IPL_VIP_REWARDS_SUMMARY}`, requestBody);
}

function getRebateHistory(data) {
    const requestBody = {
        params: {
            page: data.page,
            start_time: data.startTime,
            end_time: data.endTime,
            size: data.size,
            currency: SHARED.DEFAULT_CURRENCY
        }
    }
    return apiHelper().get(`${BONUS.BONUS}/${BONUS.REBATE_HISTORY}`, requestBody)
}

function claimRebate() {
    return apiHelper().post(`${BONUS.BONUS}/${BONUS.REBATE_HISTORY}`)
}

//card mania
function flipCardNow() {
    return apiHelper().post(`${BONUS.CARD_MANIA_FLIP}`)
}

function getCardManiaLogs(filter) {
    const requestOptions = {
        params: {
            currency: filter.currency
        }
    }
    return apiHelper().get(`${BONUS.CARD_MANIA_LOGS}`, requestOptions)
}

function getCardMania(filter) {
    const requestOptions = {
        params: {
            currency: filter.currency
        }
    }
    return apiHelper().get(`${BONUS.CARD_MANIA_SETTINGS}`, requestOptions)
}

function getCardManiaUserInfo(filter) {
    const requestOptions = {
        params: {
            currency: filter.currency
        }
    }
    return apiHelper().get(`${BONUS.CARD_MANIA_USER_INFO}`, requestOptions)
}