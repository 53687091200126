import { lookupService } from '@/services'

const MODULE_NAME = 'lookup/'

//define actions name
const A_COUNTRY = 'getCountry'
const A_PROVINCE = 'getProvince'

//define dispatch action
export const LOOKUP_COUNTRY = MODULE_NAME + A_COUNTRY
export const LOOKUP_PROVINCE = MODULE_NAME + A_PROVINCE

//define mutations name
const M_COUNTRY = 'setCountry'
const M_PROVINCE = 'setProvince'

const state = {
  country: [],
  province: []
}

//to retrieve data from store state (called by component)
const getters = {}

//to perform some actions related with updating state (called by component)
const actions = {
  [A_COUNTRY]({ commit }, { countryLookupObj }) {
    lookupService.getCountry(countryLookupObj).then(
      data => {
        let result = data
        commit(M_COUNTRY, { result })
      },
      error => {
        let result = error
        commit(M_COUNTRY, { result })
      }
    )
  },
  [A_PROVINCE]({ commit }, { provinceLookupObj }) {
    lookupService.getProvince(provinceLookupObj).then(
      data => {
        let result = data
        commit(M_PROVINCE, { result })
      },
      error => {
        let result = error
        commit(M_PROVINCE, { result })
      }
    )
  }
}

//to update state values (called by actions)
const mutations = {
  [M_COUNTRY](state, { result }) {
    let p = []
    if (result.success) {
      result.data.forEach(country => {
        p.push({
          id: country.id,
          displayText: country.local_name,
          value: country.code,
          code: country.code,
          name: country.name,
          phonePrefix: country.callingcode
        })
      })
    }
    state.country = p.sort((a, b) => (a.name > b.name ? 1 : -1))
  },

  [M_PROVINCE](state, { result }) {
    let p = []
    if (result.success) {
      result.data.forEach(province => {
        p.push({
          id: province.id,
          displayText: province.local_name,
          value: province.local_name,
          code: province.code,
          name: province.name
        })
      })
    }
    state.province = p.sort((a, b) => (a.name > b.name ? 1 : -1))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
