var SlotsProvider = {
    PT: "PT",
    PNG: "PNG",
    PP: "PP",
    SPG: "SPG",
    ONE: "ONE",
    RT: "RT",
    HABA: "HABANERO",
    JILI: "JILI",
    JDB: "JDB",
    NETENT: "NETENT",
    PG: "PG",
    NOLIMIT: "NOLIMIT",
    RELAX: "RELAX",
    BOOONGO: "BOOONGO"
}

var SportsProvider = {
    BTI: "BTI",
    IBC: "IBC"
}

var LiveProvider = {
    PT: "PT",
    SEXY_V2: "SEXY_V2",
    PP: "PP",
    EVO: "EVO",
    EZUGI: "EZUGI",
    AURA:"AURA",
    ROYAL:"ROYAL"
}

var TableProvider = {
    SPRIBE: "SPRIBE",
    JILI: "JILI",
    KING_MAKER: "SEXY_V2",
    LUDO: "LUDO",
    SPG: "SPG"
}

var CricketProvider = {
    BETSWIZ: "BETSWIZ",
    SAP: "SAP"
}

var CrashProvider = {
    SPRIBE: "SPRIBE",
}

var FishingProvider = {
    JILI: "JILI",
    SPG: "SPG"
}

export { SlotsProvider, SportsProvider, CricketProvider, LiveProvider, TableProvider, CrashProvider, FishingProvider }
