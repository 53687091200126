import { DevicePlatform } from '@/constants/enums'
import { SESSION, SHARED, Currencies, SocialMediaContacts, CACHE_KEY } from '@/constants/constants'
import { countryHelper, locale } from '@/util'
import sharedModule from '@/store/shared.module'

export const uiHelper = {
  /* Page */
  getPlatform,
  getHostname,
  getHost,
  IsIos,
  /* Currency */
  getCurrency,
  setCurrency,
  /* language */
  getLanguage,
  setLanguage,
  /* Cookie */
  getCookie,
  setCookie,
  removeCookie,
  getPopup,
  /* Local Storage With Expiry */
  getLocalStorage,
  setLocalStorage,
  /* Dialog Obj Initialize */
  initializePageDialogProperty,

  isAuthenticated,
  openPopup,
  openNewTab,
  getFingerprints,
  downloadAPK,
  isAndroid,
  getcasinoLink,
  getFBLink,
  getaffLink,
  getInstaLink,
  getTlgLink,
  getTwtLink,
  getYTLink,
  isAndroidWebView,
  getDialCode,
  changeLocaleOnLogin,
  setMetaTag,
  setHreflangLinks,
  setHreflangLinksen,
  setHreflangLinksbd,
  setHreflangLinksenin,
  setHreflangLinkshi,
  setHreflangLinksennp,
  setHreflangLinksnenp,
  getLiveChatLicense,
  getLiveChatUrl,
  getRegionLocale,
  pushSportRadarRetargeting,
  setCanonical
}

function pushSportRadarRetargeting(gameObj, type, provider) {
  try {
    let _mediaUrl = SHARED.MEDIA_SERVER_URL
    let gametype = ''
    switch (type) {
      case 'ld':
        gametype = 'casino'
        break
      case 'rng':
        gametype = 'slot'
        break
      case 'fishing':
        gametype = 'fising'
        break
      case 'sb':
        gametype = 'sportsbook'
        break
      case 'special':
        gametype = 'slot'
        break
      case 'table':
        gametype = 'table'
        break
      case 'cricket':
        gametype = 'cricket'
        break
      case 'crash':
        gametype = 'crash'
        break
      case 'lottery':
        gametype = 'lottery'
        break
    }

    if (type == 'ld') {
      if (gameObj === null || gameObj === undefined) {
        let gameCacheKey = CACHE_KEY.GAME_LIST + '_' + this.getPlatform() + '_' + this.getCurrency() + '_ld'
        let storageLdGames = this.getLocalStorage(gameCacheKey)
        if (storageLdGames) {
          gameObj = storageLdGames.find(x => x.provider == provider)
        }
      }
    } else if (type == 'sb') {
      if (gameObj === null || gameObj === undefined) {
        let gameCacheKey = CACHE_KEY.GAME_LIST + '_' + this.getPlatform() + '_' + this.getCurrency() + '_sb'
        let storageSbGames = this.getLocalStorage(gameCacheKey)
        if (storageSbGames) {
          gameObj = storageSbGames.find(x => x.provider == provider)
        }
      }
    } else if (type == 'cricket') {
      if (gameObj === null || gameObj === undefined) {
        let gameCacheKey = CACHE_KEY.GAME_LIST + '_' + this.getPlatform() + '_' + this.getCurrency() + '_cricket'
        let storageCricketGames = this.getLocalStorage(gameCacheKey)
        if (storageCricketGames) {
          gameObj = storageCricketGames.find(x => x.provider == provider)
        }
      }
    }

    console.log(gameObj)
    if (!gameObj) {
      /* from pagenavigator / non gamepage , unable to get from localStorage cause of not yet load gamepage*/
      gameObj = {}
      gameObj.provider = provider
      gameObj.type = type
      gameObj.code = 0
      gameObj.name = provider
      gameObj.locale = [
        {
          name: provider
        }
      ]
      gameObj.imageCdn = _mediaUrl + '/menu/' + type + '/' + provider + '.png'
    }

    if (gametype != 'sportsbook') {
      srtmCommands.push({
        event: 'srt.retargeting',
        payload: {
          casinoEvent: {
            game: {
              id: gameObj.code,
              name: gameObj.locale && gameObj.locale.length > 0 ? gameObj.locale[0].name : '',
              category: gametype,
              image: gameObj.imageCdn ? gameObj.imageCdn : _mediaUrl + '/game_icons/en/' + gameObj.provider + '/' + gameObj.code + '_' + gameObj.id + '.jpg',
              provider: {
                id: gameObj.provider,
                game: gameObj.code
              }
            },
            event: {
              type: 'page_view'
            },
            version: '1-0-0'
          }
        }
      })
    } else {
      //remove sport retargeting since we got no enough data at our end
      // srtmCommands.push({
      //   event: "srt.retargeting",
      //   payload: {
      //     sportsEvent: {
      //       match_id: "",
      //       sr_match_id: "",
      //       team_home_name: "",
      //       team_home_name_short: "",
      //       team_home_logo: "",
      //       team_away_name: "",
      //       team_away_name_short: "",
      //       team_away_logo: "",
      //       scheduled: "",
      //       status: "",
      //       tournament_name: "",
      //       sport: gameObj.provider,
      //       odds_home: "",
      //       odds_draw: "",
      //       odds_away: "",
      //       language: this.getLanguage(),
      //     },
      //   },
      // });
    }
  } catch (ex) {
    console.log(ex)
  }
}

var MD5 = function(d) {
  var r = M(V(Y(X(d), 8 * d.length)))
  return r.toLowerCase()
}

function M(d) {
  for (var _, m = '0123456789ABCDEF', f = '', r = 0; r < d.length; r++) (_ = d.charCodeAt(r)), (f += m.charAt((_ >>> 4) & 15) + m.charAt(15 & _))
  return f
}

function X(d) {
  for (var _ = Array(d.length >> 2), m = 0; m < _.length; m++) _[m] = 0
  for (m = 0; m < 8 * d.length; m += 8) _[m >> 5] |= (255 & d.charCodeAt(m / 8)) << m % 32
  return _
}

function V(d) {
  for (var _ = '', m = 0; m < 32 * d.length; m += 8) _ += String.fromCharCode((d[m >> 5] >>> m % 32) & 255)
  return _
}

function Y(d, _) {
  ;(d[_ >> 5] |= 128 << _ % 32), (d[14 + (((_ + 64) >>> 9) << 4)] = _)
  for (var m = 1732584193, f = -271733879, r = -1732584194, i = 271733878, n = 0; n < d.length; n += 16) {
    var h = m,
      t = f,
      g = r,
      e = i
    ;(f = md5_ii(
      (f = md5_ii(
        (f = md5_ii(
          (f = md5_ii(
            (f = md5_hh(
              (f = md5_hh(
                (f = md5_hh(
                  (f = md5_hh(
                    (f = md5_gg(
                      (f = md5_gg(
                        (f = md5_gg(
                          (f = md5_gg(
                            (f = md5_ff(
                              (f = md5_ff(
                                (f = md5_ff(
                                  (f = md5_ff(
                                    f,
                                    (r = md5_ff(r, (i = md5_ff(i, (m = md5_ff(m, f, r, i, d[n + 0], 7, -680876936)), f, r, d[n + 1], 12, -389564586)), m, f, d[n + 2], 17, 606105819)),
                                    i,
                                    m,
                                    d[n + 3],
                                    22,
                                    -1044525330
                                  )),
                                  (r = md5_ff(r, (i = md5_ff(i, (m = md5_ff(m, f, r, i, d[n + 4], 7, -176418897)), f, r, d[n + 5], 12, 1200080426)), m, f, d[n + 6], 17, -1473231341)),
                                  i,
                                  m,
                                  d[n + 7],
                                  22,
                                  -45705983
                                )),
                                (r = md5_ff(r, (i = md5_ff(i, (m = md5_ff(m, f, r, i, d[n + 8], 7, 1770035416)), f, r, d[n + 9], 12, -1958414417)), m, f, d[n + 10], 17, -42063)),
                                i,
                                m,
                                d[n + 11],
                                22,
                                -1990404162
                              )),
                              (r = md5_ff(r, (i = md5_ff(i, (m = md5_ff(m, f, r, i, d[n + 12], 7, 1804603682)), f, r, d[n + 13], 12, -40341101)), m, f, d[n + 14], 17, -1502002290)),
                              i,
                              m,
                              d[n + 15],
                              22,
                              1236535329
                            )),
                            (r = md5_gg(r, (i = md5_gg(i, (m = md5_gg(m, f, r, i, d[n + 1], 5, -165796510)), f, r, d[n + 6], 9, -1069501632)), m, f, d[n + 11], 14, 643717713)),
                            i,
                            m,
                            d[n + 0],
                            20,
                            -373897302
                          )),
                          (r = md5_gg(r, (i = md5_gg(i, (m = md5_gg(m, f, r, i, d[n + 5], 5, -701558691)), f, r, d[n + 10], 9, 38016083)), m, f, d[n + 15], 14, -660478335)),
                          i,
                          m,
                          d[n + 4],
                          20,
                          -405537848
                        )),
                        (r = md5_gg(r, (i = md5_gg(i, (m = md5_gg(m, f, r, i, d[n + 9], 5, 568446438)), f, r, d[n + 14], 9, -1019803690)), m, f, d[n + 3], 14, -187363961)),
                        i,
                        m,
                        d[n + 8],
                        20,
                        1163531501
                      )),
                      (r = md5_gg(r, (i = md5_gg(i, (m = md5_gg(m, f, r, i, d[n + 13], 5, -1444681467)), f, r, d[n + 2], 9, -51403784)), m, f, d[n + 7], 14, 1735328473)),
                      i,
                      m,
                      d[n + 12],
                      20,
                      -1926607734
                    )),
                    (r = md5_hh(r, (i = md5_hh(i, (m = md5_hh(m, f, r, i, d[n + 5], 4, -378558)), f, r, d[n + 8], 11, -2022574463)), m, f, d[n + 11], 16, 1839030562)),
                    i,
                    m,
                    d[n + 14],
                    23,
                    -35309556
                  )),
                  (r = md5_hh(r, (i = md5_hh(i, (m = md5_hh(m, f, r, i, d[n + 1], 4, -1530992060)), f, r, d[n + 4], 11, 1272893353)), m, f, d[n + 7], 16, -155497632)),
                  i,
                  m,
                  d[n + 10],
                  23,
                  -1094730640
                )),
                (r = md5_hh(r, (i = md5_hh(i, (m = md5_hh(m, f, r, i, d[n + 13], 4, 681279174)), f, r, d[n + 0], 11, -358537222)), m, f, d[n + 3], 16, -722521979)),
                i,
                m,
                d[n + 6],
                23,
                76029189
              )),
              (r = md5_hh(r, (i = md5_hh(i, (m = md5_hh(m, f, r, i, d[n + 9], 4, -640364487)), f, r, d[n + 12], 11, -421815835)), m, f, d[n + 15], 16, 530742520)),
              i,
              m,
              d[n + 2],
              23,
              -995338651
            )),
            (r = md5_ii(r, (i = md5_ii(i, (m = md5_ii(m, f, r, i, d[n + 0], 6, -198630844)), f, r, d[n + 7], 10, 1126891415)), m, f, d[n + 14], 15, -1416354905)),
            i,
            m,
            d[n + 5],
            21,
            -57434055
          )),
          (r = md5_ii(r, (i = md5_ii(i, (m = md5_ii(m, f, r, i, d[n + 12], 6, 1700485571)), f, r, d[n + 3], 10, -1894986606)), m, f, d[n + 10], 15, -1051523)),
          i,
          m,
          d[n + 1],
          21,
          -2054922799
        )),
        (r = md5_ii(r, (i = md5_ii(i, (m = md5_ii(m, f, r, i, d[n + 8], 6, 1873313359)), f, r, d[n + 15], 10, -30611744)), m, f, d[n + 6], 15, -1560198380)),
        i,
        m,
        d[n + 13],
        21,
        1309151649
      )),
      (r = md5_ii(r, (i = md5_ii(i, (m = md5_ii(m, f, r, i, d[n + 4], 6, -145523070)), f, r, d[n + 11], 10, -1120210379)), m, f, d[n + 2], 15, 718787259)),
      i,
      m,
      d[n + 9],
      21,
      -343485551
    )),
      (m = safe_add(m, h)),
      (f = safe_add(f, t)),
      (r = safe_add(r, g)),
      (i = safe_add(i, e))
  }
  return Array(m, f, r, i)
}

function md5_cmn(d, _, m, f, r, i) {
  return safe_add(bit_rol(safe_add(safe_add(_, d), safe_add(f, i)), r), m)
}

function md5_ff(d, _, m, f, r, i, n) {
  return md5_cmn((_ & m) | (~_ & f), d, _, r, i, n)
}

function md5_gg(d, _, m, f, r, i, n) {
  return md5_cmn((_ & f) | (m & ~f), d, _, r, i, n)
}

function md5_hh(d, _, m, f, r, i, n) {
  return md5_cmn(_ ^ m ^ f, d, _, r, i, n)
}

function md5_ii(d, _, m, f, r, i, n) {
  return md5_cmn(m ^ (_ | ~f), d, _, r, i, n)
}

function safe_add(d, _) {
  var m = (65535 & d) + (65535 & _)
  return (((d >> 16) + (_ >> 16) + (m >> 16)) << 16) | (65535 & m)
}

function bit_rol(d, _) {
  return (d << _) | (d >>> (32 - _))
}

function getFingerprints() {
  var timestamp = new Date().getTime()
  var navigator_info = window.navigator
  var screen_info = window.screen
  var uid = navigator_info.mimeTypes.length
  uid += navigator_info.userAgent.replace(/\D+/g, '')
  uid += navigator_info.plugins.length
  uid += screen_info.height || ''
  uid += screen_info.width || ''
  uid += screen_info.pixelDepth || ''
  uid += timestamp
  var result = MD5(uid)
  console.log(result)
  return result
}

function getPopup() {
  return DevicePlatform.POPUP
}

function initializePageDialogProperty() {
  let initPageDialog = {
    title: locale.getMessage(`label.system`),
    dialogXButton: this.closePageDialog,
    messageTitle: '',
    message: [],
    button: []
  }
  return initPageDialog
}

function getPlatform() {
  let type = DevicePlatform.DESKTOP
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/is) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i) ||
    screen.width <= 1024
  ) {
    type = DevicePlatform.MOBILE
  } else {
    type = DevicePlatform.DESKTOP
  }
  return type
}

function IsIos() {
  if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/is) || navigator.userAgent.match(/iPod/i)) {
    return true
  }

  return false
}

function getHostname() {
  return window.location.origin
}

function getHost() {
  return window.location.host
}

function setCookie(name, value, day = 100) {
  let date = new Date()
  let expiration = new Date(date.setDate(date.getDate() + day))
  let cookie = escape(name) + '=' + escape(value) + ';expires=' + expiration + '; path=/'

  if (name === 'af_code') {
    cookie += ';samesite=none;secure' // Append SameSite attribute for af_code cookie
  }

  document.cookie = cookie
}

function getCookie(name) {
  let key = name + '='
  let cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length)
    }
    if (cookie.indexOf(key) === 0) {
      return cookie.substring(key.length, cookie.length)
    }
  }
  return null
}

function removeCookie(name) {
  let date = new Date()
  let expiration = new Date(date.setDate(date.getDate() - 1))
  let cookie = escape(name) + '=' + ';expires=' + expiration + ';'
  document.cookie = cookie
}

function getCurrency() {
  var currencySession = localStorage.getItem(SESSION.CURRENCY)
  if (currencySession == '' || currencySession == null) {
    setCurrency(SHARED.DEFAULT_CURRENCY)
  }
  return localStorage.getItem(SESSION.CURRENCY)
}

function setCurrency(value) {
  localStorage.setItem(SESSION.CURRENCY, value)
}

function getLanguage() {
  var languageCookie = getCookie(SESSION.LANGUAGE)
  if (languageCookie == '' || languageCookie == null) {
    setCookie(SESSION.LANGUAGE, SHARED.DEFAULT_LANGUAGE)
    languageCookie = SHARED.DEFAULT_LANGUAGE
  }
  return languageCookie
}

function setLanguage(value) {
  localStorage.setItem(SESSION.LANGUAGE, value)
}

function isAuthenticated() {
  let isLoggedIn = localStorage.getItem(SESSION.TOKEN)
  let result = true

  if (!isLoggedIn) {
    //check if user has logged in to the system
    result = false
  }

  return result
}

function getLocalStorage(key) {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) {
    return null
  }

  const item = JSON.parse(itemStr)
  const now = new Date()

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }
  return item.value
}

function setLocalStorage(key, value, ttl) {
  const now = new Date()

  const item = {
    value: value,
    expiry: now.getTime() + ttl * 180000
  }
  localStorage.setItem(key, JSON.stringify(item))
}

function openPopup(url, title, popupWidth, popupHeight) {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

  const systemZoom = width / window.screen.availWidth
  const left = (width - popupWidth) / 2 / systemZoom + dualScreenLeft
  const top = (height - popupHeight) / 2 / systemZoom + dualScreenTop
  const newWindow = window.open(
    '',
    title,
    `
  width=${popupWidth}, 
  height=${popupHeight}, 
  top=${top}, 
  left=${left}
  `
  )

  newWindow.document.write('Redirecting...')
  newWindow.location.href = url

  // if (window.hasOwnProperty('focus')) newWindow.focus()
}

function openNewTab(url, title, popupWidth, popupHeight) {
  setTimeout(() => {
    window.open(url, '_blank')
  })
}

function downloadAPK() {
  window.open(SHARED.MEDIA_DOMAIN + '/babu88/apk/babu88.apk')
}

function isAndroid() {
  return /(android)/i.test(navigator.userAgent)
}

function getcasinoLink() {
  return 'https://babu88official.com'
}

function getFBLink() {
  //if(getCurrency()==Currencies.BDT){}else if (getCurrency()==Currencies.INR){}
  if (getCurrency() == Currencies.BDT) {
    return 'https://prelink.co/bb88fb'
  } else if (getCurrency() == Currencies.INR) {
    return 'https://prelink.co/bb88_fb_ind'
  }
}
function getaffLink() {
  //if(getCurrency()==Currencies.BDT){}else if (getCurrency()==Currencies.INR){}
  if (getCurrency() == Currencies.BDT) {
    return 'https://prelink.co/HxmKwzaLow'
  } else if (getCurrency() == Currencies.INR) {
    return 'https://babu88agents.com/in/'
  } else if (getCurrency() == Currencies.NPR) {
    return 'https://prelink.co/WeehAYitzj'
  }
}
function getInstaLink() {
  if (getCurrency() == Currencies.BDT) {
    return 'https://prelink.co/bb88insta'
  } else if (getCurrency() == Currencies.INR) {
    return ''
  }
}

function getTlgLink() {
  if (getCurrency() == Currencies.BDT) {
    return 'https://prelink.co/bb88tlgm'
  } else if (getCurrency() == Currencies.INR) {
    return 'https://prelink.co/bb88_ind_tg'
  }
}

function getTwtLink() {
  if (getCurrency() == Currencies.BDT) {
    return 'https://prelink.co/bb88twitter'
  } else if (getCurrency() == Currencies.INR) {
    return ''
  }
}

function getYTLink() {
  if (getCurrency() == Currencies.BDT) {
    return 'https://prelink.co/bb88yt'
  } else if (getCurrency() == Currencies.INR) {
    return 'https://prelink.co/bb88yt'
  }
}

function isAndroidWebView() {
  let userAgent = window.navigator.userAgent.toLowerCase()
  return userAgent.includes('wv')
}

function getDialCode() {
  let _dialCode = ''
  let language = this.getLanguage()
  switch (this.getCurrency()) {
    case Currencies.INR:
      _dialCode = '+91'
      break
    case Currencies.BDT:
      _dialCode = '+880'
      break
    case Currencies.NPR:
      _dialCode = '+977'
      break
  }
  return _dialCode
}

function changeLocaleOnLogin(fromRegistration = false) {
  let languageList = [
    {
      currency: 'BDT',
      currencyCd: '৳',
      img: 'BDT',
      language: [
        {
          title: 'Bengali',
          cd: 'bd'
        },
        {
          title: 'English',
          cd: 'en'
        }
      ]
    },
    {
      currency: 'INR',
      currencyCd: '₹',
      img: 'INR',
      language: [
        {
          title: 'English',
          cd: 'enin'
        },
        {
          title: 'Hindi',
          cd: 'hi'
        }
      ]
    },
    {
      currency: 'NPR',
      currencyCd: 'रू',
      img: 'NPR',
      language: [
        {
          title: 'English',
          cd: 'enne'
        },
        {
          title: 'Nepalese',
          cd: 'ne'
        }
      ]
    }
  ]
  let beforeLanguage = this.getLanguage()
  let memberSupportedLanguage = languageList.filter(l => l.currency == this.getCurrency())
  if (memberSupportedLanguage && memberSupportedLanguage.length > 0) {
    let currCd = memberSupportedLanguage[0].currency
    let languageCd = memberSupportedLanguage[0].language[0].cd
    let isPrevLangSameCurrency = memberSupportedLanguage[0].language.filter(l => l.cd == beforeLanguage).length > 0 ? true : false

    if (!isPrevLangSameCurrency) {
      /*if prev locale not supported by logged in member then switch*/
      this.removeCookie('language')
      console.log('language to be : ' + languageCd)
      this.setCookie('language', languageCd)
      this.removeCookie('changeLanguage')
      this.setCookie('changeLanguage', true)
      this.setCurrency(currCd)

      let langRegion = ''

      if (languageCd == 'enin') {
        langRegion = 'en'
      } else if (languageCd == 'bd') {
        langRegion = 'bn'
      } else if (languageCd == 'enne') {
        langRegion = 'en'
      } else {
        langRegion = languageCd
      }
      langRegion = langRegion + '-' + currCd.substring(0, 2)
      /*always update the correct regionLocale code when changing(login changes) language or currency */
      this.setCookie(SESSION.REGION_LOCALE, langRegion)
      if (beforeLanguage != languageCd) {
        if (!fromRegistration) {
          const newRegionLocal = this.getRegionLocale()
          console.log('new logged-in region locale : ' + newRegionLocal)
          window.location.href = window.location.origin + '/' + newRegionLocal
        }
      }
    }
  }
}

function setDefaultCurrency() {
  let currencyToSet = SHARED.DEFAULT_CURRENCY
  let browserSupporedLang = window.navigator.userLanguage || window.navigator.language
  let arrLocale = browserSupporedLang.split('-')
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  /*decide translation based on timezone then locale*/
  let arrIndiaTzKeyWords = ['Calcutta', 'Kolkata']
  let isIndiaTimezone =
    arrIndiaTzKeyWords.filter(function(a) {
      return timeZone.indexOf(a) != -1
    }).length > 0

  if (isIndiaTimezone) {
    /*set currency to india's */
    currencyToSet = Currencies.INR
  } else {
    currencyToSet = Currencies.BDT
  }
  setCurrency(currencyToSet)
}

function setDefaultLanguage() {
  let browserSupporedLang = window.navigator.userLanguage || window.navigator.language
  let arrLocale = browserSupporedLang.split('-')
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  /*decide translation based on timezone then locale*/
  let arrIndiaTzKeyWords = ['Calcutta', 'Kolkata']
  let isIndiaTimezone =
    arrIndiaTzKeyWords.filter(function(a) {
      return timeZone.indexOf(a) != -1
    }).length > 0
  let langCookie = ''
  if (isIndiaTimezone) {
    /*set Language to india's translation*/
    langCookie = 'enin'
    setCookie(SESSION.LANGUAGE, langCookie)
  } else {
    setCookie(SESSION.LANGUAGE, SHARED.DEFAULT_LANGUAGE)
    langCookie = SHARED.DEFAULT_LANGUAGE
  }

  return langCookie
}

function setMetaTag(title, desc, keywords, isHome = false) {
  let currentDomain = document.location.origin
  let currentHref = !isHome ? document.location.href : document.location.origin
  let siteBrandName = 'Babu88'

  document.title = title
  document.getElementsByTagName('meta')['description'].content = desc
  document.getElementsByTagName('meta')['keywords'].content = keywords == undefined ? locale.getMessage('meta.default_keyword') : keywords
  document.querySelector('link[rel="canonical"]').href = currentHref
  document.querySelector('meta[property="og:title"]').content = title
  document.querySelector('meta[property="og:description"]').content = desc
  document.querySelector('meta[property="og:url"]').content = currentHref
  document.querySelector('meta[property="og:site_name"]').content = siteBrandName
  document.querySelector('meta[property="og:image"]').content = currentDomain + '/static/svg/logo.svg'
  document.querySelector('meta[name="twitter:card"]').content = 'summary_large_image'
  document.querySelector('meta[name="twitter:image:alt"]').content = siteBrandName
}
function setHreflangLinks(link) {
  const existingLink = document.querySelector('link[rel="alternate"][hreflang="x-default"]')

  // Remove existing hreflang link with the specified lang attribute
  if (existingLink) {
    existingLink.parentNode.removeChild(existingLink)
  }

  // Create and append the new hreflang link
  const linkDocument = document.createElement('link')
  linkDocument.rel = 'alternate'
  linkDocument.hreflang = 'x-default'
  linkDocument.href = link
  document.getElementsByTagName('head')[0].appendChild(linkDocument)
}
function setHreflangLinksen(link) {
  const existingLink = document.querySelector('link[rel="alternate"][hreflang="en-BD"]')

  // Remove existing hreflang link with the specified lang attribute
  if (existingLink) {
    existingLink.parentNode.removeChild(existingLink)
  }

  // Create and append the new hreflang link
  const linkDocument = document.createElement('link')
  linkDocument.rel = 'alternate'
  linkDocument.hreflang = 'en-BD'
  linkDocument.href = link
  document.getElementsByTagName('head')[0].appendChild(linkDocument)
}
function setHreflangLinksbd(link) {
  const existingLink = document.querySelector('link[rel="alternate"][hreflang="bn-BD"]')

  // Remove existing hreflang link with the specified lang attribute
  if (existingLink) {
    existingLink.parentNode.removeChild(existingLink)
  }

  // Create and append the new hreflang link
  const linkDocument = document.createElement('link')
  linkDocument.rel = 'alternate'
  linkDocument.hreflang = 'bn-BD'
  linkDocument.href = link
  document.getElementsByTagName('head')[0].appendChild(linkDocument)
}
function setHreflangLinksenin(link) {
  const existingLink = document.querySelector('link[rel="alternate"][hreflang="en-IN"]')

  // Remove existing hreflang link with the specified lang attribute
  if (existingLink) {
    existingLink.parentNode.removeChild(existingLink)
  }

  // Create and append the new hreflang link
  const linkDocument = document.createElement('link')
  linkDocument.rel = 'alternate'
  linkDocument.hreflang = 'en-IN'
  linkDocument.href = link
  document.getElementsByTagName('head')[0].appendChild(linkDocument)
}
function setHreflangLinkshi(link) {
  const existingLink = document.querySelector('link[rel="alternate"][hreflang="hi-IN"]')

  // Remove existing hreflang link with the specified lang attribute
  if (existingLink) {
    existingLink.parentNode.removeChild(existingLink)
  }

  // Create and append the new hreflang link
  const linkDocument = document.createElement('link')
  linkDocument.rel = 'alternate'
  linkDocument.hreflang = 'hi-IN'
  linkDocument.href = link
  document.getElementsByTagName('head')[0].appendChild(linkDocument)
}
function setHreflangLinksennp(link) {
  const existingLink = document.querySelector('link[rel="alternate"][hreflang="en-NP"]')

  // Remove existing hreflang link with the specified lang attribute
  if (existingLink) {
    existingLink.parentNode.removeChild(existingLink)
  }

  // Create and append the new hreflang link
  const linkDocument = document.createElement('link')
  linkDocument.rel = 'alternate'
  linkDocument.hreflang = 'en-NP'
  linkDocument.href = link
  document.getElementsByTagName('head')[0].appendChild(linkDocument)
}
function setHreflangLinksnenp(link) {
  const existingLink = document.querySelector('link[rel="alternate"][hreflang="ne-NP"]')

  // Remove existing hreflang link with the specified lang attribute
  if (existingLink) {
    existingLink.parentNode.removeChild(existingLink)
  }

  // Create and append the new hreflang link
  const linkDocument = document.createElement('link')
  linkDocument.rel = 'alternate'
  linkDocument.hreflang = 'ne-NP'
  linkDocument.href = link
  document.getElementsByTagName('head')[0].appendChild(linkDocument)
}
function getLiveChatLicense() {
  return SocialMediaContacts[getCurrency()].LIVECHAT.LICENSE
}

function getLiveChatUrl() {
  return SocialMediaContacts[getCurrency()].LIVECHAT.Link
}

function getRegionLocale() {
  var regionLocale = getCookie(SESSION.REGION_LOCALE)
  if (regionLocale == '' || regionLocale == null) {
    const defaultRegionLocale = SHARED.DEFAULT_LANGUAGE + '-' + SHARED.DEFAULT_CURRENCY.substring(0, 2)
    setCookie(SESSION.REGION_LOCALE, defaultRegionLocale)
    regionLocale = defaultRegionLocale
  }
  return regionLocale
}

function setCanonical(link) {
  link = window.location.origin + window.location.pathname.replace(/\/$/, '')
  let canonical = document.querySelector('link[rel="canonical"]')
  if (canonical) {
    document.querySelector('link[rel="canonical"]').setAttribute('href', link)
  } else {
    let linkDocument = document.createElement('link')
    linkDocument.href = link
    linkDocument.rel = 'canonical'
    document.getElementsByTagName('head')[0].appendChild(linkDocument)
  }
}
