<template>
  <v-row no-gutters>
    <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'px-2' :'px-5'">
      <v-snackbar class="pa-0" style="z-index:1000 !important;top:25%" light timeout="-1" v-model="alertShow"
                  width="fit-content" top centered>
        <v-alert
            v-model="alertShow"
            dismissible
            :icon="alertSuccess ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'"
            class="mb-0 pa-1"
            :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
            light
            close-icon="mdi-close"
        >
          {{ alertMessage }}
        </v-alert>
      </v-snackbar>
      <v-form ref="forgotPasswordForm">
<!--        <app-form-field :enabledLabel=true v-model.trim="forgotPasswordObj.memberCode" :label="$t(`field.username`)" :placeholder="$t(`fieldHint.fillUpHere`)" :tooltip="$t(`fieldHint.username`)" :rules="validator.usernameRules()"></app-form-field>-->
        <captcha
            ref="captchaCode"
            v-model.trim="captcha"
            :isOptional="true"
            :errorMsg="captchaErrorMsg"
            :rules="validator.captchaRules()"
        ></captcha>
        <v-row no-gutters>
          <v-col cols="12">
            <label class="input-field-label ma-0 pb-2 d-block">
              {{ $t(`message.forgotPasswordPageDesc`) }}
              <span class="red--text ml-1">*</span></label>
            <v-row no-gutters>
              <v-col cols=4>
                <app-form-field v-model.trim="forgotPasswordObj.prefix" :value="forgotPasswordObj.prefix"  :disabled="true" :readonly="true" :display-text="this.getMobileNumberDisplayText" :custom-class="'mr-2 reg-mobile-prefix'"></app-form-field>
              </v-col>
              <v-col cols=8>
                <app-form-field :enabledLabel=false v-model.trim="forgotPasswordObj.mobile" :placeholder="$t(`fieldHint.fillUpHere`)" :rules="validator.mobileNumberRules(currency)"></app-form-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
      <v-row no-gutters>
        <v-col cols="4" class="pr-2">
          <v-btn height="auto"
                 :disabled="this.$store.state.member.resetForgotPasswordSent.countDownTimer > 0"
                 @click="this.requestSmsVerificationCode"
                 color="black" depressed
                 class="otp-button theme-button font-weight-bold yellow--text">
            {{ this.$store.state.member.resetForgotPasswordSent.countDownTimer > 0 ? formatSeconds(this.$store.state.member.resetForgotPasswordSent.countDownTimer) : $t(`button.requestOtp`) }}
          </v-btn>
        </v-col>

        <v-col cols=8>
          <v-form ref="otpForm">
            <app-form-field :enabledLabel=false v-model.trim="forgotPasswordObj.smsToken"
                            :placeholder="$t(`fieldHint.fillUpHere`)"
                            :rules="validator.requiredRules()"></app-form-field>
          </v-form>
        </v-col>
      </v-row>
      <!-- <app-button :action="this.forgotPassword" :title="$t(`button.submit`)"></app-button> -->
      <v-row no-gutters>
        <v-col cols="12">
          <v-btn height="auto" @click="this.forgotPassword" color="black" depressed class="primary-button theme-button text-capitalize pa-2 font-weight-bold yellow--text">{{ $t(`button.reset`) }}
          </v-btn>
        </v-col>
        <v-col cols="12" class="text-center">
            <label v-show="smsRequestError" class="red--text subtitle-2">{{smsRequestError}}</label>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-6">
        <v-col cols="12">
          <v-divider class="dialog-divider"></v-divider>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols=12>
          <label class="input-field-label ma-0 pb-1 d-block title_color2--text">
          {{ $t(`message.dontHaveAccount`) }}</label>
        </v-col>
      </v-row>
      <v-row no-gutters class="pb-10">
        <v-col cols="12">
          <app-button class=" dialog-button" :action="this.openRegister" :title="$t(`button.register`)"></app-button>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import stringFormat from 'string-format'
  import {
    errorCodeHelper,
    formValidator,
    locale,
    uiHelper
  } from '@/util'
  import {
    MEMBER_SEND_OTP,
    MEMBER_VERIFY_OTP,
    MEMBER_RESET_VERIFY_OTP,
    MEMBER_RESET_SEND_OTP,
    MEMBER_GET_SMS_REQUIREMENT,
    MEMBER_REQUEST_FORGOT_PASSWORD,
    MEMBER_VERIFY_FORGOT_PASSWORD,
    MEMBER_RESET_VERIFY_FORGOT_PASSWORD,
    MEMBER_RESET_REQUEST_FORGOT_PASSWORD
  } from '@/store/member.module'
  import {
    SHARED
  } from '@/constants/constants'
  import { ROUTE_NAME } from '@/constants/route.constants'
  import {SHARED_LOADING} from '@/store/shared.module'
  import Captcha from "@/components/Captcha.vue";
  export default {
    name: 'appForgotPassword',
    components: {Captcha},
    props: {
      openRegisterDialog: {
        type: Function,
        required: true,
        default: () => {
        }
      }
    },
    mounted() {
      this.$store.dispatch(`${MEMBER_RESET_SEND_OTP}`)
      //this.$refs.forgotPasswordForm.reset()
      //this.forgotPasswordObj.mobileNumberPrefix = mobileNumberPrefixList[0].value;
    },
    computed: {
      // smsVerificationSent() {
      //   return this.$store.state.member.otpSent.success
      // },
      otpVerificationSent() {
        return this.$store.state.member.resetForgotPasswordSent.complete;
      },
      smsRequestError() {
        if (this.$store.state.member.otpSent.message) {
          return this.$store.state.member.otpSent.message.data[0]
        }
        return ""
      },
      smsRequestAvailable() {
        return this.$store.state.member.otpSent.countDownTimer <= 0
      },
      smsCountdownTimer() {
        return this.$store.state.member.otpSent.countDownTimer
      },
      smsVerificationResult() {
        return this.$store.state.member.otpVerified.success
      },
      verifyForgotPasswordSent() {
        return this.$store.state.member.verifyForgotPasswordSent.complete;
      }
    },
    watch: {
      otpVerificationSent() {
        let response = this.$store.state.member.resetForgotPasswordSent;
        if (response.complete) {
          if (!response.success) {
            if(response.code == -1) {
              this.openAlert(response.success, locale.getMessage("fieldErrorMessage.invalidCaptchaCode"));
              this.$store.dispatch(`${MEMBER_RESET_REQUEST_FORGOT_PASSWORD}`);
            } else if(response.code === "3.691") {
              this.openAlert(response.success, response.error);
              this.$store.dispatch(`${MEMBER_RESET_REQUEST_FORGOT_PASSWORD}`);
            } else {
              this.openAlert(response.success, errorCodeHelper.getErrorCodeDesc(response.code));
              this.$store.dispatch(`${MEMBER_RESET_REQUEST_FORGOT_PASSWORD}`);
            }
          }
        }
      },
      verifyForgotPasswordSent() {
        let response = this.$store.state.member.verifyForgotPasswordSent;
        if (response.complete) {
          if (response.success) {
            this.openAlert(response.success, "success");
            console.log("hi")
            this.$router.push({
              name: ROUTE_NAME.RESET_PASSWORD,
              query: {
                memberCode: response.membercode,
                contact: response.contact
              }
            });
          } else {
            if(response.code == -1) {
              this.openAlert(response.success, locale.getMessage("fieldErrorMessage.invalidCaptchaCode"));
            } else if(response.code === "3.691") {
              this.openAlert(response.success, response.error);
            } else {
              this.openAlert(response.success, errorCodeHelper.getErrorCodeDesc(response.code));
            }
          }
          this.$store.dispatch(`${MEMBER_RESET_VERIFY_FORGOT_PASSWORD}`);
          this.$store.dispatch(`${SHARED_LOADING}`);
        }
      },
    },
    data: () => ({
      defaultLanguage: SHARED.DEFAULT_LANGUAGE,
      currency: uiHelper.getCurrency(),
      validator: formValidator,
      stringFormat: stringFormat,
      retainSignIn: false,
      forgotPasswordObj: {
        memberCode: '',
        mobile: '',
        smsToken: '',
        prefix: uiHelper.getDialCode()
      },
      mobileNumberPrefixList: [
        // {
        //   text: "+91",
        //   value: "+91"
        // },
        {
          text: "+880",
          value: "+880"
        }
      ],
      alertShow: false,
      alertSuccess: false,
      alertMessage: "",
      captcha: "",
      captchaErrorMsg: undefined
    }),
    methods: {
      getMobileNumberDisplayText(data) {
        return data.text
      },
      requestSmsVerificationCode() {
        this.$store.state.member.otpSent.success = false
        if (this.captcha != null && this.$refs.forgotPasswordForm.validate()) {
          let data = {
            // memberCode: this.forgotPasswordObj.memberCode,
            mobileNumber: this.forgotPasswordObj.mobile,
            prefix: this.forgotPasswordObj.prefix,
            captchaId: this.$refs.captchaCode.$refs.code.id,
            captchaCode: this.captcha,
          }
          this.$store.dispatch(`${MEMBER_REQUEST_FORGOT_PASSWORD}`, {
            data
          })
        }
      },
      forgotPassword() {
        if (this.captcha != null && this.$refs.otpForm.validate()) {
          if (this.$refs.forgotPasswordForm.validate()) {
            let data = {
              mobileNumber: this.forgotPasswordObj.mobile,
              smsToken: this.forgotPasswordObj.smsToken,
              captchaId: this.$refs.captchaCode.$refs.code.id,
              captchaCode: this.captcha
            }
            this.$store.dispatch(`${MEMBER_VERIFY_FORGOT_PASSWORD}`, {
              data
            })
          }
        }
      },
      openRegister() {
        this.$refs.forgotPasswordForm.reset()
        this.openRegisterDialog()
      },
      openAlert(success, msg) {
        this.alertSuccess = success;
        this.alertMessage = msg;
        this.alertShow = true;
      },
      formatSeconds(secs) {
        const pad = (n) => n < 10 ? `0${n}` : n;

        const h = Math.floor(secs / 3600);
        const m = Math.floor(secs / 60) - (h * 60);
        const s = Math.floor(secs - h * 3600 - m * 60);

        return `${pad(m)}:${pad(s)}`;
      }
    }
  }
</script>

<style lang="scss">
.profile-alert-success {
  .v-icon {
    color: #25b34b;
  }
}
.profile-alert-failed {
  .v-icon {
    color: #dc2d2d;
  }
}
  .dialog-divider {
    border-width: 0.8px !important;
    border-color: #ACACAC !important;
    height: 100%;
  }
  .otp-button{
        color: black;
        border-radius: 10px;
        width: 100%;
        font-size: 14px !important;
        padding: 9px !important;
        text-transform: capitalize;
    }
  @media (max-width: 959px) {
        .otp-button{
            //width: 102 !important;
            height: 35px !important;
            font-size: 13px !important;
            padding: 10px !important;
        }
    }
    @media (max-width: 599px) {
        .otp-button{
            //width: fit-content !important;
            height: 35px !important;
            font-size: 13px !important;
            padding: 11px !important;
        }
    }
    @media (max-width: 425px) {
        .otp-button{
            //width: fit-content !important;
            height: 35px !important;
            font-size: 13px !important;
            padding: 7px !important;
        }
    }
    @media (max-width: 375px) {
        .otp-button{
            //width: fit-content !important;
            height: 35px !important;
            font-size: 11px !important;
            padding: 4px !important;
        }
        .reg-mobile-prefix.v-input {
          font-size: 12px !important;

          .v-select__selection--comma {
            margin: 7px 0px 7px 0px !important;
          }

          .v-input__icon {
            height: 14px !important;
            width: 14px !important;
            min-width: 14px !important;
          }
        }
    }
</style>
