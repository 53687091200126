<template>
  <v-row no-gutters>
    <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'language_panel px-3' : 'language_panel px-5'">
      <v-row no-gutters align="center" justify="space-between" v-for="(lang, index) in filterLanguages" :key="lang.currency">
        <v-col cols="2">
          <v-avatar class="language-button" :size="$vuetify.breakpoint.xsOnly ? '35' : '48'">
            <img :src="`/static/image/country/${lang.img}.svg`" />
          </v-avatar>
        </v-col>
        <v-col cols="2" class="text-center">
          <label>{{ lang.currencyCd }} {{ lang.currency }}</label>
        </v-col>
        <!-- <v-col cols="4" class="text-right px-3" v-for="l in lang.language" :key="l.cd">
            <v-btn v-model="selectedLanguage" class="full-width" outlined>{{l.title}}</v-btn>
        </v-col> -->
        <v-col cols="8">
          <v-btn-toggle v-model="selectedLanguage" class="full-width">
            <v-row no-gutters>
              <v-col cols="6" class="text-right" :class="$vuetify.breakpoint.xsOnly ? 'px-1' : 'px-3'" v-for="l in lang.language" :key="l.cd.toLowerCase()">
                <!--                       <a class="localized" :href="localizedLink(l.cd,lang.currency)">-->
                <v-btn :value="lang.currency.toLowerCase() + l.cd.toLowerCase()" class="full-width font-weight-bold" outlined @click="changeLanguage(l.cd, lang.currency)">{{ l.title }}</v-btn>
                <!--                       </a>-->
              </v-col>
            </v-row>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" class="my-2"><v-divider v-if="index < languageList.length - 1" class="language-divider"></v-divider></v-col>
      </v-row>
      <!-- <v-row no-gutters  align="center">
         <v-col cols="2">
             <v-avatar class="language-button">
                 <img :src="`/static/image/country/bd.svg`"/>
             </v-avatar>
         </v-col>
         <v-col cols="2">
             <label>₹ INR</label>
         </v-col>
         <v-col cols="4">
             <v-btn color="black" outlined>English</v-btn>
         </v-col>
         <v-col cols="4">
             <v-btn color="black" outlined>Bengeli</v-btn>
         </v-col>
     </v-row> -->
    </v-col>
  </v-row>
</template>
<script>
import { SHARED_LOADING } from '@/store/shared.module'
import stingFormat from 'string-format'
import { formValidator, uiHelper, errorCodeHelper } from '@/util'
import { SHARED } from '@/constants/constants'
import { MEMBER_LOGIN } from '@/store/member.module'
export default {
  name: 'appLanguage',
  props: {
    closeAction: {
      type: Function,
      required: true,
      default: () => {}
    }
  },
  data: () => ({
    languageList: [
      {
        currency: 'BDT',
        currencyCd: '৳',
        img: 'BDT',
        language: [
          {
            title: 'English',
            cd: 'en'
          },
          {
            title: 'Bengali',
            cd: 'bd'
          }
        ]
      },
      {
        currency: 'INR',
        currencyCd: '₹',
        img: 'INR',
        language: [
          {
            title: 'English',
            cd: 'enin'
          },
          {
            title: 'Hindi',
            cd: 'hi'
          }
        ]
      },
      {
        currency: 'NPR',
        currencyCd: 'रु',
        img: 'NPR',
        language: [
          {
            title: 'English',
            cd: 'enne'
          },
          {
            title: 'Nepalese',
            cd: 'ne'
          }
        ]
      }
    ],
    selectedLanguage: '',
    currentLanguage: uiHelper.getLanguage()
  }),
  computed: {
    modifiedLanguageCodes() {
      // Map language codes to their modifications
      const modifications = {
        bd: 'bn', // Add other mappings if needed
        enin: 'en',
        enne: 'en'
      }
      return modifications
    },
    modifiedCurrencyCodes() {
      // Map currency codes to their modifications
      const modifications = {
        BDT: 'BD', // Add other mappings if needed
        INR: 'IN',
        NPR: 'NP'
        // Add more currency mappings here
      }
      return modifications
    },
    localizedLink() {
      return (languageCode, currencyCode) => {
        const modifiedLanguageCode = this.modifiedLanguageCodes[languageCode] || languageCode
        const modifiedCurrencyCode = this.modifiedCurrencyCodes[currencyCode] || currencyCode
        const languageSegment = modifiedLanguageCode ? `${modifiedLanguageCode}-` : ''
        const currencySegment = modifiedCurrencyCode ? `${modifiedCurrencyCode}` : ''
        const path = this.getLocalizedPath() // Implement this function to return the path without any codes.
        return `${languageSegment}${currencySegment}${path}`
      }
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    currentCurrency() {
      return uiHelper.getCurrency()
    },
    filterLanguages() {
      if (this.isLoggedIn) {
        let list = this.languageList.filter(l => l.currency == this.currentCurrency)
        if (list && list.length > 0) return list
        else return this.languageList
      } else {
        return this.languageList
      }
    }
  },
  created() {
    this.selectedLanguage = this.currentCurrency.toLowerCase() + this.currentLanguage.toLowerCase()
    console.log('language.vue selectedLanguage:', this.selectedLanguage)
  },
  methods: {
    getCountryCode(code) {
      let REG = /^[a-z]{2,}(?:-[A-Z][a-z]*)*-([A-Z]{2})$/
      var match = code.match(REG)
      if (!match) return ''
      // console.log('isValidCountryCode:', match);
      return match[1]
    },
    getLocalizedPath() {
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '')
      return cleanPath
    },
    changeLanguage(languageCd, currCd) {
      this.$store.dispatch(`${SHARED.LOADING}`)
      let langRegion = ''

      if (languageCd == 'enin') {
        langRegion = 'en'
      } else if (languageCd == 'bd') {
        langRegion = 'bn'
      } else if (languageCd == 'enne') {
        langRegion = 'en'
      } else {
        langRegion = languageCd
      }
      langRegion = langRegion + '-' + currCd.substring(0, 2)
      uiHelper.removeCookie('regionLocale')
      uiHelper.removeCookie('language')
      uiHelper.removeCookie('changeLanguage')
      uiHelper.setCookie('regionLocale', langRegion)
      uiHelper.setCookie('language', languageCd)
      uiHelper.setCookie('changeLanguage', true)
      uiHelper.setCurrency(currCd)
      // const path = window.location.pathname;
      // const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '');
      let extractPath = window.location.pathname.split('/')
      const results = extractPath.filter(element => {
        return element !== ''
      })
      if (results.length > 0) {
        let region = results[0]
        //test is valid locale
        let isValidCountryCode = this.getCountryCode(region)
        if (isValidCountryCode) {
          results.shift()
          results.unshift(uiHelper.getRegionLocale())
          const joinUrl = results.join('/')
          window.location.replace(window.location.origin + '/' + joinUrl)
        } else {
          this.$router.push({ params: { lang: uiHelper.getRegionLocale() } })
          window.location.reload()
        }
      } else {
        this.$router.push({ params: { lang: uiHelper.getRegionLocale() } })
        window.location.reload()
      }
    }
  }
}
</script>

<style lang="scss">
.language_panel {
  color: #acacac;
  .language-divider {
    border-color: #ebebeb;
  }
  .v-btn {
    color: #acacac;
    &.v-btn--active {
      color: #fddd04;
      border-color: #fddd04 !important;
      border: 2px solid #fddd04 !important;
    }
  }
}
@media (max-width: 425px) {
  .language_panel {
    font-size: 12px;
    .v-btn {
      font-size: 12px !important;
      height: 35px !important;
    }
  }
}
</style>
