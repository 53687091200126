<template>
  <v-dialog persistent :value="dialogShow" :max-width="maxWidth">
    <v-card class="dialog-card">
      <v-row justify="space-between" no-gutters :class="$vuetify.breakpoint.mdAndDown ? 'dialog-header pt-5 px-9' : 'dialog-header pt-8 px-11'">
        <v-col cols="11">
          <label v-if="!isAuth && !isRegSuccess" class="dialog-title pt-3 ma-0 text-capitalize d-block full-width">
            {{ title }}
          </label>
          <img v-if="isRegSuccess" src="/static/svg/logo.svg" alt width="133" height="22" style="float:left;" />
        </v-col>
        <!-- <label v-else class="title pa-3 ma-0 text-center white--text text-capitalize d-block full-width">
                                <v-row no-gutters class="text-center pa-4">
                                    <v-col cols="5" class="text-uppercase">
                                        <v-btn class="subtitle-1" text @click="openLoginDialog"
                                               :color="this.header_title == 'login' ? '#FFCE5E' : '#FFFFFF'">
                                            {{$t(`label.login`) }}
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="1">
                                        <v-divider vertical></v-divider>
                                    </v-col>
                                    <v-col cols="5" class="text-uppercase">
                                        <v-btn class="subtitle-1" text @click="openRegisterDialog"
                                               color="#ACACAC">
                                            {{$t(`label.register`) }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </label> -->
        <v-col cols="1" class="d-flex align-center justify-center pt-3">
          <v-icon :class="['dialog-close-icon', this.header_title == 'register' ? 'register' : this.header_title == 'login' ? 'login' : '']" @click="closeAction" v-if="closeAction != null">
            mdi-close
          </v-icon>
        </v-col>
        <v-col cols="12" class="pt-2" v-if="subtitle">
          <label class="full-width dialog-subtitle">{{ subtitle }}</label>
        </v-col>
      </v-row>
      <v-row no-gutters :class="$vuetify.breakpoint.mdAndDown ? 'px-9 py-3' : 'px-11 py-6'">
        <v-col cols="12">
          <v-divider class="dialog-divider"></v-divider>
        </v-col>
      </v-row>
      <!-- v-if is to allow component to reinitialize it Life cycle -->
      <v-row no-gutters class="d-block dialog-row" :class="customClass" v-if="dialogShow">
        <slot></slot>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'appDialog',
  props: {
    header_title: {
      type: String,
      required: false
    },
    isAuth: {
      type: Boolean,
      default: false,
      required: false
    },
    closeAction: {
      required: false,
      default: null
    },
    title: {
      type: String,
      required: true,
      default: ''
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 400
    },
    minHeight: {
      type: Number,
      required: false,
      default: 500
    },
    dialogShow: {
      type: Boolean,
      required: true,
      default: false
    },
    customClass: {
      type: String,
      required: false,
      default: 'pa-6 pt-0'
    },
    openRegisterDialog: {
      type: Function,
      default: () => ({})
    },
    openLoginDialog: {
      type: Function,
      default: () => ({})
    },
    isRegSuccess: {
      type: Boolean,
      default: false,
      required: false
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style>
/* .logo-color {
        .path.b {
            fill: #fddd04;
        }
    } */
.v-dialog {
  overflow: hidden;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}
</style>

<style lang="scss" scoped>
.dialog-logo {
  width: 133px;
  height: 22px;
  padding-top: 4px;
}
.dialog-card {
  .dialog-title {
    font-size: 20px;
  }
  .dialog-subtitle {
    font-size: 16px;
  }
}
.dialog-header {
  position: relative;
}
.dialog-close-icon {
  position: absolute;
  //top: 70% !important;
  font-size: 30px !important;
  cursor: pointer;
  //transform: translateY(-50%);
  //&.register,
  //&.login {
  //  top: 23%;
  //}
}
.dialog-row {
  overflow-y: scroll;
  scrollbar-width: thin;
  max-height: 500px;
}
.dialog-divider {
  border-width: 0.8px !important;
  border-color: #acacac !important;
  height: 100%;
}
@media (max-height: 600px) {
  .dialog-row {
    max-height: 300px;
  }
  .dialog-card {
    .dialog-title {
      font-size: 18px !important;
    }
  }
}
@media (max-height: 400px) {
  .dialog-row {
    height: 200px;
  }
}

@media (max-width: 425px) {
  .dialog-card {
    .dialog-title {
      font-size: 16px !important;
    }
    .dialog-subtitle {
      font-size: 12px !important;
    }
  }
}
</style>
