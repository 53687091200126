<template>
  <svg width="20" height="18" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <g>
	<circle class="st0" cx="50" cy="50" r="45"/>
      <path class="st1" d="M47.1,78.5c-15.4,0-27.9-12.5-27.9-27.9c0-15.4,12.5-27.8,27.9-27.8C62.6,22.8,75,35.3,75,50.7
		C75,66,62.5,78.5,47.1,78.5z"/>
      <path class="st2" d="M50,5C25.1,5,5,25.1,5,50s20.1,45,45,45s45-20.1,45-45S74.9,5,50,5z M50,93C26.3,93,7,73.7,7,50S26.3,7,50,7
		s43,19.3,43,43S73.7,93,50,93z"/>
</g>
</svg>
</template>

<script>
export default {
  name: "BdtIcon"
}
</script>

<style>
.st0{fill:#006A4E;}
.st1{fill:#F32A41;}
.st2{fill:#3A3A3A;}
</style>