import {apiHelper} from '@/util'

export const transferService = {
    getTransferType,
    getTransferHistory,
    transferWallet,
    semiTransfer,
    withdrawalRestore,
    getTransactionHistory
}

//API URLs
const TRANSFER = {
    TRANSFER: 'transfer',
    HISTORY: 'histories',
    TYPES: 'types',
    SEMITRANSFER: 'semi_transfer',
    RESTORE: 'restore'
}

function getTransferType() {
    return apiHelper().get(`/${TRANSFER.TRANSFER}/${TRANSFER.TYPES}`)
}

function transferWallet(obj) {
    let requestOptions = {
        from_wallet: obj.from_wallet,
        to_wallet: obj.to_wallet,
        amount: obj.amount
    }
    return apiHelper().post(`/${TRANSFER.TRANSFER}`, requestOptions)
}

function getTransferHistory(obj) {
    let requestOptions = {
        params: {
            page: obj.page,
            size: obj.itemsPerPage,
            status: obj.status,
            type: obj.type,
            from_wallet: obj.fromWallet,
            to_wallet: obj.toWallet,
            start_time: obj.fromDate,
            end_time: obj.toDate
        }
    }
    return apiHelper().get(`/${TRANSFER.TRANSFER}/${TRANSFER.HISTORY}`, requestOptions)
}

function semiTransfer(obj) {
    let requestOptions = {
        to_wallet: obj.to_wallet
    }
    return apiHelper().post(`/${TRANSFER.TRANSFER}/${TRANSFER.SEMITRANSFER}`, requestOptions)
}

function withdrawalRestore() {
    return apiHelper().post(`/${TRANSFER.TRANSFER}/${TRANSFER.RESTORE}`)
}

function getTransactionHistory(data) {
    const requestBody = {
        params: {
            page: data.page,
            start_time: data.startTime,
            end_time: data.endTime,
            size: data.size
        }
    }
    return apiHelper().get(`/${TRANSFER.TRANSFER}/${TRANSFER.HISTORY}`, requestBody)
  }
