import { locale } from '@/util'

export default function yesNoStatusDesc(value) {
  let desc = ''

  if (typeof value === 'number') {
    desc = locale.getMessage(`enumYesNoStatus[0].${value}`)
  } else if (typeof value === 'boolean') {
    desc = locale.getMessage(`enumYesNoStatus[1].${value}`)
  }

  return desc
}
