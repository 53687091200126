import { apiHelper } from '@/util'
export const lookupService = {
  getCountry,
  getProvince
}

//API URLs
const LOOKUP = {
  LOOKUP: 'lookup',
  COUNTRIES: 'countries',
  PROVINCES: 'provinces'
}

function getCountry(data) {
  const requestOptions = {
    params: {
      language: data.language
    }
  }
  return apiHelper().get(`/${LOOKUP.LOOKUP}/${LOOKUP.COUNTRIES}`, requestOptions)
}

function getProvince(data) {
  const requestOptions = {
    params: {
      language: data.language,
      country_code: data.countryCode
    }
  }
  return apiHelper().get(`/${LOOKUP.LOOKUP}/${LOOKUP.PROVINCES}`, requestOptions)
}
