<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100" xml:space="preserve">
    <defs>
    <circle id="SVGID_1_" cx="50" cy="50" r="44"/>
  </defs>
    <clipPath id="SVGID_00000069394261185534405960000001579436442455642244_">
    <use xlink:href="#SVGID_1_" style="overflow:visible"/>
  </clipPath>
    <g style="clip-path:url(#SVGID_00000069394261185534405960000001579436442455642244_)">
    <path id="a_00000011719253386407291090000000500741252097350046_" class="st1" d="M16.2 49.1h61.2L16.2 10.8v81.6h61.2L16.2 31.2v17.9z"/>
      <path d="M83.7 95H13.5V6.1l73 45.7H40.4L83.7 95zm-64.9-5.3H71l-38-38H18.8v38zm16.4-43.2h33L18.8 15.6v14.5l16.4 16.4zm-16.4 0h8.9l-8.9-8.9v8.9z" style="fill:#003893"/>
      <path id="a_00000168818452395510327630000000169723064554443663_" class="st1" d="M16.2 49.1h61.2L16.2 10.8v81.6h61.2L16.2 31.2v17.9z"/>
      <path class="st3" d="M19.3 34.8c2.6 6.7 10.2 10 17 7.4 3.4-1.3 6.1-4 7.4-7.4 0 6.7-5.5 12.2-12.2 12.2s-12.2-5.5-12.2-12.2"/>
      <g transform="translate(0 29.045) scale(5.56106)">
      <circle class="st3" cx="5.7" cy="2" r="1"/>
        <g id="d">
        <g id="c">
          <path id="b" class="st3" d="M6 1.1 5.9.7l-.2.3"/>
          <g transform="rotate(22.5)">
            <path id="b_00000034080770239468135650000007343228172382275492_" class="st3" d="m6.4 1.3.1-.5-.5.3"/>
          </g>
          <g transform="rotate(45)">
            <path id="b_00000049205723098574639120000012948126226668119184_" class="st3" d="m6.6 1.7.2-.4h-.4"/>
          </g>
        </g>
          <g transform="rotate(67.5)">
          <g id="c_00000116945944691258108530000014600820676928458127_">
            <path id="b_00000163779126806985164690000012533477753211854990_" class="st3" d="m6.7 2 .3-.2-.4-.1"/>
            <g transform="rotate(22.5)">
              <path id="b_00000009560603494028774850000013323027174862427820_" class="st3" d="m6.6 2.4.4-.1-.3-.3"/>
            </g>
            <g transform="rotate(45)">
              <path id="b_00000080176244598733990110000016365996224790329002_" class="st3" d="m6.4 2.8.5.1-.3-.5"/>
            </g>
          </g>
        </g>
      </g>
        <g transform="scale(-1 1)">
        <g id="d_00000058550510642524621850000000017360531530046896_">
          <g id="c_00000087374528846928285760000010629889493015534244_">
            <path id="b_00000087373884508872046950000014965196820332660902_" class="st3" d="m5.3 1.1.1-.4.3.3"/>
            <g transform="rotate(22.5)">
              <path id="b_00000088822027664500078130000009196634119278782102_" class="st3" d="M4.9 1.3V.9l.4.2"/>
            </g>
            <g transform="rotate(45)">
              <path id="b_00000106829896528419282240000015681834683073063854_" class="st3" d="m4.7 1.7-.2-.4.5.1"/>
            </g>
          </g>
          <g transform="rotate(67.5)">
            <g id="c_00000088842634323804872530000014150271518603780797_">
              <path id="b_00000170280842953155602150000017692367275479369881_" class="st3" d="m4.6 2-.3-.2.4-.1"/>
              <g transform="rotate(22.5)">
                <path id="b_00000090978362199468524670000012803155759604207530_" class="st3" d="m4.7 2.4-.4-.1.3-.3"/>
              </g>
              <g transform="rotate(45)">
                <path id="b_00000072246575993937865380000014962080810812480147_" class="st3" d="M4.9 2.8h-.4l.2-.4"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
      <g transform="translate(0 58.787) scale(8.1434)">
      <circle class="st3" cx="3.9" cy="1.5" r="1"/>
        <g id="g">
        <g id="f">
          <path id="e" class="st3" d="m4.1 2.5-.2.6-.3-.6"/>
          <g transform="rotate(180)">
            <path id="e_00000155142262931315535760000008796520914022780067_" class="st3" d="m3.6.5.3-.6.3.6"/>
          </g>
        </g>
          <g transform="rotate(90)">
          <g id="f_00000127013783111676581040000001082307443351207842_">
            <path id="e_00000054266767676905593470000007964988580218821031_" class="st3" d="m2.9 1.7-.6-.2.6-.3"/>
            <g transform="rotate(180)">
              <path id="e_00000116924146081899547210000006954798717050651292_" class="st3" d="m4.8 1.2.6.3-.6.2"/>
            </g>
          </g>
        </g>
      </g>
        <g transform="rotate(30)">
        <g id="g_00000083785925626123565290000018393910593508275632_">
          <g id="f_00000167355700273813177370000006289469783734274965_">
            <path id="e_00000158016789476163420590000017336001275873768866_" class="st3" d="m3.6 2.5-.5.4.1-.7"/>
            <g transform="rotate(180)">
              <path id="e_00000162311450826279310120000016419568727713984446_" class="st3" d="m4.1.5.5-.4v.6"/>
            </g>
          </g>
          <g transform="rotate(90)">
            <g id="f_00000042732570209040882680000016308331792773252508_">
              <path id="e_00000090277912336746481600000012849131494998014343_" class="st3" d="M2.9 1.2 2.5.7l.7.1"/>
              <g transform="rotate(180)">
                <path id="e_00000044880646662187113530000008385987004272925825_" class="st3" d="m4.8 1.7.4.5h-.6"/>
              </g>
            </g>
          </g>
        </g>
      </g>
        <g transform="rotate(60)">
        <g id="g_00000121244048056233284580000005965273735962473120_">
          <g id="f_00000110443821232277335170000017211489238690728622_">
            <path id="e_00000046314555134386888760000016001198087902555833_" class="st3" d="m3.1 2.2-.6.1.4-.5"/>
            <g transform="rotate(180)">
              <path id="e_00000148661865900107141380000010553981601641580699_" class="st3" d="m4.6.7.7-.1-.5.6"/>
            </g>
          </g>
          <g transform="rotate(90)">
            <g id="f_00000025436540940758553670000015345945425092907940_">
              <path id="e_00000142161754225829854180000011393786031174986656_" class="st3" d="M3.1.7V.1l.5.4"/>
              <g transform="rotate(180)">
                <path id="e_00000169538585664650173380000012149538710777905829_" class="st3" d="m4.6 2.2.1.7-.6-.4"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
    <path d="M50 5C25.1 5 5 25.1 5 50s20.1 45 45 45 45-20.1 45-45S74.9 5 50 5zm0 88C26.3 93 7 73.7 7 50S26.3 7 50 7s43 19.3 43 43-19.3 43-43 43z" style="fill:#3a3a3a"/>
</svg>

</template>

<script>
export default {
  name: "NprIcon"
}
</script>

<style>
.st1{fill:#dc143c}.st3{fill:#fff}
</style>