export default function floatParser(value) {
  let result = ''

  value = value == null ? parseFloat('0') : parseFloat(value)

  if (value == 0) {
    result = ` ${value.toFixed(2).toString()}`
  } else {
    result = ` ${value
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  }

  return result
}
