<template>
  <v-row no-gutters>
    <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'login_panel ' : 'login_panel px-5'">
      <v-row class="hidden-md-and-up" v-if="isMobileLogin" style="margin: unset">
        <v-col cols="12" class="mobile-header text-center">
          {{ $t(`label.loginToBabu`) }}
        </v-col>
      </v-row>
      <v-row class="hidden-sm-and-down mt-4" style="margin: unset">
        <v-col cols="12" class="login-header text-center py-0">
          {{ $t(`label.login`) }}
        </v-col>
        <v-col cols="12" class="login-header-desc text-center pt-0">
          {{ $t(`label.welcomeBack`) }}
        </v-col>
      </v-row>
      <v-row class="justify-center" :class="$vuetify.breakpoint.mdAndUp ? 'mt-2 mb-6' : ''">
        <v-col cols="12" md="4" :class="$vuetify.breakpoint.mdAndUp ? 'login-form-bg pt-0 mb-12' : ''">
          <v-form ref="loginForm" class="ma-8">
            <v-row class="justify-center">
              <v-col cols="12">
                <app-form-field
                  :enabledLabel="true"
                  v-model.trim="loginObj.memberCode"
                  :label="$t(`field.username`)"
                  :placeholder="$t(`fieldHint.fillUpHere`)"
                  :tooltip="$t(`fieldHint.username`)"
                  :rules="validator.usernameRules()"
                ></app-form-field>
                <app-form-field
                  :enabledLabel="true"
                  v-model.trim="loginObj.password"
                  :label="$t(`field.password`)"
                  :placeholder="$t(`fieldHint.fillUpHere`)"
                  :tooltip="$t(`fieldHint.password`)"
                  :rules="validator.passwordRules()"
                  :allowShowPassword="false"
                  type="password"
                ></app-form-field>
                <!-- desktop view login button -->
                <v-row no-gutters class="hidden-sm-and-down">
                  <v-col cols="12">
                    <v-btn min-height="50px" height="auto" @click="this.login" color="black" depressed
                           class="primary-button desktop-login-btn theme-button text-capitalize pa-2">
                      {{ $t(`button.login`) }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <label v-show="loginErrorMsg" class="red--text subtitle-2">{{ loginErrorMsg }}</label>
                    <label v-show="fullPageLoginErrMsg" class="red--text subtitle-2">{{ fullPageLoginErrMsg }}</label>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mb-1">
                  <v-col cols="12" class=""
                         :class="$vuetify.breakpoint.mdAndUp ? 'text-center' : 'text-right forgot-password'">
                    <v-btn
                      text
                      height="auto"
                      v-on:click="isMobileLogin ? openSelfForgotPasswordDialog() : openForgotPasswordDialog()"
                      class="text-capitalize body-3 text-decoration-underline"
                      :class="$vuetify.breakpoint.mdAndUp ? 'forgot-text pt-5' : 'pr-0 title_color2--text font-weight-bold'"
                    >
                      {{ $t(`label.forgotPassword`) }}
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- mobile view login button -->
                <v-row no-gutters class="hidden-md-and-up">
                  <v-col cols="12">
                    <v-btn
                      height="auto"
                      @click="this.login"
                      color="black"
                      depressed
                      class="primary-button theme-button text-capitalize pa-2 font-weight-bold yellow--text"
                      :class="isMobileLogin ? 'mobile-login-btn' : ''"
                    >
                      {{ $t(`button.login`) }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <label v-show="loginErrorMsg" class="red--text subtitle-2">{{ loginErrorMsg }}</label>
                    <label v-show="fullPageLoginErrMsg" class="red--text subtitle-2">{{ fullPageLoginErrMsg }}</label>
                  </v-col>
                </v-row>
                <!-- desktop view dont-have account button -->
                <v-row no-gutters class="hidden-sm-and-down">
                  <v-col cols="12">
                    <p class="input-field-label desktop-register text-center ma-0">
                      <label>{{ $t(`message.dontHvAccYet`) }}</label>
                      <label @click="openRegister" class=" forgot-text text-decoration-underline">
                        {{ $t(`label.registerHere`) }}
                      </label>
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters :class="$vuetify.breakpoint.mdAndUp ? 'py-4' : 'py-6'">
                  <v-col cols="12">
                    <v-divider class=""
                               :class="$vuetify.breakpoint.mdAndUp ? 'dialog-divider-desktop' : 'dialog-divider'"></v-divider>
                  </v-col>
                </v-row>
                <!-- mobile view dont-have account button -->
                <v-row no-gutters class="hidden-md-and-up">
                  <v-col cols="12">
                    <label class="input-field-label ma-0 pb-1 d-block title_color2--text">
                      {{ $t(`message.dontHaveAccount`) }}
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pb-10 hidden-md-and-up">
                  <v-col cols="12">
                    <app-button class="dialog-button" :action="this.openRegister"
                                :title="$t(`button.register`)"></app-button>
                  </v-col>
                </v-row>
                <!-- desktop view tnc -->
                <v-row no-gutters class="hidden-sm-and-down px-2">
                  <v-col cols="12">
                    <label class="login-tnc text-center ma-0 pb-1 d-block">
                      {{ $t(`message.loginTnc`) }}
                    </label>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
    <!-- FORGOT PASSWORD DIALOG -->
    <app-dialog :dialogShow="forgotPasswordDialogShow" :max-width="400" :title="$t(`label.resetPassword`)"
                :closeAction="this.closeForgotPasswordDialog">
      <app-forgot-password :openRegisterDialog="openRegister"></app-forgot-password>
    </app-dialog>
  </v-row>
</template>

<script>
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import stingFormat from "string-format";
import { formValidator, uiHelper, errorCodeHelper } from "@/util";
import { SESSION, SHARED } from "@/constants/constants";
import { MEMBER_LOGIN, MEMBER_RESET_LOGIN_STORE, MEMBER_RESET_MOBILE_LOGIN_STORE } from "@/store/member.module";
import { SHARED_LOADING, SHARED_UNLOADING } from "@/store/shared.module";
import { ROUTE_NAME } from "@/constants/route.constants";
import AppForgotPassword from "@/components/member/forgotPassword";

export default {
  name: "appLogin",
  components: { AppForgotPassword },
  mounted() {
    this.$refs.loginForm.reset();
  },
  created() {
    if (this.isLoggedIn) {
      //return to home
      this.$router.push({
        name: ROUTE_NAME.HOME
      });
    }
  },
  props: {
    closeAction: {
      type: Function,
      required: true,
      default: () => {
      }
    },
    openForgotPasswordDialog: {
      type: Function,
      required: true,
      default: () => {
      }
    },
    loginErrorMsg: {
      type: String,
      required: true,
      default: ""
    },
    isMobileLogin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    forgotPasswordDialogShow: false,
    validator: formValidator,
    stingFormat: stingFormat,
    retainSignIn: false,
    loginObj: {
      memberCode: "",
      password: "",
      domain: "",
      platform: "",
      option: SHARED.DEFAULT_LOGIN_PASSWORD_METHOD,
      fp: ""
    },
    fullPageLoginErrMsg: ""
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn;
    },
    selfLoginResponseComplete() {
      return this.$store.state.member.mobileLoginResponse.complete;
    }
  },
  watch: {
    isLoggedIn() {
      this.$refs.loginForm.reset();
      if (this.isLoggedIn) {
        //return to home
        this.$router.push({
          name: ROUTE_NAME.HOME
        });
      }
    },
    selfLoginResponseComplete() {
      //only handle to display single page login error msg , redirection still handled by layoutmaster.vue 's loginResp complete
      let response = this.$store.state.member.mobileLoginResponse;
      if (response.code != 0 && !response.success) {
        if (this.isMobileLogin) {
          this.fullPageLoginErrMsg = errorCodeHelper.getErrorCodeDesc(response.code);
        }
      }
      this.$store.dispatch(`${MEMBER_RESET_MOBILE_LOGIN_STORE}`);
    }
  },
  methods: {
    // selfLoginResponseComleteDialog(){}
    closeForgotPasswordDialog() {
      this.forgotPasswordDialogShow = false;
    },
    openSelfForgotPasswordDialog() {
      this.forgotPasswordDialogShow = true;
      console.log("opened forgot password");
    },
    openRegister() {
      this.$refs.loginForm.reset();
      if (this.isMobileLogin) {
        this.$router.push({
          name: ROUTE_NAME.REGISTER
        });
      } else {
        this.openRegisterDialog();
      }
    },
    login() {
      if (this.$refs.loginForm.validate()) {
        let jObj = this;
        this.loginObj.domain = uiHelper.getHostname();
        this.loginObj.platform = uiHelper.getPlatform();
        this.loginObj.language = uiHelper.getLanguage();

        let existFp = typeof uiHelper.getCookie("fp_visitor") != "undefined" && uiHelper.getCookie("fp_visitor") != null && uiHelper.getCookie("fp_visitor") != "";
        console.log("FP exists:", existFp);

        if (existFp) {
          jObj.loginObj.fp = uiHelper.getCookie("fp_visitor");
          let loginObj = this.loginObj;
          this.$store.dispatch(`${MEMBER_LOGIN}`, { loginObj })
            .then(() => {
              console.log("Login dispatched with existing FP");
            })
            .catch(err => {
              console.error("Error dispatching login:", err);
            });
        } else {
          try {
            jObj.loginObj.fp = uiHelper.getFingerprints();
            if (jObj.loginObj.fp != "" && jObj.loginObj.fp != null) {
              uiHelper.setCookie("fp_visitor", jObj.loginObj.fp, 30);
              let loginObj = jObj.loginObj;
              this.$store.dispatch(`${MEMBER_LOGIN}`, { loginObj })
                .then(() => {
                  console.log("Login dispatched with new FP");
                })
                .catch(err => {
                  console.error("Error dispatching login:", err);
                });
            }
          } catch (err) {
            console.log("Error getting fingerprints:", err);
            let loginObj = this.loginObj;
            this.$store.dispatch(`${MEMBER_LOGIN}`, { loginObj })
              .then(() => {
                console.log("Login dispatched without FP");
              })
              .catch(err => {
                console.error("Error dispatching login:", err);
              });
          }
        }

        try {
          srtmCommands.push({
            event: "track.user.login",
            payload: {
              action: "complete",
              userId: this.loginObj.memberCode
            }
          });
          console.log("done sradar login");
        } catch (err) {
          console.log("Error tracking login:", err);
        }

        // let jObj = this
        // this.loginObj.domain = uiHelper.getHostname()
        // this.loginObj.platform = uiHelper.getPlatform()
        // this.loginObj.language = uiHelper.getLanguage()
        // let existFp = typeof uiHelper.getCookie('fp_visitor') != 'undefined' && uiHelper.getCookie('fp_visitor') != null && uiHelper.getCookie('fp_visitor') != ''
        // if (existFp) {
        //   jObj.loginObj.fp = uiHelper.getCookie('fp_visitor')
        //   let loginObj = this.loginObj
        //   this.$store.dispatch(`${MEMBER_LOGIN}`, {
        //     loginObj
        //   })
        // } else {
        //   try {
        //     jObj.loginObj.fp = uiHelper.getFingerprints()
        //     if (jObj.loginObj.fp != '' && jObj.loginObj.fp != null) {
        //       uiHelper.setCookie('fp_visitor', jObj.loginObj.fp, 30)
        //       let loginObj = jObj.loginObj
        //       this.$store.dispatch(`${MEMBER_LOGIN}`, {
        //         loginObj
        //       })
        //     }
        //   } catch (err) {
        //     console.log(err)
        //     let loginObj = this.loginObj
        //     this.$store.dispatch(`${MEMBER_LOGIN}`, {
        //       loginObj
        //     })
        //   }
        // }
        // try {
        //   srtmCommands.push({
        //     event: 'track.user.login',
        //     payload: {
        //       action: 'complete',
        //       userId: this.loginObj.memberCode
        //     }
        //   })
        //   console.log('done sradar login')
        // } catch (err) {
        //   console.log(err)
        // }
      }
    }
  }
};
</script>

<style lang="scss">
.login-form-bg {
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
}

.forgot-text {
  color: #0094ff !important;
  font-size: 12px !important;
  font-family: 'Roboto', sans-serif;
}

.login-header {
  background-color: none;
  font-size: 24px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif !important;
}

.login-header-desc {
  background-color: none;
  font-family: 'Roboto', sans-serif;
}

.v-application {
  .mobile-login-btn {
    background-color: #0066d1 !important;
    color: #ffffff;
  }

  .desktop-login-btn {
    background-color: #ffce01 !important;
    font-family: 'Roboto', sans-serif;
    border-radius: 8px;
  }
}

.login_panel {
  .theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
    background: #ffffff;
    color: #2699fb;
  }

  .theme--dark.v-label {
    color: #2699fb;
  }

  .theme--dark.v-input input,
  .theme--dark.v-input textarea,
  .theme--dark.v-icon {
    color: #2699fb;
  }

  .input-field .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 0;
    margin-right: 5px;
  }

  .dialog-divider {
    border-width: 0.8px !important;
    border-color: #acacac !important;
    height: 100%;
  }

  .dialog-divider-desktop {
    border-width: 0.8px !important;
    border-color: #d3d3d3 !important;
    height: 100%;
  }

  .forgot-password {
    margin-top: -15px;
  }

  .desktop-register {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
  }

  .login-tnc {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
  }

  .login-dialog-box {
    font-family: 'Roboto', sans-serif !important;
  }
}
</style>
