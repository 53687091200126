<template>
  <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.72569 0C10.2132 0 12.2269 2.01374 12.2269 4.50124C12.2269 6.96514 10.2132 8.97884 7.72569 8.97884C5.2618 8.97884 3.2481 6.9651 3.2481 4.50124C3.2481 2.0137 5.26183 0 7.72569 0ZM11.6819 8.93154L14.738 12.0113L7.72566 19L0.736938 12.0113L3.79303 8.93154C6.01993 10.9216 9.43159 10.9216 11.6819 8.93154H11.6819Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: "TierIcon"
}
</script>

<style scoped>

</style>