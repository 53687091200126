import moment from 'moment'
import { DATE } from '@/constants/constants'

export default function dateTimeFormat(value) {
  let result = ''

  if (value != undefined && value != null && value != '') {
    return moment(String(value)).format(DATE.LONG)
  }

  return result
}
