var DataType = {
  BOOLEAN: 'boolean',
  FLOAT: 'float',
  INTEGER: 'integer',
  STRING: 'string',
  ARRAY: 'array',
  MONEY: 'money'
}

export { DataType }
