import { affiliatePaymentService } from '@/services'
import { SHARED_LOADING, SHARED_UNLOADING } from '@/store/shared.module'
import format from 'string-format'
const MODULE_NAME = 'affiliatePayment/'

const A_TRANSACTION = 'getAffiliatePaymentTransactionHistory'
const A_AVAILABLE_METHOD = 'getAffiliatePaymentAvailableMethod'
const A_MERCHANT_BANK_ACCOUNT = 'getAffiliatePaymentMerchantBankAccount'
const A_MEMBER_BANK_ACCOUNT = 'getAffiliatePaymentMemberBankAccount'
const A_ONLINE_PAYMENT_SUPPORTED_BANK = 'getAffiliatePaymentOnlinePaymentSupportedBank'
const A_SUBMIT_PAYMENT_TRANSACTION = 'postAffiliatePaymentPaymentTransaction'
const A_RESET_TRANSACTION_RESPONSE_STORE = 'resetAffiliatePaymentTransactionResponseStore'
const A_BANK = 'getAffiliatePaymentBank'
const A_NEW_MEMBER_BANK_ACCOUNT = 'postAffiliatePaymentNewMemberBankAccount'
const A_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE = 'resetAffiliatePaymentNewMemberBankkAccountStore'
const A_DELETE_MEMBER_BANK_ACCOUNT = 'deleteAffiliatePaymentMemberBankAccount'
const A_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE = 'resetAffiliatePaymentDeleteMemberBankAccountStore'
const A_UPDATE_MEMBER_BANK_ACCOUNT = 'updateAffiliatePaymentMemberBankAccount'
const A_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE = 'resetAffiliatePaymentUpdateMemberBankAccountStore'
const A_RESET_HISTORY = 'resetAffiliatePaymentTransactionHistory'
const A_PROCESS_TRANSACTION = 'processAffiliatePaymentTransaction'
const A_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE = 'resetAffiliatePaymentProcessTransactionStore'
const A_CANCEL_TRANSACTION = 'cancelAffiliatePaymentTransaction'
const A_RESET_CANCEL_TRANSACTION_STORE = 'resetCancelAffiliatePaymentTransactionStore'

export const AFFILIATE_PAYMENT_TRANSACTION = MODULE_NAME + A_TRANSACTION
export const AFFILIATE_PAYMENT_AVAILABLE_METHOD = MODULE_NAME + A_AVAILABLE_METHOD
export const AFFILIATE_PAYMENT_MERCHANT_BANK_ACCOUNT = MODULE_NAME + A_MERCHANT_BANK_ACCOUNT
export const AFFILIATE_PAYMENT_MEMBER_BANK_ACCOUNT = MODULE_NAME + A_MEMBER_BANK_ACCOUNT
export const AFFILIATE_PAYMENT_ONLINE_PAYMENT_SUPPORTED_BANK = MODULE_NAME + A_ONLINE_PAYMENT_SUPPORTED_BANK
export const AFFILIATE_PAYMENT_SUBMIT_PAYMENT_TRANSACTION = MODULE_NAME + A_SUBMIT_PAYMENT_TRANSACTION
export const AFFILIATE_PAYMENT_RESET_TRANSACTION_RESPONSE_STORE = MODULE_NAME + A_RESET_TRANSACTION_RESPONSE_STORE
export const AFFILIATE_PAYMENT_BANK = MODULE_NAME + A_BANK
export const AFFILIATE_PAYMENT_NEW_MEMBER_BANK_ACCOUNT = MODULE_NAME + A_NEW_MEMBER_BANK_ACCOUNT
export const AFFILIATE_PAYMENT_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE = MODULE_NAME + A_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE
export const AFFILIATE_PAYMENT_DELETE_MEMBER_BANK_ACCOUNT = MODULE_NAME + A_DELETE_MEMBER_BANK_ACCOUNT
export const AFFILIATE_PAYMENT_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE = MODULE_NAME + A_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE
export const AFFILIATE_PAYMENT_UPDATE_MEMBER_BANK_ACCOUNT = MODULE_NAME + A_UPDATE_MEMBER_BANK_ACCOUNT
export const AFFILIATE_PAYMENT_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE = MODULE_NAME + A_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE
export const AFFILIATE_PAYMENT_RESET_HISTORY = MODULE_NAME + A_RESET_HISTORY
export const AFFILIATE_PAYMENT_PROCESS_TRANSACTION = MODULE_NAME + A_PROCESS_TRANSACTION
export const AFFILIATE_PAYMENT_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE = MODULE_NAME + A_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE
export const AFFILIATE_PAYMENT_CANCEL_TRANSACTION = MODULE_NAME + A_CANCEL_TRANSACTION
export const AFFILIATE_PAYMENT_RESET_CANCEL_TRANSACTION_STORE = MODULE_NAME + A_RESET_CANCEL_TRANSACTION_STORE

const M_TRANSACTION = 'setAffiliatePaymentTransactionHistory'
const M_AVAILABLE_METHOD = 'setAffiliatePaymentAvailableMethod'
const M_MERCHANT_BANK_ACCOUNT = 'setAffiliatePaymentMerchantBankAccount'
const M_MEMBER_BANK_ACCOOUNT = 'setAffiliatePaymentMemberBankAccount'
const M_ONLINE_PAYMENT_SUPPORTED_BANK = 'setAffiliatePaymentOnlinePaymentSupportedBank'
const M_SUBMIT_PAYMENT_TRANSACTION = 'responseAffiliatePaymentPostPaymentTransaction'
const M_RESET_TRANSACTION_RESPONSE_STORE = 'setAffiliatePaymentResetTransactionResponseStore'
const M_BANK = 'setAffiliatePaymentBank'
const M_NEW_MEMBER_BANK_ACCOUNT = 'responseAffiliatePaymentPostNewMemberBankAccount'
const M_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE = 'resetAffiliatePaymentNewMemberBankAccountStore'
const M_DELETE_MEMBER_BANK_ACCOUNT = 'responseAffiliatePaymentDeleteMemberBankAccount'
const M_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE = 'resetAffiliatePaymentDeleteMemberBankAccountStore'
const M_UPDATE_MEMBER_BANK_ACCOUNT = 'responseAffiliatePaymentUpdateMemberBankAccount'
const M_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE = 'resetAffiliatePaymentUpdateMemberBankAccountStore'
const M_RESET_HISTORY = 'resetAffiliatePaymentTransactionHistory'
const M_PROCESS_TRANSACTION = 'processAffiliatePaymentTransactionComplete'
const M_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE = 'resetAffiliatePaymentProcessTransactionStoreComplete'
const M_CANCEL_TRANSACTION = 'cancelAffiliatePaymentTransactionComplete'
const M_RESET_CANCEL_TRANSACTION_STORE = 'resetAffiliatePaymentCancelTransactionCompleteStore'

const state = {
  newMemberBankAccountResponse: {
    action: 'newMemberBankAccount',
    complete: false,
    success: false,
    code: 0
  },
  deleteMemberBankAccountResponse: {
    action: 'deleteMemberBankAccount',
    complete: false,
    success: false,
    code: 0
  },
  updateMemberBankAccountResponse: {
    action: 'updateMemberBankAccount',
    complete: false,
    success: false,
    code: 0
  },
  transactionHistory: [],
  availableMethods: [],
  merchantBankAccounts: [],
  memberBankAccounts: [],
  onlinePaymentSupportedBanks: [],
  trasnactionResponse: {},
  banks: [],
  pagination: {
    pageNumber: 0,
    pageSize: 0,
    lastPage: 0,
    tableRowsCount: 0
  },
  processTransactionResponse: {
    action: 'processTransaction',
    complete: false,
    success: false,
    code: 0,
    data: ''
  },
  cancelTransactionResponse: {
    action: 'cancelTransaction',
    complete: false,
    success: false,
    code: 0
  }
}

const getters = {}

const actions = {
  [A_TRANSACTION]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    let isPagination = obj.isPagination
    affiliatePaymentService.getTransaction(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_TRANSACTION, { result, isPagination })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_TRANSACTION, { result, isPagination })
      }
    )
  },
  [A_AVAILABLE_METHOD]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliatePaymentService.getAvailableMethod(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_AVAILABLE_METHOD, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_AVAILABLE_METHOD, { result })
      }
    )
  },
  [A_MERCHANT_BANK_ACCOUNT]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliatePaymentService.getMerchantBankAccount(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_MERCHANT_BANK_ACCOUNT, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_MERCHANT_BANK_ACCOUNT, { result })
      }
    )
  },
  [A_MEMBER_BANK_ACCOUNT]({ commit }, { obj }) {
    affiliatePaymentService.getMemberBankAccount(obj).then(
      data => {
        let result = data
        commit(M_MEMBER_BANK_ACCOOUNT, { result })
      },
      error => {
        let result = error
        commit(M_MEMBER_BANK_ACCOOUNT, { result })
      }
    )
  },
  [A_ONLINE_PAYMENT_SUPPORTED_BANK]({ commit }, { obj }) {
    affiliatePaymentService.getOnlinePaymentSupportedBank(obj).then(
      data => {
        let result = data
        commit(M_ONLINE_PAYMENT_SUPPORTED_BANK, { result })
      },
      error => {
        let result = error
        commit(M_ONLINE_PAYMENT_SUPPORTED_BANK, { result })
      }
    )
  },
  [A_SUBMIT_PAYMENT_TRANSACTION]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliatePaymentService.postTransaction(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SUBMIT_PAYMENT_TRANSACTION, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SUBMIT_PAYMENT_TRANSACTION, { result })
      }
    )
  },
  [A_RESET_TRANSACTION_RESPONSE_STORE]({ commit }) {
    commit(M_RESET_TRANSACTION_RESPONSE_STORE)
  },
  [A_BANK]({ commit }, { obj }) {
    affiliatePaymentService.getBank(obj).then(
      data => {
        let result = data
        commit(M_BANK, { result })
      },
      error => {
        let result = error
        commit(M_BANK, { result })
      }
    )
  },
  [A_NEW_MEMBER_BANK_ACCOUNT]({ dispatch, commit }, { bankAccountObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliatePaymentService.newMemberBankAccount(bankAccountObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_NEW_MEMBER_BANK_ACCOUNT, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_NEW_MEMBER_BANK_ACCOUNT, { result })
      }
    )
  },
  [A_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE]({ commit }) {
    commit(M_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE)
  },
  [A_DELETE_MEMBER_BANK_ACCOUNT]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliatePaymentService.deleteMemberBankAccount(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_DELETE_MEMBER_BANK_ACCOUNT, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_DELETE_MEMBER_BANK_ACCOUNT, { result })
      }
    )
  },
  [A_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE]({ commit }) {
    commit(M_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE)
  },
  [A_UPDATE_MEMBER_BANK_ACCOUNT]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliatePaymentService.putMemberBankAccount(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_UPDATE_MEMBER_BANK_ACCOUNT, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_UPDATE_MEMBER_BANK_ACCOUNT, { result })
      }
    )
  },
  [A_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE]({ commit }) {
    commit(M_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE)
  },

  [A_RESET_HISTORY]({ commit }) {
    commit(M_RESET_HISTORY)
  },
  [A_PROCESS_TRANSACTION]({ dispatch, commit }, { processTransactionObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliatePaymentService.processTransaction(processTransactionObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_PROCESS_TRANSACTION, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_PROCESS_TRANSACTION, { result })
      }
    )
  },
  [A_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE]({ commit }) {
    commit(M_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE)
  },
  [A_CANCEL_TRANSACTION]({ dispatch, commit }, { cancelTransactionObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    affiliatePaymentService.cancelTransaction(cancelTransactionObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CANCEL_TRANSACTION, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CANCEL_TRANSACTION, { result })
      }
    )
  },
  [A_RESET_CANCEL_TRANSACTION_STORE]({ commit }) {
    commit(M_RESET_CANCEL_TRANSACTION_STORE)
  }
}

const mutations = {
  [M_TRANSACTION](state, { result, isPagination }) {
    if (result.success) {
      let d = result.data
      state.pagination = {
        pageNumber: result.pagination.current_page,
        pageSize: Number(result.pagination.per_page),
        lastPage: result.pagination.last_page,
        tableRowsCount: result.pagination.total
      }

      let p = []

      d.forEach(x => {
        p.push({
          transactionId: x.transID,
          amount: x.amount,
          status:
            x.transStatus.toLowerCase() == 'approved'
              ? 'success'
              : x.transStatus.toLowerCase() == 'rejected'
              ? 'fail'
              : x.transStatus.toLowerCase() == 'processing' || x.transStatus.toLowerCase() == 'escalated'
              ? 'process'
              : x.transStatus.toLowerCase() == 'cancelled'
              ? 'cancelled'
              : 'pending',
          type: x.transType.toLowerCase(),
          method: x.methodName,
          methodCode: x.methodCode,
          date: x.createdAt,
          actualStatus:
            x.transStatus.toLowerCase() == 'approved'
              ? 'success'
              : x.transStatus.toLowerCase() == 'rejected'
              ? 'fail'
              : x.transStatus.toLowerCase() == 'processing' || x.transStatus.toLowerCase() == 'escalated'
              ? 'process'
              : x.transStatus.toLowerCase() == 'cancelled'
              ? 'cancelled'
              : 'pending'
        })
      })

      if (isPagination) {
        state.transactionHistory = p
      } else {
        state.transactionHistory.concat(p)
      }
    }
  },
  [M_AVAILABLE_METHOD](state, { result }) {
    let p = []
    if (result.success) {
      let d = result.data

      d.forEach(method => {
        if (method.method != null && method.method != undefined) {
          p.push({
            code: method.method.code.toLowerCase(),
            sort: method.method.sortNum,
            minAmount: method.minAmountPerTrx,
            maxAmount: method.maxAmountPerTrx,
            transactionType: method.method.transTypeName
          })
        }
      })
    }
    state.availableMethods = p
  },
  [M_MERCHANT_BANK_ACCOUNT](state, { result }) {
    let p = []
    if (result.success) {
      let d = result.data
      d.forEach(bankAccount => {
        if (bankAccount.bank != null && bankAccount.bank != undefined) {
          p.push({
            id: bankAccount.id,
            displayText: format(
              '{0} - {1}',
              bankAccount.bank.name,
              bankAccount.accountNumber.length > 6 ? bankAccount.accountNumber.substr(bankAccount.accountNumber.length - 6) : bankAccount.accountNumber
            ),
            name: bankAccount.bank.name,
            value: bankAccount.shortCode,
            accountName: bankAccount.accountName,
            accountNumber: bankAccount.accountNumber
          })
        }
      })
    }
    p.push({
      id: '0',
      displayText: 'otherBank',
      name: '',
      value: '0',
      accountName: '',
      accountNumber: ''
    })

    state.merchantBankAccounts = p
  },
  [M_MEMBER_BANK_ACCOOUNT](state, { result }) {
    let p = []
    if (result.success) {
      let d = result.data
      d.forEach(memberBankAccount => {
        p.push({
          id: memberBankAccount.id,
          displayText: format('{0}', memberBankAccount.accountName),
          value: memberBankAccount.id,
          bankName: memberBankAccount.bankName,
          accountName: memberBankAccount.accountName,
          accountNumber: memberBankAccount.accountNumber,
          bankBranch: memberBankAccount.branch,
          bankCity: memberBankAccount.city,
          bankProvince: memberBankAccount.province
        })
      })
    }
    p.push({
      id: '0',
      displayText: 'newBankAccount',
      value: '0',
      name: '',
      accountName: '',
      accountNumber: ''
    })
    state.memberBankAccounts = p
  },
  [M_ONLINE_PAYMENT_SUPPORTED_BANK](state, { result }) {
    let p = []
    if (result.success) {
      let d = result.data
      d.forEach(onlineSupportedBank => {
        p.push({
          id: onlineSupportedBank.id,
          value: onlineSupportedBank.id,
          displayText: onlineSupportedBank.name,
          code: onlineSupportedBank.id,
          bankCode: onlineSupportedBank.bankCode
        })
      })
    }
    state.onlinePaymentSupportedBanks = p
  },
  [M_SUBMIT_PAYMENT_TRANSACTION](state, { result }) {
    if (result.success) {
      let d = result.data
      state.trasnactionResponse = {
        id: d.transID,
        type: d.transType,
        methodName: d.methodName,
        status: d.transStatus,
        amount: d.amount,
        remark: d.remark,
        complete: true,
        success: result.success,
        code: result.code,
        error: result.message
      }
    } else {
      state.trasnactionResponse = {
        complete: true,
        success: result.success,
        code: result.code,
        error: result.message
      }
    }
  },
  [M_RESET_TRANSACTION_RESPONSE_STORE](state) {
    state.trasnactionResponse = {
      complete: false,
      success: false,
      code: 0,
      error: ''
    }
  },
  [M_BANK](state, { result }) {
    let p = []
    if (result.success) {
      let d = result.data
      d.forEach(bank => {
        p.push({
          id: bank.id,
          displayText: bank.name,
          value: bank.bankCode,
          code: bank.bankCode,
          name: bank.name
        })
      })
    }
    p.push({
      id: 0,
      displayText: 'other',
      value: 0,
      code: 'other',
      name: 'other'
    })

    state.banks = p
  },
  [M_NEW_MEMBER_BANK_ACCOUNT](state, { result }) {
    state.newMemberBankAccountResponse = {
      action: 'newMemberBankAccount',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE](state) {
    state.newMemberBankAccountResponse = {
      action: 'newMemberBankAccount',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_DELETE_MEMBER_BANK_ACCOUNT](state, { result }) {
    state.deleteMemberBankAccountResponse = {
      action: 'deleteMemberBankAccount',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE](state) {
    state.deleteMemberBankAccountResponse = {
      action: 'deleteMemberBankAccount',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_UPDATE_MEMBER_BANK_ACCOUNT](state, { result }) {
    state.updateMemberBankAccountResponse = {
      action: 'updateMemberBankAccount',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE](state) {
    state.updateMemberBankAccountResponse = {
      action: 'updateMemberBankAccount',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_RESET_HISTORY](state) {
    state.transactionHistory = []
  },
  [M_PROCESS_TRANSACTION](state, { result }) {
    state.processTransactionResponse = {
      action: 'processTransaction',
      complete: true,
      success: result.success,
      code: result.code,
      data: result.data
    }
  },
  [M_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE](state) {
    state.processTransactionResponse = {
      action: 'processTransaction',
      complete: false,
      success: false,
      code: 0,
      data: ''
    }
  },
  [M_CANCEL_TRANSACTION](state, { result }) {
    state.cancelTransactionResponse = {
      action: 'cancelTransaction',
      complete: true,
      success: result.success,
      code: result.code
    }
    if (result.success) {
      let d = result.data
      state.transactionHistory.find(x => x.transactionId == d.transID).status =
        d.transStatus.toLowerCase() == 'approved'
          ? 'success'
          : d.transStatus.toLowerCase() == 'rejected'
          ? 'fail'
          : d.transStatus.toLowerCase() == 'processing' || d.transStatus.toLowerCase() == 'escalated'
          ? 'process'
          : d.transStatus.toLowerCase() == 'cancelled'
          ? 'cancelled'
          : 'pending'

      state.transactionHistory.find(x => x.transactionId == d.transID).actualStatus =
        d.transStatus.toLowerCase() == 'approved'
          ? 'success'
          : d.transStatus.toLowerCase() == 'rejected'
          ? 'fail'
          : d.transStatus.toLowerCase() == 'processing' || d.transStatus.toLowerCase() == 'escalated'
          ? 'process'
          : d.transStatus.toLowerCase() == 'cancelled'
          ? 'cancelled'
          : 'pending'
    }
  },
  [M_RESET_CANCEL_TRANSACTION_STORE](state) {
    state.cancelTransactionResponse = {
      action: 'cancelTransaction',
      complete: false,
      success: false,
      code: 0
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
