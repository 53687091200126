import moment from 'moment'
import { TIME } from '@/constants/constants'

export default function shortTimeFormat(value) {
  let result = ''

  if (value != undefined && value != null && value != '') {
    return moment(String(value)).format(TIME.SHORT)
  }

  return result
}
