import {gameService} from '@/services'
import {SHARED_LOADING, SHARED_UNLOADING} from '@/store/shared.module'
import {SHARED, CACHE_KEY} from '@/constants/constants'
import {uiHelper} from '@/util'
import moment from 'moment'

//define module name
const MODULE_NAME = 'game/'
//define actions name
const A_PROVIDERS_TYPE_CONFIG = 'getGameProviderTypeConfig'
const A_TYPE_GAMES = 'getTypeGames'
const A_GAMES = 'getGames'
const A_LAUNCH_GAME = 'launchGame'
const A_RESET_LAUNCH_GAME_RESPONSE = 'resetLaunchGameResponse'
const A_CATEGORIES = 'getGameCategories'
const A_JACKPOT_LIST = 'getJackpotList'
const A_GET_TRANSFER_GAME_DETAILS = 'getGameTransferDetails'
const A_RESET_TRANSFER_GAME_DETAILS = 'resetGameTransferDetails'
const A_QUICK_REGISTER_LOCAL_GAME = 'quickRegisterLocalGame'
const A_RESET_TRANSFER_LOCAL_REGISTER = 'resetTransferLocalProvider'
const A_DEEP_LINK_KISSKAYA = 'getDeepLinkKissKaya'
const A_RESET_DEEP_LINK_KISSKAYA = 'resetDeepLinkKissKaya'
const A_MATCHES_HIGHLIGHTS = 'getMatchesHighlights'
const A_HOMEPAGE_HOT = 'getHomepageHotGames'

//define dispatch action
export const GAME_PROVIDERS_TYPE_CONFIG = MODULE_NAME + A_PROVIDERS_TYPE_CONFIG
export const GAME_TYPE_GAMES = MODULE_NAME + A_TYPE_GAMES
export const GAME_GAMES = MODULE_NAME + A_GAMES
export const GAME_LAUNCH_GAME = MODULE_NAME + A_LAUNCH_GAME
export const GAME_RESET_LAUNCH_GAME_RESPONSE = MODULE_NAME + A_RESET_LAUNCH_GAME_RESPONSE
export const GAME_CATEGORIES = MODULE_NAME + A_CATEGORIES
export const GAME_JACKPOT_LIST = MODULE_NAME + A_JACKPOT_LIST
export const GAME_TRANSFER_DETAILS = MODULE_NAME + A_GET_TRANSFER_GAME_DETAILS
export const GAME_RESET_TRANSFER_DETAILS = MODULE_NAME + A_RESET_TRANSFER_GAME_DETAILS
export const GAME_REGISTER_LOCAL_PROVIDER = MODULE_NAME + A_QUICK_REGISTER_LOCAL_GAME
export const GAME_RESET_REGISTER_LOCAL_PROVIDER = MODULE_NAME + A_RESET_TRANSFER_LOCAL_REGISTER
export const GAME_KISSKAYA_DEEPLINK = MODULE_NAME + A_DEEP_LINK_KISSKAYA
export const GAME_KISSKAYA_RESET_DEEPLINK = MODULE_NAME + A_RESET_DEEP_LINK_KISSKAYA
export const GAME_MATCHES_HIGHLIGHTS = MODULE_NAME + A_MATCHES_HIGHLIGHTS
export const GAME_HOMEPAGE_HOT = MODULE_NAME + A_HOMEPAGE_HOT

//define mutations name
const M_PROVIDERS_TYPE_CONFIG = 'setGameProviderTypeConfig'
const M_TYPE_GAMES = 'setTypeGames'
const M_LAUNCH_GAME = 'setLaunchGame'
const M_RESET_LAUNCH_GAME_RESPONSE = 'resetLaunchGameResponse'
const M_CATEGORIES = 'setGameCategories'
const M_FAILURE = 'setFailure'
const M_JACKPOT_LIST = 'setJackpotList'
const M_SET_GAME_TRANSFER_DETAILS = 'setGameTransferDetails'
const M_RESET_GAME_TRANSFER_DETAILS = 'resetGameTransferDetails'
const M_SET_LOCAL_PROVIDER_REGISTER = 'setLocalProviderRegister'
const M_RESET_REGISTER_LOCAL_DETAILS = 'resetLocalProviderRegister'
const M_SET_DEEP_LINK_KISSKAYA = 'setDeepLinkKissKaya'
const M_RESET_DEEP_LINK_KISSKAYA = 'resetDeepLinkKissKaya'
const M_MACTHES_HIGHLIGHTS = 'setMatchesHighlights'
const M_HOMEPAGE_HOT = 'setHomepageHotGames'

//init app state
const state = {
    deeplink: {
        complete: false,
        success: false,
        code: 0,
        launchUrl: ''
    },
    registeredPlayerDetails: {
        code: 0,
        username: '',
        password: '',
        success: false,
        complete: false
    },
    playerDetails: {
        code: 0,
        username: '',
        password: '',
        success: false,
        complete: false
    },
    ProviderTypes: [],
    SportsbookGames: [],
    CasinoGames: [],
    FishingGames: [],
    LotteryGames: [],
    SlotGames: [],
    CricketGames: [],
    Games: [],
    SpecialGames: [],
    TableGames: [],
    CrashGames: [],
    launchGameResponse: {
        action: 'launchGame',
        complete: false,
        success: false,
        code: 0,
        gameUrl: ''
    },
    categories: [],
    jackpots: [],
    errorMsg: {
        code: 0
    },
    matchesHighlights: [],
    HomepageHotGames:[]
}

//to retrieve data from store state (called by component)
const getters = {}

//to perform some actions related with updating state (called by component)
const actions = {
    async [A_HOMEPAGE_HOT]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        await gameService.getHomepageHot(obj).then(
            data => {
                let result = data
                commit(M_HOMEPAGE_HOT, {result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            },
            error => {
                let result = error
                commit(M_HOMEPAGE_HOT, {result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            }
        )
    },
    async [A_TYPE_GAMES]({dispatch, commit}, {Obj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        await gameService.getGames(Obj).then(
            data => {
                let result = data
                let type = Obj.type
                commit(M_TYPE_GAMES, {result, type})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            },
            error => {
                let result = error
                let type = Obj.type
                commit(M_TYPE_GAMES, {result, type})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            }
        )
    },
    [A_DEEP_LINK_KISSKAYA]({dispatch, commit}, {obj}) {
        let provider = obj.provider
        gameService.kisskayadeeplink(obj).then(
            data => {
                let result = data
                commit(M_SET_DEEP_LINK_KISSKAYA, {provider, result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            },
            error => {
                let result = error
                commit(M_SET_DEEP_LINK_KISSKAYA, {provider, result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            }
        )
    },
    [A_QUICK_REGISTER_LOCAL_GAME]({dispatch, commit}, {obj}) {
        let provider = obj.provider
        gameService.quickRegister(obj).then(
            data => {
                let result = data
                commit(M_SET_LOCAL_PROVIDER_REGISTER, {provider, result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            },
            error => {
                let result = error
                commit(M_SET_LOCAL_PROVIDER_REGISTER, {provider, result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            }
        )
    },
    [A_RESET_TRANSFER_LOCAL_REGISTER]({commit}) {
        commit(M_RESET_REGISTER_LOCAL_DETAILS)
    },
    [A_GET_TRANSFER_GAME_DETAILS]({dispatch, commit}, {obj}) {
        let provider = obj.provider
        gameService.getPlayerGameDetails(obj).then(
            data => {
                let result = data
                commit(M_SET_GAME_TRANSFER_DETAILS, {provider, result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            },
            error => {
                let result = error
                commit(M_SET_GAME_TRANSFER_DETAILS, {provider, result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            }
        )
    },
    [A_PROVIDERS_TYPE_CONFIG]({commit}, {providerTypeConfigObj}) {
        gameService.getProviderTypeConfig(providerTypeConfigObj).then(
            data => {
                let result = data
                commit(M_PROVIDERS_TYPE_CONFIG, {result})
            },
            error => {
                let result = error
                commit(M_PROVIDERS_TYPE_CONFIG, {result})
            }
        )
    },
    async [A_LAUNCH_GAME]({dispatch, commit}, {gameObj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        await gameService.launchGame(gameObj).then(
            data => {
                let result = data
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_LAUNCH_GAME, {result})
            },
            error => {
                let result = error
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_LAUNCH_GAME, {result})
            }
        )
    },
    [A_RESET_TRANSFER_GAME_DETAILS]({commit}) {
        commit(M_RESET_GAME_TRANSFER_DETAILS)
    },
    [A_RESET_LAUNCH_GAME_RESPONSE]({commit}) {
        commit(M_RESET_LAUNCH_GAME_RESPONSE)
    },
    [A_CATEGORIES]({commit}) {
        gameService.gameCategories().then(
            data => {
                let result = data
                commit(M_CATEGORIES, {result})
            },
            error => {
                let result = error
                commit(M_CATEGORIES, {result})
            }
        )
    },

    [A_JACKPOT_LIST]({commit}) {
        gameService.getJackpot().then(
            data => {
                let result = data
                commit(M_JACKPOT_LIST, {result})
            },
            error => {
                let result = error
                commit(M_JACKPOT_LIST, {result})
            }
        )
    },

    [A_MATCHES_HIGHLIGHTS]({commit}) {
        gameService.matchesHighlights().then(
            data => {
                let result = data
                commit(M_MACTHES_HIGHLIGHTS, {result})
            },
            error => {
                let result = error
                commit(M_MACTHES_HIGHLIGHTS, {result})
            }
        )
    }
}

//to update state values (called by actions)
const mutations = {
    [M_HOMEPAGE_HOT](state,{result}){
        let p = []

        if(result.success){
            let data = result.data

            data.forEach(game=>{
                let gameLocale = []

                game.localization.forEach(language => {
                    for (let i = 0; i < Object.keys(language).length; i++) {
                        gameLocale.push({
                            language: Object.keys(language)[i],
                            name: Object.values(language)[i]
                        })
                    }
                })
                p.push({
                    provider: game.provider.toLowerCase(),
                    code: game.game_code,
                    id: game.game_id,
                    isDemoAvailable: game.is_demo,
                    locale: gameLocale,
                    jackpootGroup: game.jackpot_group,
                    engine: game.game_engine,
                    category: game.categories,
                    type: game.game_type.toLowerCase(),
                    imageCdn: game.image_cdn
                })
            })

            uiHelper.setLocalStorage(`${CACHE_KEY.HOMEPAGE_HOTGAMELIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`, p, 60)
            state.HomepageHotGames = p
        }
    },
    [M_TYPE_GAMES](state, {result, type}) {
        let p = []

        if (result.success) {
            let d = result.data

            d.forEach(game => {
                let gameLocale = []

                game.localization.forEach(language => {
                    for (let i = 0; i < Object.keys(language).length; i++) {
                        gameLocale.push({
                            language: Object.keys(language)[i],
                            name: Object.values(language)[i]
                        })
                    }
                })
                p.push({
                    provider: game.provider.toLowerCase(),
                    code: game.game_code,
                    id: game.game_id,
                    operator_game_id: game.id ? game.id : '',
                    isDemoAvailable: game.is_demo,
                    locale: gameLocale,
                    jackpootGroup: game.jackpot_group,
                    engine: game.game_engine,
                    category: game.categories,
                    type: game.game_type.toLowerCase(),
                    imageCdn: game.image_cdn
                })
            })

            if (type == 'sb') {
                uiHelper.setLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_${type}`, p, 60)
                state.SportsbookGames = p
            } else if (type == 'ld') {
                uiHelper.setLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_${type}`, p, 60)
                state.CasinoGames = p
            } else if (type == 'fishing') {
                uiHelper.setLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_${type}`, p, 60)
                state.FishingGames = p
            } else if (type == 'lottery') {
                uiHelper.setLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_${type}`, p, 60)
                state.LotteryGames = p
            } else if (type == 'rng') {
                uiHelper.setLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_${type}`, p, 60)
                state.SlotGames = p
            } else if (type == 'special') {
                uiHelper.setLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_${type}`, p, 60)
                state.SpecialGames = p
            } else if (type == 'table') {
                uiHelper.setLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_${type}`, p, 60)
                state.TableGames = p
            } else if (type == 'cricket') {
                uiHelper.setLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_${type}`, p, 60)
                state.CricketGames = p
            } else if (type == 'crash') {
                uiHelper.setLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_${type}`, p, 60)
                state.CrashGames = p
            }
        }
    },
    [M_RESET_DEEP_LINK_KISSKAYA](state) {
        state.deeplink = {
            complete: false,
            success: false,
            code: 0,
            launchUrl: ''
        }
    },
    [M_SET_DEEP_LINK_KISSKAYA](state, {result}) {
        let d = result.data
        if (result.success) {
            if (d != null && d.success == true) {
                state.deeplink = {
                    complete: true,
                    success: d.success,
                    code: d.code,
                    launchUrl: d.data
                }
            } else {
                state.deeplink = {
                    complete: true,
                    success: d.success,
                    code: d.code,
                    launchUrl: ''
                }
            }
        } else {
            state.deeplink = {
                complete: true,
                success: d.success,
                code: d.code,
                launchUrl: ''
            }
        }
    },
    [M_SET_LOCAL_PROVIDER_REGISTER](state, {provider, result}) {
        let d = result.data
        if (result.success) {
            if (d != null && d.data != null) {
                state.registeredPlayerDetails = {
                    success: result.success,
                    code: result.code,
                    complete: true
                }
                if (provider.toLowerCase() == 'mega888') {
                    state.registeredPlayerDetails.username = d.data.login_id
                    state.registeredPlayerDetails.password = d.data.password
                } else if (provider.toLowerCase() == 'xe88') {
                    state.registeredPlayerDetails.username = d.data.playerid
                    state.registeredPlayerDetails.password = d.data.playerpassword
                } else {
                    state.registeredPlayerDetails.username = d.data.provider_membercode
                    state.registeredPlayerDetails.password = d.data.password
                }
            } else {
                state.registeredPlayerDetails = {
                    success: false,
                    code: result.code,
                    complete: true
                }
            }
        } else {
            state.registeredPlayerDetails = {
                success: false,
                code: result.code,
                complete: true
            }
        }
    },
    [M_SET_GAME_TRANSFER_DETAILS](state, {provider, result}) {
        let d = result.data
        if (result.success) {
            if (d != null) {
                state.playerDetails = {
                    success: result.success,
                    code: result.code,
                    complete: true
                }
                if (provider.toLowerCase() == 'mega888') {
                    state.playerDetails.username = d.login_id
                    state.playerDetails.password = d.password
                } else if (provider.toLowerCase() == 'xe88') {
                    state.playerDetails.username = d.playerid
                    state.playerDetails.password = d.playerpassword
                } else {
                    state.playerDetails.username = d.provider_membercode
                    state.playerDetails.password = d.password
                }
            } else {
                state.playerDetails = {
                    success: false,
                    code: result.code,
                    complete: true
                }
            }
        } else {
            state.playerDetails = {
                success: false,
                code: result.code,
                complete: true
            }
        }
    },
    [M_PROVIDERS_TYPE_CONFIG](state, {result}) {
        let p = []
        let providerName = []
        if (result.success) {
            let d = result.data

            d.forEach(gameType => {
                let providerUnderGameType = []
                gameType.providers.forEach(provider => {
                    providerUnderGameType.push({
                        providerCode: provider.provider.toLowerCase(),
                        isMaintenance: provider.is_game_maintenance,
                        game: provider.game,
                        sorting: provider.sorting
                    })
                    if (providerName.indexOf(provider.provider.toLowerCase()) === -1) {
                        providerName.push(provider.provider.toLowerCase())
                    }
                })

                p.push({
                    id: gameType.id,
                    type: gameType.game_type.toLowerCase(),
                    providers: providerUnderGameType
                })
            })

            let sorting = SHARED.MENU_GAME_POSITION

            // p.sort(function (a, b) {
            //     if (sorting.indexOf(a.type) > sorting.indexOf(b.type)) {
            //         return 1
            //     } else {
            //         if (b.type == 'rng') {
            //             b.providers.sort(function (c, d) {
            //               return c.sorting - d.sorting;
            //             })
            //         }
            //         return -1
            //     }
            // })
            uiHelper.setLocalStorage(`${CACHE_KEY.GAME_LIST_TYPE_NAME}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`, providerName, 60)
            uiHelper.setLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`, p, 60)
        }
        state.ProviderTypes = p
    },
    [M_LAUNCH_GAME](state, {result}) {
        if (result.success) {
            let d = result.data
            state.launchGameResponse = {
                action: 'launchGame',
                complete: true,
                success: result.success,
                code: result.code,
                gameUrl: d.game_url
            }
        } else {
            state.launchGameResponse = {
                action: 'launchGame',
                complete: true,
                success: result.success,
                code: result.code
            }
        }
    },
    [M_RESET_REGISTER_LOCAL_DETAILS](state) {
        state.registeredPlayerDetails = {
            code: 0,
            username: '',
            password: '',
            success: false,
            complete: false
        }
    },
    [M_RESET_GAME_TRANSFER_DETAILS](state) {
        state.playerDetails = {
            code: 0,
            username: '',
            password: '',
            success: false,
            complete: false
        }
    },
    [M_RESET_LAUNCH_GAME_RESPONSE](state) {
        state.launchGameResponse = {
            action: 'launchGame',
            complete: false,
            success: false,
            code: 0,
            gameUrl: ''
        }
    },
    [M_CATEGORIES](state, {result}) {
        let p = []
        if (result.success) {
            p = result.data
        }
        state.categories = p
    },
    [M_FAILURE](state, error) {
        state.errorMsg.code = error.code
        console.log(error)
    },
    [M_JACKPOT_LIST](state, {result}) {
        let p = []

        let d = result

        d.forEach(jackpot => {
            jackpot.jackpots.forEach(jackpotData => {
                p.push({
                    provider: jackpot.provider,
                    currency: jackpot.currency,
                    jackpotCode: jackpotData.code,
                    jackpotAmount: jackpotData.amount
                })
            })
        })

        state.jackpots = p
    },
    [M_MACTHES_HIGHLIGHTS](state, {result}) {
        //let p = []
        /*if(result){
                let sortedList = _.orderBy(result, [function(object) {
                    return moment(object.match_date, "DD/MM/YYYY hh:mm:ss").toDate();
                }],["desc"])
                p = _.groupBy(sortedList, 'id')
            }*/
        state.matchesHighlights = result
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
