import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {SHARED} from '@/constants/constants'
import en from '@/locales/en.json'
import zh from '@/locales/zh.json'
import ms from '@/locales/ms.json'
import bd from '@/locales/bd.json'
import ta from '@/locales/ta.json'
import enin from '@/locales/en-in.json'
import hi from '@/locales/hi.json'
import ne from '@/locales/ne.json'
import enne from '@/locales/en-ne.json'

//import th from '@/locales/th.json'
Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'ms',
    fallbackLocale: SHARED.FALLBACK_LANGUAGE,
    messages: {ta, bd, en, ne ,enin,enne,hi},
})

export default i18n
