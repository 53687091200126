import { rewardService } from '@/services'
import { SHARED_LOADING, SHARED_UNLOADING } from '@/store/shared.module'
import { SESSION, SHARED } from '@/constants/constants'
import router from '@/router'
import { ROUTE_NAME } from '@/constants/route.constants'
import Vue from 'vue'

//define module name
const MODULE_NAME = 'reward/'

const A_GET_REWARD_WALLET_LOG = 'getRewardWallet'
const A_GET_CHECK_IN_DAY = 'getCheckInDay'
const A_POST_CHECK_IN_DAY = 'postCheckInDay'
const A_ACCEPT_CHALLENGE = 'acceptChallenge'
const A_GET_MEMBER_CHALLENGE = 'getChallengeByMember'
const A_GET_DAILY_CHECK_IN_PRIZE_LIST = 'getDailyCheckInPrizeList'
const A_RESET_POST_CHECK_IN_DAY = 'resetPostCheckInDay'
const A_CONFIGURATION_STATUS = 'getConfigurationStatus'
const A_GET_REWARD_ITEM = 'getRewardItem'
const A_CLAIM_REWARD_ITEM = 'claimRewardItem'
const A_VERIFY_POINTS = 'verifyPoints'
const A_RESET_VERIFY_POINTS = 'resetVerifyPoints'
const A_REDEEM_HISTORY = 'getRedeemHistory'
const A_RESET_CLAIM_REWARD_ITEM = 'resetClaimRewardItem'

export const REWARD_GET_ITEM = MODULE_NAME + A_GET_REWARD_ITEM
export const MEMBER_REDEEM_HISTORY = MODULE_NAME + A_REDEEM_HISTORY
export const MEMBER_VERIFY_POINTS = MODULE_NAME + A_VERIFY_POINTS
export const MEMBER_RESET_VERIFY_POINTS = MODULE_NAME + A_RESET_VERIFY_POINTS
export const MEMBER_CLAIM_REWARD = MODULE_NAME + A_CLAIM_REWARD_ITEM
export const MEMBER_RESET_CLAIM_REWARD_ITEM = MODULE_NAME + A_RESET_CLAIM_REWARD_ITEM
export const REWARD_GET_WALLET_LOG = MODULE_NAME + A_GET_REWARD_WALLET_LOG
export const REWARD_GET_CHECK_IN_DAY = MODULE_NAME + A_GET_CHECK_IN_DAY
export const REWARD_POST_CHECK_IN_DAY = MODULE_NAME + A_POST_CHECK_IN_DAY
export const REWARD_ACCEPT_CHALLENGE = MODULE_NAME + A_ACCEPT_CHALLENGE
export const REWARD_GET_MEMBER_CHALLENGE = MODULE_NAME + A_GET_MEMBER_CHALLENGE
export const REWARD_GET_DAILY_CHECK_IN_PRIZE_LIST = MODULE_NAME + A_GET_DAILY_CHECK_IN_PRIZE_LIST
export const REWARD_RESET_POST_CHECK_IN_DAY = MODULE_NAME + A_RESET_POST_CHECK_IN_DAY
export const REWARD_CONFIGURATION_STATUS = MODULE_NAME + A_CONFIGURATION_STATUS

const M_SET_REWARD_WALLET_LOG = 'setRewardWallet'
const M_SET_CHECK_IN_DAY = 'setCheckInDay'
const M_POST_CHECK_IN_DAY = 'postCheckInDay'
const M_SET_ACCEPT_CHAllENGE = 'acceptChallengeResponse'
const M_SET_MEMBER_CHAllENGE = 'setMemberChallenge'
const M_SET_DAILY_CHECK_IN_PRIZE_LIST = 'setDailyCheckInPrizeList'
const M_RESET_POST_CHECK_IN_DAY = 'resetPostCheckInDayResponse'
const M_CONFIGURATION_STATUS = 'setConfigurationStatus'
const M_SET_REWARD_ITEM = 'setRewardItem'
const M_REDEEM_HISTORY = 'redeemHistory'
const M_RESET_VERIFY_POINTS = 'resetVerifyPoints'
const M_VERIFY_POINTS = 'verifyPoints'
const M_CLAIM_REWARD_ITEM = 'claimRewardItem'
const M_RESET_CLAIM_REWARD_ITEM = 'resetClaimRewardItem'

const state = {
  redeemProduct: {
    complete: false,
    success: false,
    message: '',
    code: 0
  },
  redeemHistory: {
    data: [],
    pagination: {}
  },
  verifyPoints: {
    complete: false,
    success: false,
    code: 0
  },
  rewardItem: {
    pagination: {},
    data: []
  },
  rewardWalletLog: {
    pagination: {},
    data: []
  },
  checkInDay: [],
  checkInDayResponse: {
    complete: false,
    success: false,
    data: '',
    code: 0
  },
  acceptChallengeResponse: {
    success: false,
    complete: false,
    code: 0
  },
  memberChallenge: {},
  dailyCheckInPrizeList: {},
  configurationStatus: {
    spin_wheel: {
      key: '',
      enabled: ''
    },
    daily_check_in: {
      key: '',
      enabled: ''
    },
    coin_grab: {
      key: '',
      enabled: ''
    },
    card_mania: {
      key: '',
      enabled: ''
    }
  }
}

//to retrieve data from store state (called by component)
const getters = {}

//to perform some actions related with updating state (called by component)
const actions = {
  [A_GET_REWARD_WALLET_LOG]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    rewardService.getRewardWalletLog(obj).then(
      data => {
        let result = data
        commit(M_SET_REWARD_WALLET_LOG, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      },
      error => {
        let result = error
        commit(M_SET_REWARD_WALLET_LOG, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      }
    )
  },
  [A_GET_CHECK_IN_DAY]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    rewardService.getCheckInDay().then(
      data => {
        let result = data
        commit(M_SET_CHECK_IN_DAY, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      },
      error => {
        let result = error
        commit(M_SET_CHECK_IN_DAY, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      }
    )
  },
  [A_POST_CHECK_IN_DAY]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    rewardService.postCheckInDay(obj).then(
      data => {
        let result = data
        commit(M_POST_CHECK_IN_DAY, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      },
      error => {
        let result = error
        commit(M_POST_CHECK_IN_DAY, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      }
    )
  },
  [A_GET_MEMBER_CHALLENGE]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    rewardService.getChallengeByMember().then(
      data => {
        let result = data
        commit(M_SET_MEMBER_CHAllENGE, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      },
      error => {
        let result = error
        commit(M_SET_MEMBER_CHAllENGE, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      }
    )
  },
  [A_ACCEPT_CHALLENGE]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    rewardService.acceptMemberChallenge(obj).then(
      data => {
        let result = data
        commit(M_SET_ACCEPT_CHAllENGE, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      },
      error => {
        let result = error
        commit(M_SET_ACCEPT_CHAllENGE, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      }
    )
  },
  [A_GET_DAILY_CHECK_IN_PRIZE_LIST]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    rewardService.getDailyCheckInRewardPrizeList(obj).then(
      data => {
        let result = data
        commit(M_SET_DAILY_CHECK_IN_PRIZE_LIST, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      },
      error => {
        let result = error
        commit(M_SET_DAILY_CHECK_IN_PRIZE_LIST, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      }
    )
  },
  [A_RESET_POST_CHECK_IN_DAY]({ commit }) {
    commit(M_RESET_POST_CHECK_IN_DAY)
  },
  [A_CONFIGURATION_STATUS]({ dispatch, commit }, { filter }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    rewardService.getRewardConfiguration(filter).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CONFIGURATION_STATUS, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CONFIGURATION_STATUS, { result })
      }
    )
  },
  [A_GET_REWARD_ITEM]({ dispatch, commit }, { filter, categoryId }) {
    dispatch(SHARED_LOADING, {}, { root: true })
    rewardService
      .getRewardItem(filter, categoryId)
      .then(data => {
        let result = data
        commit(M_SET_REWARD_ITEM, { result })
        dispatch(SHARED_UNLOADING, {}, { root: true })
      })
      .catch(error => {
        let result = error
        commit(M_SET_REWARD_ITEM, { result })
        dispatch(SHARED_UNLOADING, {}, { root: true })
      })
  },
  [A_CLAIM_REWARD_ITEM]({ dispatch, commit }, { filter }) {
    return new Promise((resolve, reject) => {
      dispatch(`${SHARED_LOADING}`, {}, { root: true })

      rewardService
        .claimRewardItem(filter)
        .then(data => {
          let result = data
          commit(M_CLAIM_REWARD_ITEM, { result })
          dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
          resolve(data)
        })
        .catch(error => {
          let result = error
          commit(M_CLAIM_REWARD_ITEM, { result })
          dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
          reject(error)
        })
    })
  },
  [A_VERIFY_POINTS]({ dispatch, commit }, { filter }) {
    return new Promise((resolve, reject) => {
      dispatch(`${SHARED_LOADING}`, {}, { root: true })
      rewardService
        .verifyClaim(filter)
        .then(data => {
          dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
          commit(M_VERIFY_POINTS, { result: data })
          resolve(data)
        })
        .catch(error => {
          dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
          commit(M_VERIFY_POINTS, { result: error })
          reject(error)
        })
    })
  },
  [A_RESET_VERIFY_POINTS]({ commit }) {
    commit(M_RESET_VERIFY_POINTS)
  },
  [A_RESET_CLAIM_REWARD_ITEM]({ commit }) {
    commit(M_RESET_CLAIM_REWARD_ITEM)
  },
  [A_REDEEM_HISTORY]({ dispatch, commit }, { redeemHistoryObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    rewardService.getRedeemHistory(redeemHistoryObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_REDEEM_HISTORY, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_REDEEM_HISTORY, { result })
      }
    )
  }
}

//to update state values (called by actions)
const mutations = {
  [M_CLAIM_REWARD_ITEM](state, { result }) {
    state.redeemProduct.complete = true
    state.redeemProduct.success = result.success
    state.redeemProduct.message = result.message
    state.redeemProduct.code = result.code
  },
  [M_REDEEM_HISTORY](state, { result }) {
    if (result.success) {
      state.redeemHistory.data = result.data
      state.redeemHistory.pagination = result.pagination
    } else {
      state.redeemHistory.data = []
      state.redeemHistory.pagination = {}
    }
  },
  [M_VERIFY_POINTS](state, { result }) {
    Vue.set(state.verifyPoints, 'complete', true)
    Vue.set(state.verifyPoints, 'success', result.success)
    Vue.set(state.verifyPoints, 'code', result.code)
  },

  [M_RESET_VERIFY_POINTS](state) {
    Vue.set(state.verifyPoints, 'complete', false)
    Vue.set(state.verifyPoints, 'success', false)
    Vue.set(state.verifyPoints, 'code', 0)
  },
  [M_RESET_CLAIM_REWARD_ITEM](state) {
    state.redeemProduct.complete = false
    state.redeemProduct.success = false
    state.redeemProduct.message = ''
    state.redeemProduct.code = 0
  },
  [M_SET_REWARD_ITEM](state, { result }) {
    if (result.success) {
      state.rewardItem.data = result.data
      state.rewardItem.pagination = result.pagination
    } else {
      state.rewardItem = {
        data: [],
        pagination: {}
      }
      // state.rewardItem.data = [];
      // state.rewardItem.pagination = {};
    }
  },
  [M_CONFIGURATION_STATUS](state, { result }) {
    if (result.success) {
      state.configurationStatus = result.data
    }
  },
  [M_SET_REWARD_WALLET_LOG](state, { result }) {
    if (result.success) {
      state.rewardWalletLog.data = result.data
      state.rewardWalletLog.pagination = result.pagination
    }
  },
  [M_SET_CHECK_IN_DAY](state, { result }) {
    if (result.success) {
      state.checkInDay = result.data
    }
  },
  [M_POST_CHECK_IN_DAY](state, { result }) {
    state.checkInDayResponse.complete = true
    state.checkInDayResponse.success = result.success
    state.checkInDayResponse.data = result.data
    state.checkInDayResponse.code = result.code
  },
  [M_RESET_POST_CHECK_IN_DAY](state) {
    state.checkInDayResponse.complete = false
    state.checkInDayResponse.success = false
    state.checkInDayResponse.data = false
    state.checkInDayResponse.code = false
  },
  [M_SET_ACCEPT_CHAllENGE](state, { result }) {
    state.acceptChallengeResponse.success = result.success
    state.acceptChallengeResponse.complete = result.complete
    state.acceptChallengeResponse.code = result.code
  },
  [M_SET_MEMBER_CHAllENGE](state, { result }) {
    if (result.success) {
      state.memberChallenge = result.data
    }
  },
  [M_SET_DAILY_CHECK_IN_PRIZE_LIST](state, { result }) {
    if (result.success) {
      state.dailyCheckInPrizeList = result.data
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
