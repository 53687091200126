import { userService } from '@/services'

//define module name
const MODULE_NAME = 'user/'

//define actions name
const A_CHANGE_PASSWORD = 'changePassword'

//define dispatch action
export const USER_CHANGE_PASSWORD = MODULE_NAME + A_CHANGE_PASSWORD

//define mutations name
const M_RESULT = 'setResult'

//init app state
const state = {
  msg: ''
}

//to retrieve data from store state (called by component)
const getters = {}

//to perform some actions related with updating state (called by component)
const actions = {
  [A_CHANGE_PASSWORD]({ commit }, { currentPwd, newPwd }) {
    userService.changePassword(currentPwd, newPwd).then(
      data => {
        //'Change Password Success'
        commit(M_RESULT, data)
      },
      error => {
        //'Change Password Failed'
        let data = error
        commit(M_RESULT, data)
      }
    )
  }
}

//to update state values (called by actions)
const mutations = {
  [M_RESULT](state, data) {
    state.msg = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
