<template>
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6359 11.2678H11.9539C10.9124 11.2678 10.0311 10.3866 10.0311 9.34543V6.4011C10.0311 6.00053 10.2715 5.7605 10.6717 5.7605C11.0723 5.7605 11.3127 6.00052 11.3127 6.4011V9.34543C11.3127 9.746 11.5527 9.98603 11.9533 9.98603H13.6357C14.0362 9.98603 14.2766 10.2264 14.2766 10.6269C14.2766 11.0275 13.8763 11.2679 13.636 11.2679L13.6359 11.2678Z" fill="black"/>
    <path d="M11.1626 17.6747C10.2177 17.6747 9.28709 17.526 8.39675 17.2332C7.98935 17.099 7.7683 16.6602 7.90217 16.2533C8.03605 15.8462 8.47485 15.6242 8.8821 15.7587C9.61551 15.9999 10.3828 16.1223 11.1622 16.1223C15.1792 16.1223 18.447 12.8544 18.447 8.83743C18.4472 4.82045 15.1795 1.55261 11.1626 1.55261C7.14558 1.55261 3.87774 4.82049 3.87774 8.83743C3.87774 9.26623 3.53022 9.61374 3.10143 9.61374C2.67264 9.61374 2.32513 9.26622 2.32513 8.83743C2.32513 3.96458 6.28971 0 11.1626 0C16.0354 0 20 3.96458 20 8.83743C20 13.7103 16.0354 17.6749 11.1626 17.6749L11.1626 17.6747Z" fill="black"/>
    <path d="M3.08909 9.78857C2.47045 9.78857 1.99512 9.39603 1.77528 9.02969L0.109251 6.2291C-0.109992 5.86051 0.0111539 5.3841 0.37944 5.16503C0.748335 4.94608 1.22445 5.06693 1.44352 5.43521L3.10694 8.23111C3.10573 8.22807 3.10725 8.22898 3.10937 8.2302C3.11968 8.2261 3.13302 8.21989 3.15031 8.21033L6.03656 6.47549C6.40394 6.25473 6.88095 6.3736 7.102 6.74098C7.32276 7.10866 7.20389 7.58536 6.83651 7.80642L3.9272 9.5543C3.62835 9.72063 3.34649 9.78856 3.08903 9.78856L3.08909 9.78857Z" fill="black"/>
  </svg>

</template>

<script>
export default {
  name: "HistoryIcon"
}
</script>

<style scoped>

</style>