import {apiHelper} from '@/util'
import {SHARED} from '@/constants/constants'


export const gameService = {
    getGames,
    getProviderTypeConfig,
    launchGame,
    gameCategories,
    getJackpot,
    getPlayerGameDetails,
    quickRegister,
    kisskayadeeplink,
    matchesHighlights,
    getHomepageHot
}

//API URLs
const GAME = {
    GAME: 'game',
    TYPES: 'types',
    CATEGORIES: 'categories',
    PLAYER_DETAILS: 'playerdetails',
    MEDIA_SERVER_URL: SHARED.MEDIA_SERVER_URL,
    SERVER_NAME: SHARED.SERVER_NAME,
    JACKPOT_URL: 'json/BABU88/grand_jackpot.json',
    QUICK_REGISTER: 'quickregister',
    DEEPLINK:'kisskaya/deeplink',
    HOMEPAGE_HOT:'homepagehot'
}

function kisskayadeeplink(data) {
    const requestBody = {
        provider: data.provider
    }
    return apiHelper().post(`/${GAME.GAME}/${GAME.DEEPLINK}`, requestBody)
}

function quickRegister(data) {
    const requestBody = {
        provider: data.provider
    }
    return apiHelper().post(`/${GAME.GAME}/${GAME.QUICK_REGISTER}`, requestBody)
}


function getPlayerGameDetails(data) {
    let requestBody = {
        params: {
            provider: data.provider
        }
    }
    return apiHelper().get(`/${GAME.GAME}/${GAME.PLAYER_DETAILS}`, requestBody)
}

function getGames(data) {
    let requestBody = {
        params: {
            currency: data.currency,
            type: data.type,
            size: data.size,
            page: data.page,
            platform: data.platform,
            category: data.category,
            provider: data.provider,
            mode: data.mode
        }
    }
    return apiHelper().get(`/${GAME.GAME}`, requestBody)
}

function getHomepageHot(data) {
    let requestBody = {
        params: {
            currency: data.currency,
            size: data.size,
            page: data.page,
            platform: data.platform,
            mode: data.mode
        }
    }
    return apiHelper().get(`/${GAME.GAME}/${GAME.HOMEPAGE_HOT}`, requestBody)
}

function getProviderTypeConfig(obj) {
    const requestOptions = {
        params: {
            currency: obj.currency,
            platform: obj.platform
        }
    }
    return apiHelper().get(`/${GAME.GAME}/${GAME.TYPES}`, requestOptions)
}

function launchGame(gameObj) {
    const requestBody = {
        provider: gameObj.provider,
        game_id: gameObj.gameId,
        game_code: gameObj.gameCode,
        is_demo: gameObj.isDemo,
        platform: gameObj.platform,
        language: gameObj.language,
        website: gameObj.webSite,
        currency: gameObj.currency,
        game_type: gameObj.gameType
    }
    return apiHelper().post(`/${GAME.GAME}`, requestBody)
}

function gameCategories() {
    return apiHelper().get(`/${GAME.GAME}/${GAME.CATEGORIES}`)
}

function getJackpot() {
    return apiHelper(`${GAME.MEDIA_SERVER_URL}`, false).get(`/${GAME.JACKPOT_URL}`)
}

function matchesHighlights() {
    return apiHelper(`${GAME.MEDIA_SERVER_URL}/${GAME.SERVER_NAME}`, false).get(`/cricket/data_${SHARED.DEFAULT_CURRENCY}.json?v=`+Date.now())
}
