import moment from 'moment'
import { TIME } from '@/constants/constants'

export default function halftimeFormat(value) {
  let result = ''

  if (value != undefined && value != null && value != '') {
    let date = moment(String(value)).format(TIME.FULL)
    let arr = date.split(" ")
    return arr[0] + " GMT " + arr[1] 
  }

  return result
}
