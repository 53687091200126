<template>
  <v-footer class="footer-color">
    <v-row no-gutters justify="center" align="center">
      <!-- <v-col cols="12" class="px-6">
                <v-row no-gutters>
                    <v-col cols="12" :lg="4" style="display: flex; align-items: center"
                           v-if="$vuetify.breakpoint.mdAndUp">
                        {{$t(`label.paymentMethod`)}}&nbsp;
                        <img src="/static/image/payment/payNow.png" width="200px"/>
                    </v-col>
                    <v-col cols="12" v-else>
                        <v-row>
                            <v-col cols="12" class="text-center">
                                {{$t(`label.paymentMethod`)}}
                            </v-col>
                        </v-row>
                        <v-row class="justify-center" align="center" no-gutters>
                            <v-col cols="12" :sm="2" class="mx-auto text-center">
                                <img src="/static/image/payment/payNow.png" width="200px"/>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" :lg="8" :align="$vuetify.breakpoint.mdAndUp? 'end' :'center'"
                           :class="$vuetify.breakpoint.mdAndUp? 'py-0': 'py-2'">
                        <img width="25" height="25" style="vertical-align: middle"
                             :src="`${mediaUrl}/footer_icons/eighteen.svg`" alt="18+"
                             title="18+"/>
                        <span class="px-4 navigator_active--text">Responsible Gaming</span>
                    </v-col>
                </v-row>

            </v-col> -->

      <v-col cols="12" :class="$vuetify.breakpoint.xsOnly ? 'px-3 py-4' : 'paddingLR py-4'">
        <div v-if="!$vuetify.breakpoint.smAndDown" class="dotted-line py-4"></div>
        <v-divider v-else class="footer-divider"></v-divider>
      </v-col>

      <v-row v-if="$vuetify.breakpoint.smOnly" no-gutters justify="space-between" :class="$vuetify.breakpoint.xsOnly ? 'px-1 pl-4' : 'px-1'">
        <v-col cols="12" :lg="7" :class="$vuetify.breakpoint.mdAndDown ? 'justify-center' : 'justify-start'">
          <template v-for="(link, index) in footerLink">
            <v-btn
              v-if="$vuetify.breakpoint.smAndUp"
              color="white"
              :ripple="false"
              retain-focus-on-click
              exact-active-class
              :key="link.text_key"
              :to="{ name: link.routeName, params: { lang: $route.params.lang } }"
              text
              height="30"
              rounded
              class="my-0 mr-12 text-capitalize white--text px-3 footer"
            >
              <label class="footer-link-label text-decoration-underline subtitle-1">{{ $t(`label.${link.text_key}`) }}</label>
            </v-btn>
            <v-btn
              v-if="$vuetify.breakpoint.xsOnly"
              color="white"
              :ripple="false"
              retain-focus-on-click
              exact-active-class
              :key="link.text_key"
              :to="{ name: link.routeName, params: { lang: $route.params.lang } }"
              text
              height="30"
              rounded
              class="my-0 text-capitalize white--text pr-3 pl-1 footer"
            >
              <label class="footer-link-label text-decoration-underline caption">{{ $t(`label.${link.text_key}`) }}</label>
            </v-btn>
            <!-- <v-divider class="footer-link-divider" v-if="index + 1 < footerLink.length" vertical
                                   :key="index"></v-divider> -->
          </template>
        </v-col>
      </v-row>
      <v-row v-if="!$vuetify.breakpoint.smAndDown" no-gutters justify="space-between" :class="$vuetify.breakpoint.xsOnly ? 'px-1 pl-4' : 'px-1 pb-4'">
        <v-col cols="6" class="paddingLR">
          <v-row no-gutters>
            <h1 class="main_title">{{ $t(`footer.aboutBabu`) }}</h1>
          </v-row>
          <v-row no-gutters class="pt-3">
            <p class="seo_text">{{ $t(`footer.aboutBabuDescription`) }}</p>
          </v-row>
        </v-col>
        <v-col cols="6" class="px-5">
          <v-row no-gutters>
            <p class="main_title">{{ $t(`footer.partners`) }}</p>
          </v-row>
          <v-row no-gutters class="pt-3">
            <v-col cols="2">
              <v-row no-gutters class="d-flex justify-center align-center">
                <img class="" width="60" height="auto" src="/static/image/footer/partner1.png" alt />
              </v-row>
            </v-col>
            <v-col cols="2">
              <v-row no-gutters class="d-flex justify-center align-center">
                <img class="" width="60" height="auto" src="/static/image/footer/partner2.png" alt />
              </v-row>
            </v-col>
            <v-col cols="2">
              <v-row no-gutters class="d-flex justify-center align-center">
                <img class="" width="60" height="auto" src="/static/image/footer/partner3.png" alt />
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters class="pt-3">
            <v-col cols="2">
              <v-row no-gutters class="d-flex justify-center align-center">
                <p class="seo_text">Montreal Tigers</p>
              </v-row>
            </v-col>
            <v-col cols="2">
              <v-row no-gutters class="d-flex justify-center align-center">
                <p class="seo_text">Colombo Strikers</p>
              </v-row>
            </v-col>
            <v-col cols="2">
              <v-row no-gutters class="d-flex justify-center align-center">
                <p class="seo_text">Northern Warriors</p>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="paddingLR pt-5">
          <v-row no-gutters>
            <p class="main_title">{{ $t(`label.certifiedBy`) }}</p>
          </v-row>
          <v-row no-gutters class="">
            <a :href="this.casinolink" target="_blank">
              <img class="mr-3 mt-1" width="200px" src="/static/image/footer/babu88-official.png" alt />
            </a>
          </v-row>
        </v-col>
        <v-col cols="6" class="pt-5 px-5">
          <v-row no-gutters>
            <p class="main_title">{{ $t(`label.responsibleGaming`) }}</p>
          </v-row>
          <v-row no-gutters class="">
            <img class="footer-icon" src="/static/svg/btm-18+.svg" alt />
            <img class="footer-icon" src="/static/svg/btm-gamcare.svg" alt />
          </v-row>
        </v-col>
      </v-row>
      <v-col cols="12" class="paddingLR py-5">
        <div v-if="!$vuetify.breakpoint.smAndDown" class="dotted-line py-4"></div>
        <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters justify="space-between">
          <v-col cols="6">
            <v-row no-gutters>
              <label class="subtitle-1 footer-link-label">
                <p class="main_title">{{ $t(`label.paymentMethod`) }}</p>
              </label>
            </v-row>
            <v-row no-gutters v-if="$vuetify.breakpoint.mdAndUp" class="d-flex align-center">
              <!--              <img class="mr-3" width="162" height="32" src="/static/image/footer/475047.png" alt />-->
              <v-row no-gutters class="d-flex align-center" v-if="currency.toLowerCase() === 'bdt'">
                <a>
                  <img class="mr-4 mt-2 grayscale-on-hover" width="60px" src="/static/image/footer/icon_footer_bkash_colour.svg" alt="bkash9" />
                </a>
                <a>
                  <img class="mr-4 mt-2 grayscale-on-hover" width="60px" src="/static/image/footer/icon_footer_nagad_colour.svg" alt="nagad" />
                </a>
                <a>
                  <img class="mr-4 mt-1 grayscale-on-hover" width="60px" src="/static/image/footer/icon_footer_rocket_colour.svg" alt="rocket" />
                </a>
                <a>
                  <img class="mr-4 mt-2 grayscale-on-hover" width="60px" src="/static/image/footer/icon_footer_upay_colour.svg" alt="upay" />
                </a>
              </v-row>
              <v-row no-gutters class="d-flex align-center" v-else-if="currency.toLowerCase() === 'inr'">
                <a>
                  <img class="mr-4 mt-2 grayscale-on-hover" width="60px" src="/static/image/footer/icon_footer_paytm_colour.svg" alt="bkash9" />
                </a>
                <a>
                  <img class="mr-4 mt-2 grayscale-on-hover" width="60px" src="/static/image/footer/icon_footer_phonepe_colour.svg" alt="nagad" />
                </a>
                <a>
                  <img class="mr-4 mt-1 grayscale-on-hover" width="60px" src="/static/image/footer/icon_footer_UPI_colour.svg" alt="rocket" />
                </a>
                <a>
                  <img class="mr-4 mt-2 grayscale-on-hover" width="60px" src="/static/image/footer/icon_footer_bankdeposit_colour.svg" alt="upay" />
                </a>
              </v-row>
              <v-row no-gutters class="d-flex align-center" v-else-if="currency.toLowerCase() === 'npr'">
                <a>
                  <img class="mr-4 mt-2 grayscale-on-hover" width="60px" src="/static/image/footer/icon_footer_connectIPS_colour.svg" alt="bkash9" />
                </a>
                <a>
                  <img class="mr-4 mt-2 grayscale-on-hover" width="60px" src="/static/image/footer/icon_footer_khaltiwallet_colour.svg" alt="nagad" />
                </a>
                <a>
                  <img class="mr-4 mt-1 grayscale-on-hover" width="60px" src="/static/image/footer/icon_footer_esewa_colour.svg" alt="rocket" />
                </a>
                <a>
                  <img class="mr-4 mt-2 grayscale-on-hover" width="60px" src="/static/image/footer/icon_footer_bankdeposit2_colour.svg" alt="upay" />
                </a>
              </v-row>
              <!--              <img class="mr-3" width="100" height="32" src="/static/image/footer/gamingcuracao.png" alt />-->
            </v-row>
          </v-col>
          <v-col cols="6" class="px-5">
            <v-row no-gutters>
              <label class="subtitle-1 footer-link-label">
                <p class="main_title">{{ $t(`footer.followUs`) }}</p>
              </label>
            </v-row>
            <v-row no-gutters>
              <a :href="this.fblink" target="_blank">
                <img class="footer-icon grayscale-on-hover" src="/static/svg/hover_btm-fb.svg" alt />
              </a>
              <a :href="this.ytLink" target="_blank" @mouseenter="hoverYT = true" @mouseleave="hoverYT = false">
                <img class="footer-icon" :src="hoverYT ? '/static/svg/hover_btm-yt.svg' : '/static/svg/btm-yt.svg'" alt />
              </a>
              <!--              <a :href="this.ytLink" target="_blank">-->
              <!--                <img class="footer-icon grayscale-on-hover" src="/static/svg/hover_btm-yt.svg" alt />-->
              <!--              </a>-->
              <a :href="this.instaLink" target="_blank" v-if="this.currency == currenciesList.BDT">
                <img class="footer-icon grayscale-on-hover" src="/static/svg/hover_btm-ig.svg" alt />
              </a>
              <a :href="this.twtLink" target="_blank" @mouseenter="hoverX = true" @mouseleave="hoverX = false" v-if="this.currency == currenciesList.BDT">
                <img class="footer-icon" :src="hoverX ? '/static/svg/hover_btm-twitter.svg' : '/static/svg/btm-twitter.svg'" alt />
              </a>
              <a :href="this.tlgLink" target="_blank">
                <img class="footer-icon grayscale-on-hover" src="/static/svg/hover_btm-tlg.svg" alt />
              </a>
            </v-row>
          </v-col>
          <!--          <v-col cols="4">-->
          <!--            <v-row no-gutters>-->
          <!--              <label class="subtitle-1 footer-link-label"><p class="main_title">Responsible Games</p></label>-->
          <!--            </v-row>-->
          <!--            <v-row no-gutters>-->
          <!--              <img class="footer-icon" src="/static/svg/btm-18.svg" alt />-->
          <!--            </v-row>-->
          <!--          </v-col>-->
        </v-row>
        <v-row no-gutters v-if="$vuetify.breakpoint.mdAndUp" class="mt-12 align white--text">
          {{ footerCopyRight }}
        </v-row>
        <!--        <v-divider class="footer-divider"></v-divider>-->
        <v-row no-gutters v-show="!isViewableAll && $vuetify.breakpoint.smAndDown">
          <v-col cols="12" :lg="4">
            <!-- <v-row>
                            <v-col cols="12" class="text-decoration-underline pt-0">
                                <v-btn text @click="isViewableAll = true" class="font-weight-bold">
                                    Understand more
                                </v-btn>
                            </v-col>
                        </v-row> -->
          </v-col>
        </v-row>
      </v-col>
      <v-row no-gutters v-show="$vuetify.breakpoint.mdAndUp || isViewableAll" class="py-6 paddingLR second_footer">
        <v-col cols="12">
          <h3 class="main_title pb-2">
            {{ $t(`footer.trustedPremiumOnlineCasinoTitle`) }}
          </h3>
          <p class="seo_text">{{ $t(`footer.trustedPremiumOnlineCasinoDesc_1`) }}</p>
        </v-col>
        <v-col v-if="showMore" cols="12" :lg="12">
          <v-row class="mt-2">
            <v-col cols="10" :class="$vuetify.breakpoint.mdAndUp ? 'py-2' : 'mx-auto text-center'">
              <h3 class="main_title pb-2">
                {{ $t(`footer.gameAvailable`) }}
              </h3>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="showMore" cols="12" :lg="4">
          <v-row class="mt-2">
            <v-col cols="10" :class="$vuetify.breakpoint.mdAndUp ? 'py-2' : 'mx-auto text-center'">
              <h3 class="main_title pb-2">
                {{ $t(`footer.slotGames`) }}
              </h3>
              <p class="seo_text">
                {{ $t(`footer.slotGamesDes`) }}
              </p>
            </v-col>
            <v-col cols="10" :class="$vuetify.breakpoint.mdAndUp ? 'py-2' : 'mx-auto text-center'">
              <h3 class="main_title pb-2">
                {{ $t(`footer.sportsBetting`) }}
              </h3>
              <p class="seo_text">
                {{ $t(`footer.sportsBettingDes`) }}
                <!--                <br />-->
                <!--                {{ $t(`footer.liveCasinoGamesOnlineCasinoDesc_2`) }}-->
                <!--                <br />-->
                <!--                {{ $t(`footer.liveCasinoGamesOnlineCasinoDesc_3`) }}-->
              </p>
            </v-col>
            <v-col cols="10" :class="$vuetify.breakpoint.mdAndUp ? 'py-2' : 'mx-auto text-center'">
              <h3 class="main_title pb-2">
                {{ $t(`footer.liveCasino`) }}
              </h3>
              <p class="seo_text">
                {{ $t(`footer.liveCasinoDes`) }}
                <!--                <br />-->
                <!--                {{ $t(`footer.sportsBettingDesc_2`) }}-->
                <!--                <br />-->
                <!--                {{ $t(`footer.sportsBettingDesc_3`) }}-->
                <!--                <br />-->
                <!--                {{ $t(`footer.sportsBettingDesc_4`) }}-->
                <!--                <br />-->
                <!--                {{ $t(`footer.sportsBettingDesc_5`) }}-->
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="showMore" cols="12" :lg="4">
          <v-row class="mt-2">
            <v-col cols="10" :class="$vuetify.breakpoint.mdAndUp ? 'py-2' : 'mx-auto text-center'">
              <h3 class="main_title pb-2">
                {{ $t(`footer.paymentOptions`) }}
              </h3>
              <p class="seo_text">
                {{ $t(`footer.paymentOptionsDes`) }}
              </p>
            </v-col>
            <v-col cols="10" :class="$vuetify.breakpoint.mdAndUp ? 'py-2' : 'mx-auto text-center'">
              <h3 class="main_title pb-2">
                {{ $t(`footer.license`) }}
              </h3>
              <p class="seo_text">
                {{ $t(`footer.licenseDes`) }}
              </p>
            </v-col>
            <!-- <v-col cols="10" :class="$vuetify.breakpoint.mdAndUp ? 'py-2' : 'mx-auto text-center'">
                              <p class="main_title">
                                  <b>Looking to find out the livescore of football , EPL, La liga, Bundesliga, World
                                      cup or Euro tournament football scores?</b>
                              </p>
                              <p class="seo_text">
                                  Find all live scores and football matches at BABU88. Register and open up our app,
                                  with tons of variations to play with, you will have the best experience and mobile
                                  sportsbook at BABU88
                              </p>
                          </v-col>
                          <v-col cols="10" :class="$vuetify.breakpoint.mdAndUp ? 'py-2' : 'mx-auto text-center'">
                              <p class="main_title">
                                  <b>BABU88 has an exclusive sportsbook for sports related activities and all your
                                      favourite sports.</b>
                              </p>
                              <p class="seo_text">
                                  With every sports game available to bet, place your winning bet with BABU88 today!
                              </p>
                          </v-col>
                          <v-col cols="10" :class="$vuetify.breakpoint.mdAndUp ? 'py-2' : 'mx-auto text-center'">
                              <p class="main_title">
                                  <b>Slot Games - 918Kiss, Mega888</b>
                              </p>
                              <p class="seo_text">
                                  Look no further as BABU88 comes with the most popular slot game providers such as
                                  918kiss, mega888, xe88, 918kaya, PlayTech, Pragmatic Play, GPI, Play n Go, and more.
                                  With this large of a variety, there's so much games to be played and rewards to be
                                  obtained. You won't be bored!
                              </p>
                          </v-col>

                          <v-col cols="10" :class="$vuetify.breakpoint.mdAndUp ? 'py-2' : 'mx-auto text-center'">
                              <p class="main_title">
                                  <b>Play Your Favourite Kiss 918 Games Through The 918kiss apk!</b>
                              </p>
                              <p class="seo_text">
                                  You might have heard your friends checking out kiss 918 or 918kaya before! So, what
                                  are you waiting for? Don’t be left behind when it comes to having fun! An
                                  unbelievable world of mobile and online casino entertainment awaits you, and all you
                                  have to do is get the 918kiss apk!<br/>
                                  You can find the download link for the 918kiss apk right here on this website. The
                                  918kiss download is available for both Android and iOS, so no worries about what
                                  kind of device you have!
                              </p>
                          </v-col> -->
          </v-row>
        </v-col>
        <v-col v-if="showMore" cols="12" :lg="4">
          <v-row class="mt-2">
            <v-col cols="10" :class="$vuetify.breakpoint.mdAndUp ? 'py-2' : 'mx-auto text-center'">
              <h3 class="main_title pb-2">
                {{ $t(`footer.tnc`) }}
              </h3>
              <p class="seo_text">
                {{ $t(`footer.tncDes`) }}
              </p>
            </v-col>
            <v-col cols="10" :class="$vuetify.breakpoint.mdAndUp ? 'py-2' : 'mx-auto text-center'">
              <h3 class="main_title pb-2">
                {{ $t(`footer.24/7support`) }}
              </h3>
              <p class="seo_text">
                {{ $t(`footer.24/7supportDes`) }}
              </p>
            </v-col>
            <!-- <v-col cols="10" :class="$vuetify.breakpoint.mdAndUp ? 'py-2' : 'mx-auto text-center'">
                              <p class="main_title">
                                  <b>Payments Options</b>
                              </p>
                              <p class="seo_text">
                                  The best online casino sites offer a variety of ways to deposit and withdraw money.
                                  This makes the games accessible to every individuals based on their own preferences.
                                  We provide different kinds of payments choices for our customers, including PayNow.
                              </p>
                          </v-col>
                          <v-col cols="10" :class="$vuetify.breakpoint.mdAndUp ? 'py-2' : 'mx-auto text-center'">
                              <p class="main_title">
                                  <b>The Top Mega 888 apk Download Location!</b>
                              </p>
                              <p class="seo_text">
                                  918 kiss is not the only great mobile casino we have here at BABU88!
                                  We also feature Mega 888, which is well known as another extremely popular mobile
                                  casino in Bangladesh!
                                  Just like with the 918 kiss apk, the Mega 888 apk will give you access to a massive
                                  range of online casino games.<br/>
                                  Want to know what kind of games? Keep reading on to find out all about them!<br/>
                                  There is only one step left. As we mentioned earlier, you have to sign up for the
                                  mobile casino! Fortunately, this is a very quick and easy step. For example, the
                                  918kiss register form only asks for a few details.
                                  Don’t worry about the safety of this information – the highest-grade encryption is
                                  used to protect it!<br/>
                                  After you’ve signed up, simply enter your username and password when you open up the
                                  app, and you’re in!
                              </p>
                          </v-col>
                          <v-col cols="10" :class="$vuetify.breakpoint.mdAndUp ? 'py-2' : 'mx-auto text-center'">
                              <p class="main_title">
                                  <b>Online Gaming Providers that you should check them out</b>
                              </p>
                              <p class="seo_text">
                                  The most popular site that hold a rising number of best casino providers from all
                                  over the world, especially Bangladesh and Indonesia.
                                  A wide range of casino games will be available for players to encounter and play.
                                  Try out instant deposit and withdraw cash at anytime and anywhere.
                                  Spot every online casino games in our site, such as Live Casino, Slots, Poker,
                                  Fishing, TV and Score.
                                  Being excited to become a member to enjoy the Promos, including welcome bonus and
                                  reload bonus in term of new member, sport-book and slot game.
                              </p>
                          </v-col> -->
          </v-row>
        </v-col>
        <label @click="showMore = !showMore" v-if="!showMore" class="readMore">{{ $t(`footer.readMore`) }}</label>
        <label @click="showMore = !showMore" v-if="showMore" class="readMore">{{ $t(`footer.readLess`) }}</label>
      </v-row>
      <v-col cols="12" class="px-5" v-if="!$vuetify.breakpoint.mdAndUp">
        <v-row no-gutters justify="space-between">
          <v-col cols="6" :lg="4">
            <v-row no-gutters>
              <label class="subtitle-1 footer-link-label">Responsible Games</label>
            </v-row>
            <v-row no-gutters>
              <img class="footer-icon" src="/static/svg/btm-18.svg" alt />
            </v-row>
          </v-col>
          <v-col cols="6" :lg="4">
            <v-row no-gutters>
              <label class="subtitle-1 footer-link-label">{{ $t(`label.certifiedBy`) }}</label>
            </v-row>
            <v-row no-gutters v-if="$vuetify.breakpoint.mdAndUp">
              <!--              <img class="mr-3" width="162" height="32" src="/static/image/footer/475047.png" alt />-->
              <a href="https://babu88official.com/get-started/" target="_blank">
                <img class="mr-3 mt-1" width="200px" src="/static/image/footer/babu88-official.png" alt />
              </a>
              <!--              <img class="mr-3" width="100" height="32" src="/static/image/footer/gamingcuracao.png" alt />-->
            </v-row>
            <v-row no-gutters v-if="$vuetify.breakpoint.smAndDown">
              <!--              <img class="mr-3" width="103" height="21" src="/static/image/footer/475047.png" alt />-->
              <a :href="this.casinolink" target="_blank">
                <img class="mr-3 mt-1" width="132px" src="/static/image/footer/babu88-official.png" alt />
              </a>
              <!--              <img class="mr-3" width="64" height="21" src="/static/image/footer/gamingcuracao.png" alt />-->
            </v-row>
          </v-col>
          <v-col cols="12" :lg="4">
            <v-row no-gutters>
              <label class="subtitle-1 footer-link-label">Find Us</label>
            </v-row>
            <v-row no-gutters>
              <a :href="this.fblink" target="_blank">
                <img class="footer-icon" src="/static/svg/btm-fb.svg" alt />
              </a>
              <a :href="this.instaLink" target="_blank" v-if="this.currency == currenciesList.BDT">
                <img class="footer-icon" src="/static/svg/btm-insta.svg" alt />
              </a>
              <a :href="this.twtLink" target="_blank" v-if="this.currency == currenciesList.BDT">
                <img class="footer-icon" src="/static/svg/btm-twitter.svg" alt />
              </a>
              <a :href="this.ytLink" target="_blank">
                <img class="footer-icon" src="/static/svg/btm-yt.svg" alt />
              </a>
              <a :href="this.tlgLink" target="_blank">
                <img class="footer-icon" src="/static/svg/btm-tlg.svg" alt />
              </a>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="!$vuetify.breakpoint.mdAndUp" class="px-5 mt-12 align" cols="12" :class="$vuetify.breakpoint.mdAndUp ? 'pr-4' : 'pr-4 py-2'">
        {{ footerCopyRight }}
      </v-col>
      <!-- <v-col cols="12" class="px-6 py-4">
                <v-divider class="title_color"></v-divider>
            </v-col> -->
      <!-- <v-col cols="12" class="px-8 mx-0">
                <v-row :justify="$vuetify.breakpoint.mdAndUp? 'start' :'center'" no-gutters>
                    <v-col cols="12" class="shrink footer-vendor-box-col">
                        <div>
                            <label class="white--text footer-box-title">{{ $t(`label.poweredBy`) }}:</label>
                        </div>
                        <div class="footer-vendor-box">
                            <template v-for="(k, i) in footerProvider">
                                <img class="footer-image"
                                     :src="`${mediaUrl}/footer_icons/${k}_footer.png`" alt
                                     :key="i"/>
                            </template>
                        </div>
                    </v-col>
                </v-row>
                <v-row :justify="$vuetify.breakpoint.mdAndUp? 'space-between' :'center'" no-gutters>
                    <v-col cols="auto" class="shrink footer-license-box-col">
                        <div>
                            <label class="white--text footer-box-title">{{ $t(`label.license`) }}:</label>
                        </div>
                        <div class="text-center">
                            <img class="footer-image license" src="/static/image/license/first-cagayan.png"
                                 @click="goToFirstCagayan"/>
                            <img class="footer-image license" src="/static/image/license/pagcor.png"
                                 @click="goToPagcor"/>
                        </div>
                    </v-col>
                    <v-col cols="auto" class="shrink footer-language-box-col">
                        <div class="text-center">
                            <label class="white--text footer-box-title">{{ $t(`label.language`) }}:</label>
                        </div>
                        <div class="text-center">
                            <img @click="changeLanguage(language)" v-for="language in supportedLanguage" :key="language"
                                 class="footer-image language" :src="`/static/image/country/${language}.png`" alt/>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" :class="$vuetify.breakpoint.smAndDown ? 'mx-auto justify-center' : ''">
                        <v-img :src="`${mediaUrl}/ssl_cert.png`" :aspect-ratio="167/42" width="167"/>
                    </v-col>
                </v-row>
            </v-col> -->
    </v-row>
  </v-footer>
</template>

<script>
import NavOnePay from '@/assets/svg/onePay.svg'
import { ROUTE_NAME } from '@/constants/route.constants'
import format from 'string-format'
import { locale, uiHelper } from '@/util'
import { SHARED, CACHE_KEY, Currencies } from '@/constants/constants'
import { SHARED_LOADING } from '@/store/shared.module'
import _ from 'lodash'

export default {
  name: 'appFooter',
  props: {},
  components: {
    NavOnePay
  },
  data: () => ({
    showMore: false,
    hoverX: false,
    hoverYT: false,
    currenciesList: Currencies,
    currency: uiHelper.getCurrency(),
    casinolink: uiHelper.getcasinoLink(),
    fblink: uiHelper.getFBLink(),
    instaLink: uiHelper.getInstaLink(),
    twtLink: uiHelper.getTwtLink(),
    ytLink: uiHelper.getYTLink(),
    tlgLink: uiHelper.getTlgLink(),
    isViewableAll: false,
    footerProvider: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST_TYPE_NAME}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    supportedLanguage: SHARED.SUPPORTED_LANGUAGES,
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    footerLink: [
      // {
      //   link: '/customer-service',
      //   text: locale.getMessage(`label.customerService`)
      // },
      {
        routeName: ROUTE_NAME.TNC,
        text_key: 'termsAndConditions'
      },
      {
        routeName: ROUTE_NAME.ABOUT_US,
        text_key: 'aboutUs'
      },
      {
        routeName: ROUTE_NAME.FAQ,
        text_key: 'faq'
      },
      {
        routeName: ROUTE_NAME.CONTACT_US,
        text_key: 'contactUs'
      }
    ],
    footerCopyRight: format(locale.getMessage(`message.copyRight`), new Date().getFullYear())
  }),
  computed: {
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    }
  },
  watch: {
    ProviderTypes() {
      this.footerProvider = uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST_TYPE_NAME}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`)
      return this.$store.state.game.ProviderTypes
    }
  },
  methods: {
    changeLanguage(language) {
      this.$store.dispatch(`${SHARED_LOADING}`)
      locale.setCurrentLanguage(language)
      uiHelper.removeCookie('language')
      uiHelper.setCookie('language', language)
      window.location.reload()
    },
    goToFirstCagayan() {
      window.open('https://www.firstcagayan.com/zh-hant#2', '_blank')
    },
    goToPagcor() {
      window.open('https://pagcor.ph/index.php', '_blank')
    }
  }
}
</script>

<style lang="scss">
.grayscale-on-hover:hover {
  filter: grayscale(0%);
}
.grayscale-on-hover {
  filter: grayscale(100%);
}
.readMore {
  color: #0080f6;
  text-decoration: underline;
}
.paddingLR {
  padding: 0 12% !important;
}
.second_footer {
  background-color: #000;
  color: #fff;
}
.dotted-line {
  border-top: 1px dotted #fff; /* Adjust the color and thickness as needed */
  margin-top: 10px; /* Adjust the margin as needed */
}
.footer-divider {
  color: #3a3a3a;
}
.footer-icon {
  float: center;
  display: block;
  width: 50px;
  height: 50px;
  margin: 5px 12px 0px 0px;
}
.navPayIcon {
  width: 120px;
  height: 50px;
  margin-top: -15px;
  fill: #0f80aa;
}

.payNowIcon {
  width: 144px;
  height: 28px;
}

.main_title {
  color: #fff;
  font-size: 16px;
}

.seo_text {
  font-size: 14px;
  color: #fff;
}

.v-footer {
  min-height: 200px;
  background-color: #ebebeb;
  padding: 25px 0 0px;

  .footer-box-title {
    font-size: 18px;
    display: block;
    padding: 10px 0px;
  }

  .footer-link-label {
    color: #3a3a3a;
    letter-spacing: 0;
    cursor: pointer;

    &:hover {
      color: white;
    }
  }

  .footer-link-divider {
    border-color: #3a3a3a;
  }
}

.footer-row {
  padding-left: 65px;
  padding-right: 65px;

  &.col {
    padding: 0px 8px;
  }
}

.footer-image {
  margin-right: 15px;
  margin-bottom: 10px;
  height: 30px;

  &.language,
  &.license {
    cursor: pointer;
  }
}

.footer-color {
  background-color: #ebebeb !important;
}

@media (max-width: 1650px) {
}

@media (max-width: 1500px) {
}

@media (max-width: 1350px) {
  .footer-box-title,
  .footer-link-label {
    font-size: 14px;
  }
}

@media (max-width: 1263px) {
  .footer-vendor-box-col {
    min-width: 90%;
    max-width: 90%;

    .footer-vendor-box {
      max-width: 100%;
    }
  }

  .footer-image {
    height: 25px;
  }
}

@media (max-width: 1024px) {
}

@media (max-width: 959px) {
}

@media (max-width: 599px) {
  .footer-icon {
    float: center;
    display: block;
    width: 30px;
    height: 30px;
    margin: 5px 12px 0px 0px;
  }
  .footer-box-title,
  .footer-link-label {
    font-size: 16px;
  }

  .footer-vendor-box-col {
    min-width: 90%;
    max-width: 90%;
    text-align: center;
  }
  .footer-transaction-method-box-col {
    min-width: 90%;
    max-width: 90%;
    text-align: center;
  }
  .footer-license-box-col {
    min-width: 90%;
    max-width: 90%;
    text-align: center;
  }
  .footer-language-box-col {
    min-width: 90%;
    max-width: 90%;
    text-align: center;
  }
}

@media (max-width: 425px) {
}
@media (min-width: 900px) {
  .footer-color {
    background-color: #333333 !important;
  }
}
</style>
