const MODULE_NAME = 'shared/'

//define actions name
const A_LOADING = 'loading'
const A_UNLOADING = 'unloading'
const A_DIALOG = 'openDialog'
const A_CLOSE_DIALOG = 'closeDialog'

//define dispatch action
export const SHARED_LOADING = MODULE_NAME + A_LOADING
export const SHARED_UNLOADING = MODULE_NAME + A_UNLOADING
/* SHARED DIALOG IS NOT INUSE */
export const SHARED_DIALOG = MODULE_NAME + A_DIALOG
export const SHARED_CLOSE_DIALOG = MODULE_NAME + A_CLOSE_DIALOG

//define mutations name
const M_LOADING = 'loading'
const M_UNLOADING = 'unloading'
const M_DIALOG = 'openDialog'
const M_CLOSE_DIALOG = 'closeDialog'

const state = {
  isLoading: null,
  isShowDialog: null,
  dialog: {
    title: '',
    messages: [],
    mainButtonTitle: '',
    isSecondaryButtonShow: false,
    secondaryButtonTitle: ''
  }
}

//to retrieve data from store state (called by component)
const getters = {}

//to perform some actions related with updating state (called by component)
const actions = {
  [A_LOADING]({ commit }) {
    commit(M_LOADING)
  },
  [A_UNLOADING]({ commit }) {
    commit(M_UNLOADING)
  },
  [A_DIALOG]({ commit }, { dialogTitle, dialogMessages, dialogMainButtonTitle, dialogIsSecondaryButtonShow, dialogSecondaryButtonTitle }) {
    commit(M_DIALOG, { dialogTitle, dialogMessages, dialogMainButtonTitle, dialogIsSecondaryButtonShow, dialogSecondaryButtonTitle })
  },
  [A_CLOSE_DIALOG]({ commit }) {
    commit(M_CLOSE_DIALOG)
  }
}

//to update state values (called by actions)
const mutations = {
  [M_LOADING](state) {
    state.isLoading = true
  },
  [M_UNLOADING](state) {
    setTimeout(function() {
      state.isLoading = false
    }, 300)
  },
  [M_DIALOG](state, { dialogTitle, dialogMessages, dialogMainButtonTitle, dialogIsSecondaryButtonShow, dialogSecondaryButtonTitle }) {
    state.dialog.title = dialogTitle
    state.dialog.messages = dialogMessages
    state.dialog.mainButtonTitle = dialogMainButtonTitle
    state.dialog.isSecondaryButtonShow = dialogIsSecondaryButtonShow
    state.dialog.secondaryButtonTitle = dialogSecondaryButtonTitle
    state.isShowDialog = true
  },
  [M_CLOSE_DIALOG]() {
    state.dialog.title = ''
    state.dialog.messages = []
    state.dialog.mainButtonTitle = ''
    state.dialog.isSecondaryButtonShow = false
    state.dialog.secondaryButtonTitle = ''
    state.isShowDialog = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
