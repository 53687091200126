import { locale } from '@/util'

export default function isVerifiedStatusDesc(value) {
  let desc = ''

  if (typeof value === 'number') {
    desc = locale.getMessage(`enumVerificationStatus[0].${value}`)
  } else if (typeof value === 'boolean') {
    desc = locale.getMessage(`enumVerificationStatus[1].${value}`)
  }

  return desc
}
