import { apiHelper } from '@/util'

export const referralService = {
  referralOverview,
  referralDownlines,
  referralReport,
  redeemCommission,
  referralConfig
}

//API urls
const REFERRAL = {
  REFERRAL: 'referral',
  OVERVIEW: 'overview',
  DOWNLINES: 'downlines',
  REPORT: 'report',
  COMMISSION: 'commission',
  CONFIG: 'config'
}

function referralOverview() {
    return apiHelper().get(`/${REFERRAL.REFERRAL}/${REFERRAL.OVERVIEW}`)
}

function referralDownlines(data) {
  const requestOptions = {
    params: {
      page: data.page,
      size: data.itemsPerPage,
      start_date: data.start_date,
      end_date: data.end_date
    }
  }
  return apiHelper().get(`/${REFERRAL.REFERRAL}/${REFERRAL.DOWNLINES}`, requestOptions)
}

function referralReport(data) {
  const requestOptions = {
    params: {
      page: data.page,
      size: data.itemsPerPage
      //start_date: data.start_date,
      //end_date: data.end_date
    }
  }
  return apiHelper().get(`/${REFERRAL.REFERRAL}/${REFERRAL.REPORT}`, requestOptions)
}

function redeemCommission(data) {
  const requestBody = {
    amount: data.amount
  }
  return apiHelper().post(`/${REFERRAL.REFERRAL}/${REFERRAL.COMMISSION}`, requestBody)
}

function referralConfig() {
  return apiHelper().get(`/${REFERRAL.REFERRAL}/${REFERRAL.CONFIG}`)
}