<template>
  <div id="my_custom_link" class="floating-left"><img src="/static/image/icon/icon-live-chat.jpeg" alt="live-chat" />
  </div>
</template>

<style lang="scss">
.floating-left {
  cursor: pointer;
  display: flex;
  width: 56px;
  height: 56px;
  position: fixed;
  z-index: 2147483000;
  bottom: 70px;
  right: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  background-color: #ffce01;

  &:hover {
    transition: transform 250ms cubic-bezier(0.33, 0.00, 0.00, 1.00);
    transform: scale(1.1);
  }

  @media (max-width: 450px) {
    bottom: 70px;
    right: 10px;
  }

  img {
    width: 42px;
    height: 42px;
    border-radius: 21px;
  }
} </style>

<script>
export default {
  name: 'appFloatingChat',
}
</script>
