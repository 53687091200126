<template>
    <v-btn class="full-width font-weight-bold pa-2" :disabled="disabled" :class="customClass"
           :max-width="maxWidth" @click="action">{{ title }}
    </v-btn>
</template>

<script>
    export default {
        name: 'appButton',
        props: {
            title: {
                type: String,
                required: true,
                default: ''
            },
            action: {
                type: Function,
                required: true,
                default: () => ({})
            },
            maxWidth: {
                type: Number,
                required: false,
                default: undefined
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            customClass: {
                type: String,
                required: false,
                default: 'buttonPrimary theme-button theme--light subtitle-1'
            }
        }
    }
</script>

<style lang="scss" scoped>
    .secondary_button {
        background: transparent !important;
        text-decoration: underline;
        color: var(--v-navigator_active-base) !important;
        box-shadow: none!important;
    }

    .v-btn.theme-button {
        // background: #FDDD04;
        text-transform: capitalize;
        border-radius: 10px;
        height: auto;
        font-size: 16px;
        // &:not(.v-btn--text) {
        //     // border-radius: 25px;

        //     &:not(.v-btn--outlined).buttonPrimary {
        //         &:hover {
        //             background-color: var(--v-buttonHover-base) !important;
        //             border-color: var(--v-buttonHover-base) !important;

        //             &::before {
        //                 opacity: 0;
        //             }
        //         }
        //     }

        //     &:not(.v-btn--outlined) {
        //         transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        //     }
        // }
    }

    @media (max-width: 959px) {
        .v-btn.theme-button {
            width: 70px ;
            height: 30px;
            font-size: 12px !important;
        }
        .v-btn.dialog-button {
            width: inherit !important;
            height: auto !important;
            font-size: 14px !important;
        }
      .deposit-btn.buttonPrimary {
        background-color: var(--v-newMobileSecondary-base) !important;
        color: white !important;
      }
      .deposit-btn {
        width: inherit !important;
        height: auto !important;
        font-size: 14px !important;
        background-color: var(--v-newMobileSecondary-base) !important;
        color: white !important;
      }
    }
</style>
