var ProcessTransactionResponseType = {
  NONE: 1,
  URL_LINK: 2,
  HTML_AUTO_REDIRECT: 4,
  HTML_REDIRECT: 3,
  IMAGE_URL: 5,
  IMAGE_DATA: 6,
  OPEN_NEW_WINDOW: 'NEW_WINDOW',
  REDIRECT: 'REDIRECT'
}

export { ProcessTransactionResponseType }
