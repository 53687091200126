<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.60929 0C4.30805 0 3.25002 1.07334 3.25002 2.35944C3.25002 2.67404 3.31611 2.97381 3.42963 3.25002H0.750059C0.724076 3.24862 0.697917 3.24862 0.671935 3.25002C0.486914 3.26937 0.315492 3.35691 0.191314 3.49555C0.0671546 3.63401 -0.00103281 3.81398 1.18275e-05 4.00006V8.50002C1.18275e-05 8.69899 0.0790066 8.88977 0.219738 9.03032C0.36029 9.17105 0.551069 9.25005 0.750045 9.25005H1.50008V19.25C1.50008 19.4489 1.57908 19.6397 1.71964 19.7803C1.86036 19.921 2.05114 20 2.24994 20H17.7498C17.9486 20 18.1394 19.921 18.2801 19.7803C18.4207 19.6397 18.4997 19.4489 18.4997 19.25V9.25005H19.2497C19.4487 9.25005 19.6395 9.17106 19.78 9.03032C19.9207 8.88977 19.9997 8.69899 19.9997 8.50002V4.00006C19.9997 3.80108 19.9207 3.6103 19.78 3.46975C19.6395 3.32902 19.4487 3.25002 19.2497 3.25002H16.5701C16.6837 2.9738 16.7497 2.67404 16.7497 2.35945C16.7497 1.07334 15.6918 4.42774e-06 14.3905 4.42774e-06C13.0897 4.42774e-06 12.1284 0.629017 11.4218 1.30477C10.8885 1.8145 10.449 2.34307 9.99981 2.76561C9.55061 2.34307 9.11115 1.8145 8.57786 1.30477C7.87125 0.629017 6.90988 4.42774e-06 5.60913 4.42774e-06L5.60929 0ZM5.60929 1.50008C6.4641 1.50008 6.95883 1.83577 7.53905 2.39065C7.80202 2.64211 8.06395 2.9398 8.35149 3.25002H5.52348C5.48582 3.23101 5.44658 3.21549 5.4063 3.20311C5.02823 3.0976 4.74992 2.75182 4.74992 2.35945C4.74992 1.85986 5.09764 1.50009 5.60929 1.50009V1.50008ZM14.3905 1.50008C14.9021 1.50008 15.2498 1.85983 15.2498 2.35944C15.2498 2.75181 14.9715 3.09761 14.5935 3.2031C14.5506 3.21496 14.5087 3.23065 14.4686 3.25001H11.6483C11.9358 2.93978 12.1978 2.64211 12.4607 2.39064C13.0409 1.83573 13.5356 1.50007 14.3905 1.50007L14.3905 1.50008ZM1.49995 4.74992H9.24988V7.75002H1.49995V4.74992ZM10.7499 4.74992H18.4998V7.75002H10.7499V4.74992ZM2.99993 9.24988H9.24988V18.4998H2.99993V9.24988ZM10.7499 9.24988H16.9998V18.4998H10.7499V9.24988Z" fill="black"/>
  </svg>

</template>

<script>
export default {
  name: "RewardsIcon"
}
</script>

<style scoped>

</style>