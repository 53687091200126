<template>
  <div class="card-mania-bg">
    <div class="pack-box">
      <div class="prize-container">
        <div class="chances-box">
          <p>
            <b>{{ play_chances }}</b>
            chances
          </p>
        </div>
        <div>
          <span class="slogan">{{ $t(`label.card_mania_title`) }}</span>
        </div>
        <div class="prizes">
          <div class="prizes-content">
            <div class="prize-header">
              <img src="/static/image/cardMania/gadgets.png" alt="gadgets" />
              <div class="txt-clr-title txt-upper">{{ $t(`label.card_mania_prize_header`) }}</div>
            </div>
            <p>{{ $t(`label.card_mania_prize_content`) }}</p>
            <hr />
            <div class="prize-example">
              <div class="txt-clr-title">{{ $t(`label.card_mania_grand_prizes`) }}</div>
              <p>{{ getGrandPrizesListMessage() }}</p>
            </div>
            <div class="prize-example">
              <div class="txt-clr-title">{{ $t(`label.card_mania_consolation_prizes`) }}</div>
              <p>{{ getConsolationPrizesListMessage() }}</p>
            </div>
          </div>
        </div>
        <div><img class="icon-coin" src="/static/image/cardMania/icon-coin-1.png" alt="coin" /></div>
      </div>
      <div class="game-container">
        <div class="title-container">
          <img class="title" src="/static/image/cardMania/title.png" alt="title" />
          <img class="icon-coin" src="/static/image/cardMania/icon-coin-2.png" alt="coin" />
          <img class="mobile-icon-gift" src="/static/image/cardMania/icon-gift.png" alt="gift" />
          <div class="mobile-chip-card-mania">
            <v-chip color="transparent" label x-large style="float: right;" @click="informationBox = true">
              <v-icon x-large left color="white" style="width:26px;">
                mdi-information-outline
              </v-icon>
            </v-chip>
          </div>
        </div>
        <div class="mobile-slogan">
          <div class="chances-box">
            <p>
              <b>{{ play_chances }}</b>
              chances
            </p>
          </div>
          <span>{{ $t(`label.card_mania_title`) }}</span>
          <img class="mobile-icon-coin-1" src="/static/image/cardMania/icon-coin-1.png" alt="coin1" />
          <img class="mobile-icon-coin-2" src="/static/image/cardMania/icon-coin-2.png" alt="coin2" />
        </div>
        <div class="game-frame">
          <div class="game-start-frame" v-if="isStartGame == false">
            <v-btn @click="startGame()" ripple class="white--text">{{ $t(`label.play_now`) }}</v-btn>
            <span>{{ $t(`label.card_mania_play_chance`, [play_chances]) }}</span>
          </div>
          <div class="card" v-for="(card, index) in cards" :key="index" @click="flipCard(index)" :class="{ open: card.open }">
            <div class="card-inner">
              <div class="card-front"></div>
              <div class="card-back">
                <img :src="card.image" :alt="card.image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-prize-container">
        <div class="prizes">
          <div class="prize-box">
            <div class="prize-img">
              <img src="/static/image/cardMania/gadgets.png" alt="gadgets" />
            </div>
            <div class="prize-header">
              <div class="txt-clr-title txt-upper">{{ $t(`label.card_mania_prize_header`) }}</div>
              <p>{{ $t(`label.card_mania_prize_content`) }}</p>
              <hr />
              <div class="prize-example">
                <div class="txt-clr-title">{{ $t(`label.card_mania_grand_prizes`) }}</div>
                <p>{{ getGrandPrizesListMessage() }}</p>
              </div>
              <div class="prize-example">
                <div class="txt-clr-title">{{ $t(`label.card_mania_consolation_prizes`) }}</div>
                <p>{{ getConsolationPrizesListMessage() }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="winner-container">
        <div class="chip-card-mania">
          <v-chip color="transparent" label x-large style="float: right;" @click="informationBox = true">
            <v-icon x-large left color="white" style="width:26px;">
              mdi-information-outline
            </v-icon>
          </v-chip>
        </div>
        <div class="winner">
          <div class="winner-content">
            <div class="winner-tab">
              <v-tabs fixed-tabs centered :hide-slider="true" v-model="tab">
                <v-tab href="#list">{{ $t(`field.winnerList`) }}</v-tab>
                <v-tab href="#record">{{ $t(`field.winningRecord`) }}</v-tab>
              </v-tabs>
              <v-window v-model="tab">
                <v-window-item value="list">
                  <ul class="pl-3 pr-3 winner-list-ul">
                    <li v-for="(winner, index) in cardManiaLogs" :key="index" class="winner-list d-flex align-center">
                      <span class="mx-1 winner-date">{{ winner.created_at | shortDate }}</span>
                      <span class="mx-1 winner-name">{{ GetMaskMember(winner.member_code) }}</span>
                      <span class="mxb-2 winner-rewarded">{{ winner.reward }}</span>
                    </li>
                  </ul>
                </v-window-item>
                <v-window-item value="record">
                  <ul class="pl-3 pr-3 winner-list-ul">
                    <li v-for="(winner, index) in cardManiaLogs" v-if="memberCode != null && winner.member_code == memberCode" :key="index" class="winner-list d-flex align-center">
                      <span class="mx-1 winner-date">{{ winner.created_at | shortDate }}</span>
                      <span class="mx-1 winner-name">{{ winner.member_code }}</span>
                      <span class="mxb-2 winner-rewarded">{{ winner.reward }}</span>
                    </li>
                  </ul>
                </v-window-item>
              </v-window>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Pop-up box -->
    <v-dialog max-width="500px" v-model="isWinPrize" transition="top">
      <v-card color="white" min-height="200" max-width="500px">
        <v-card-title class="primary white--text">
          {{ $t(`label.card_mania_rewards`) }}
        </v-card-title>
        <div class="card-mania-pop-box pa-5">
          <div v-if="prize_name != '' && prize_name != 'miss'">
            <div>
              <img :src="prize_img" alt="winning" />
            </div>
            <h3>{{ $t(`label.card_mania_congratulations`) }}</h3>
            <span>{{ $t(`label.card_mania_win_prizes`, [prize_name]) }}</span>
          </div>
          <div v-else-if="error != ''">
            <h3>{{ error }}</h3>
          </div>
          <div v-else><h3>Ops, sorry please try again next time</h3></div>
        </div>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="resetGame()" ripple outlined class="primary white--text" style="min-width: 100%;">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="500px" v-model="informationBox">
      <v-card color="white" min-height="200" max-height="450" max-width="500" class="mx-auto" style="overflow-y: auto">
        <v-card-title class="primary white--text">
          {{ $t(`label.howToStart`) }}
        </v-card-title>
        <div class="pa-5">
          <ol>
            <li>{{ getCoinMessage() }}</li>
            <li>{{ $t(`label.card_mania_how_to_start_1`) }}</li>
            <li>{{ $t(`label.card_mania_how_to_start_2`) }}</li>
          </ol>
          <b>
            <br />
            {{ $t(`label.card_mania_tnc`) }}
            <br />
            <br />
          </b>
          <ol>
            <li>{{ $t(`label.card_mania_tnc_content_1`) }}</li>
            <li>{{ $t(`label.card_mania_tnc_content_2`) }}</li>
            <li>{{ $t(`label.card_mania_tnc_content_3`) }}</li>
            <li>{{ $t(`label.card_mania_tnc_content_4`) }}</li>
          </ol>
        </div>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="informationBox = false" ripple outlined class="primary white--text" style="min-width: 100%;">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { errorCodeHelper, locale, uiHelper } from '@/util'
import currencyFormat from '@/filters/currency-format'
import { SESSION } from '@/constants/constants'
import { FLIP_CARD_NOW, CARD_MANIA_SETTING, RESET_CARD_MANIA, CARD_MANIA_LOGS, CARD_MANIA_USER_INFO } from '@/store/bonus.module'

export default {
  name: 'CardMania',
  components: {},
  data: () => ({
    memberCode: localStorage.getItem(SESSION.USERNAME),
    play_chances: 0,
    play_chances_msg: '',
    min_deposit: 0,
    max_chances_per_day: 1,
    informationBox: false,
    isWinPrize: false,
    isStartGame: false,
    prize_name: '',
    prize_img: '',
    card_index: -1,
    error: '',
    prize_probability: [],
    cards: [
      { image: '', open: false },
      { image: '', open: false },
      { image: '', open: false },
      { image: '', open: false },
      { image: '', open: false },
      { image: '', open: false },
      { image: '', open: false },
      { image: '', open: false },
      { image: '', open: false }
    ],
    winner_list: [],
    tab: 0
  }),
  created() {
    this.metaTag()
    this.getCardManiaSetting()
    this.getCardManiaLogs()
    this.getCardManiaUserInfo()
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
    },
    flipCard(index) {
      let func = this

      if (!func.isLoggedIn) {
        func.$parent.openLoginDialog()
      } else {
        func.card_index = index
        func.randomPrize()
      }
    },
    getCoinMessage() {
      return locale.getMessage('label.card_mania_deposit_requirement', [currencyFormat(this.min_deposit, uiHelper.getCurrency()), this.max_chances_per_day])
    },
    getGrandPrizesListMessage() {
      return locale.getMessage('label.card_mania_grand_prizes_list', [
        this.prize_probability[8]?.reward_name,
        this.prize_probability[7]?.reward_name,
        this.prize_probability[6]?.reward_name,
        this.prize_probability[5]?.reward_name,
        this.prize_probability[4]?.reward_name
      ])
    },
    getConsolationPrizesListMessage() {
      return locale.getMessage('label.card_mania_consolation_prizes_list', [
        this.prize_probability[3]?.reward_name,
        this.prize_probability[2]?.reward_name,
        this.prize_probability[1]?.reward_name,
        this.prize_probability[0]?.reward_name
      ])
    },
    randomPrize() {
      this.$store.dispatch(`${FLIP_CARD_NOW}`)
    },
    getCardManiaLogs() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${CARD_MANIA_LOGS}`, { filter })
    },
    getCardManiaSetting() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${CARD_MANIA_SETTING}`, { filter })
    },
    getCardManiaUserInfo() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${CARD_MANIA_USER_INFO}`, { filter })
    },
    GetMaskMember(membercode) {
      let len = membercode.length
      const last = membercode.substr(0, 3) + '***' + membercode.substr(len - 3, len)
      return last
    },
    startGame() {
      let func = this
      if (!func.isLoggedIn) {
        func.$parent.openLoginDialog()
      } else if (func.play_chances <= 0) {
        func.error = func.play_chances_msg
        func.isWinPrize = true
      } else {
        func.isStartGame = true
      }
    },
    resetGame() {
      let func = this
      func.isWinPrize = false
      func.prize_name = ''
      func.prize_img = ''
      func.card_index = -1
      func.cards.forEach(c => {
        c.open = false
      })
      setTimeout(function() {
        func.cards.forEach(c => {
          c.image = ''
        })
      }, 500)
    }
  },
  computed: {
    cardManiaResult() {
      return this.$store.state.bonus.cardMania.complete
    },
    cardManiaLogs() {
      return this.$store.state.bonus.cardManiaLog
    },
    cardMania() {
      let d = this.$store.state.bonus.cardManiaSettings
      this.end_date = d.end_date
      return d
    },
    cardManiaUserInfo() {
      return this.$store.state.bonus.cardManiaUserInfo
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    }
  },
  watch: {
    cardManiaResult() {
      if (this.$store.state.bonus.cardMania.complete) {
        let func = this
        this.prize_name = this.$store.state.bonus.cardMania.data
        this.error = !this.$store.state.bonus.cardMania.success
          ? errorCodeHelper.getErrorCodeDesc(this.$store.state.bonus.cardMania.code, currencyFormat(this.min_deposit, uiHelper.getCurrency()))
          : ''
        if (this.error != '') {
          func.isWinPrize = true
        } else {
          const prize = this.prize_probability.filter(p => p.reward_name == this.prize_name)
          let arr = this.prize_probability.filter(p => p.reward_name !== this.prize_name)
          arr.sort(() => Math.random() - 0.5)

          const card = this.cards[this.card_index]
          if (!card.open) {
            card.image = prize[0].selected_image_url
            let i = 0
            func.cards.forEach(c => {
              if (c.image == '') {
                c.image = arr[i].unselected_image_url
                i++
              }
            })
            func.prize_name = prize[0].reward_name
            func.prize_img = prize[0].notice_image_url

            setTimeout(function() {
              card.open = true
              setTimeout(function() {
                func.cards.forEach(c => {
                  if (!c.open) {
                    c.open = true
                  }
                })
                setTimeout(function() {
                  func.isWinPrize = true
                  func.isStartGame = false
                }, 1000)
              }, 1500)
            }, 1000)
          }
        }
        this.getCardManiaLogs()
        this.getCardManiaUserInfo()
        this.$store.dispatch(`${RESET_CARD_MANIA}`)
      }
    },
    cardManiaLogs() {
      this.memberCode = localStorage.getItem(SESSION.USERNAME)
      return this.$store.state.bonus.cardManiaLog
    },
    cardMania() {
      let d = this.$store.state.bonus.cardManiaSettings
      this.min_deposit = d.min_deposit
      this.max_chances_per_day = d.max_chances_per_day
      this.end_date = d.end_date
      this.prize_probability = d.prize_probability
      this.informationBox = true
      return d
    },
    cardManiaUserInfo() {
      let d = this.$store.state.bonus.cardManiaUserInfo
      this.play_chances = d.play_chances || 0
      this.play_chances_msg = errorCodeHelper.getErrorCodeDesc(d.msg, currencyFormat(this.min_deposit, uiHelper.getCurrency()))
      return d
    }
  }
}
</script>

<style lang="scss">
.card-mania-bg {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  background: url('/static/image/background/card_mania_bg.jpg');

  @media (max-width: 1240px) {
    height: auto;
    background: url('/static/image/background/mobile_card_mania_bg.jpg');
    background-size: cover;
  }

  .pack-box {
    display: flex;
    width: 1240px;
    margin: 8px auto 56px;

    @media (max-width: 1240px) {
      width: 100%;
      flex-direction: column;
    }

    .prize-container {
      display: block;
      width: 320px;
      max-width: 320px;
      margin: 80px auto 0;
      color: white;

      @media (max-width: 1240px) {
        display: none;
      }

      .chances-box {
        display: flex;
        font-size: 20px;
        width: 188px;
        height: 70px;
        margin: auto;
        align-items: center;
        justify-content: center;
        background: url('/static/image/cardMania/chances-box.png') no-repeat;
        background-size: contain;

        > p {
          margin-bottom: 4px;
          > b {
            font-size: 24px;
            color: #f5cd07;
          }
        }
      }

      .slogan {
        display: flex;
        width: 320px;
        margin: 20px auto 40px;
        font-size: 24px;
        color: white;
        text-align: center;
      }

      > .prizes {
        position: relative;
        width: 100%;
        padding: 3px;
        border-radius: 20px;
        background: linear-gradient(-270deg, rgb(1 96 67) 0%, #32f35b 20%, rgb(1 96 67) 99%);

        > .prizes-content {
          background-color: black;
          border-radius: 20px;
          padding: 12px;

          > .prize-header {
            display: flex;
            font-size: 22px;
            gap: 0 8px;
            line-height: 1.2;
            vertical-align: middle;
            align-items: center;
            margin-bottom: 8px;
            text-align: center;

            > img {
              width: 50%;
              scale: 1.2;
            }
          }

          > .prize-example {
            margin-top: 20px;

            > .txt-clr-title {
              line-height: 1.2;
              font-size: 18px;
            }

            > p {
              margin-bottom: 0;
            }
          }
        }
      }

      .txt-clr-title {
        color: #f5cd07;
        font-weight: 600;
      }

      .txt-upper {
        text-transform: uppercase;
      }

      .icon-coin {
        float: right;
        width: 120px;
        justify-self: right;
        margin-top: 28px;
        margin-right: 32px;
      }
    }

    .game-container {
      width: 100%;
      background-color: transparent;

      .title-container {
        display: flex !important;
        position: relative;

        .title {
          display: flex;
          width: 380px;
          margin: 0 auto -22px;
        }

        .icon-coin {
          position: absolute;
          top: 40px;
          right: -12px;
          width: 68px;

          @media (max-width: 1240px) {
            display: none;
          }
        }

        .mobile-icon-gift {
          display: none;
          @media (max-width: 1240px) {
            display: flex;
            position: absolute;
            top: 12px;
            left: 44px;
            width: 52px;
          }
        }

        .mobile-chip-card-mania {
          display: none;
          @media (max-width: 1240px) {
            display: flex;
            position: absolute;
            right: 0;
          }
        }
      }

      .mobile-slogan {
        display: none;
        position: relative;

        @media (max-width: 1240px) {
          display: block;
          margin-top: 12px;
        }
        > span {
          display: flex;
          width: 380px;
          margin: 12px auto;
          font-size: 18px;
          color: white;
          text-align: center;
        }

        .chances-box {
          display: flex;
          font-size: 18px;
          width: 168px;
          height: 62px;
          margin: 20px auto auto;
          align-items: center;
          justify-content: center;
          background: url('/static/image/cardMania/chances-box.png') no-repeat;
          background-size: contain;

          > p {
            margin-bottom: 4px;
            color: white;
            > b {
              font-size: 22px;
              color: #f5cd07;
            }
          }
        }

        .mobile-icon-coin-1 {
          display: flex;
          position: absolute;
          top: 0;
          left: -20px;
          width: 52px;
        }

        .mobile-icon-coin-2 {
          display: flex;
          position: absolute;
          top: 8px;
          right: -16px;
          width: 52px;
        }
      }

      .game-frame {
        display: flex !important;
        flex-wrap: wrap;
        gap: 10px;
        width: 552px;
        height: 648px;
        padding: 96px 50px 48px;
        margin: 16px auto;
        background: url('/static/image/cardMania/game-frame.png') no-repeat;
        background-size: contain;
        position: relative;

        @media (max-width: 560px) {
          width: 96%;
          height: 112vw;
          padding: 17vw 8vw 12vw;
          margin: 0 auto;
          gap: 3%;
          justify-content: center;
        }

        .game-start-frame {
          display: flex !important;
          width: 526px;
          height: 644px;
          padding-top: 300px;
          padding-left: 30px;
          left: 0;
          top: 2px;
          align-items: center;
          flex-direction: column;
          position: absolute;
          z-index: 1;
          background: url('/static/image/cardMania/game-frame-overlay.png') no-repeat;
          background-size: cover;

          @media (max-width: 560px) {
            width: 99%;
            height: 112vw;
            padding-top: 52vw;
            padding-left: 1vw;
            left: 0;
            top: 0;
          }

          > button {
            width: 340px;
            height: 48px;
            color: white;
            font-size: 20px;
            background-image: linear-gradient(#ffaa2c, #ff7902);

            @media (max-width: 560px) {
              width: 80%;
            }
          }

          > span {
            font-size: 18px;
            margin-top: 8px;
            color: white;
          }
        }

        .card {
          width: 144px;
          height: 158px;
          perspective: 1000px;

          @media (max-width: 560px) {
            width: 30%;
            height: 26vw;
          }
        }

        .card-inner {
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
          transition: all 1s ease;
          transform-style: preserve-3d;
        }

        .card.open .card-inner {
          transform: rotateY(180deg);
        }

        .card-front,
        .card-back {
          width: 100%;
          height: 100%;
          position: absolute;
          backface-visibility: hidden;
        }

        .card-front {
          background: url('/static/image/cardMania/card-back.png');
          background-size: cover;
        }

        .card-back {
          transform: rotateY(180deg);
        }

        .card-back img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .winner-container {
      display: block;
      width: 320px;
      min-width: 320px;
      max-width: 320px;

      @media (max-width: 1240px) {
        width: 92%;
        min-width: 370px;
        max-width: 420px;
        margin: 0 auto;
      }

      > .winner {
        position: relative;
        width: 100%;
        padding: 3px;
        border-radius: 20px;
        background: linear-gradient(-270deg, rgb(1 96 67) 0%, #32f35b 20%, rgb(1 96 67) 99%);
        margin-top: 172px;

        &::before {
          content: '';
          position: absolute;
          top: -102px;
          width: 232px;
          height: 136px;
          left: 44px;
          background: url('/static/image/cardMania/winners.png') no-repeat;
          background-size: cover;
        }

        @media (max-width: 1240px) {
          &::before {
            left: 20%;
          }
        }

        > .winner-content {
          height: 600px;
          background-color: black;
          border-radius: 20px;
          padding: 12px;

          .winner-tab {
            margin-top: 28px;

            .v-tabs-bar {
              border-radius: 24px;
              background-color: #2b2b2b;
            }

            .v-tab {
              font-size: 15px;
              padding: 0;
              text-transform: none;
              border-radius: 24px;
              color: #ffaa2c;
              background-color: #2b2b2b;
            }

            .v-tab--active {
              color: white;
              font-weight: bold;
              background-image: linear-gradient(#ffaa2c, #ff7902);
            }

            .winner-list-ul {
              height: 500px;
            }

            .winner-list {
              height: 28px;
              color: white;
              font-size: 12px;
              justify-content: center;

              .winner-date {
                width: 72px;
                text-align: center;
              }

              .winner-name {
                width: 100px;
                text-align: center;
                white-space: nowrap;

                @media (max-width: 1240px) {
                  width: 128px;
                }
              }

              .winner-rewarded {
                width: 88px;
                font-weight: bold;
                color: #ffaa2c;
                float: right;
                text-align: center;
                white-space: nowrap;

                @media (max-width: 1240px) {
                  width: 100px;
                }
              }
            }
          }
        }
      }

      .chip-card-mania {
        @media (max-width: 1240px) {
          display: none;
        }
      }
    }

    .mobile-prize-container {
      display: none;
      width: 92%;
      min-width: 370px;
      max-width: 420px;
      margin: 40px auto 0;
      color: white;

      @media (max-width: 1240px) {
        display: flex;
      }

      .prizes {
        position: relative;
        width: 100%;
        padding: 2px;
        margin-left: 28px;
        border-radius: 20px;
        background: linear-gradient(-270deg, rgb(1 96 67) 0%, #32f35b 20%, rgb(1 96 67) 99%);

        > .prize-box {
          display: flex;
          background-color: black;
          border-radius: 20px;

          > .prize-img {
            margin: -2px -12px -20px -44px;

            @media (max-width: 1240px) {
              margin: -2px -4px -20px -52px;
            }

            > img {
              max-width: 160px;
              scale: 1.2;

              @media (max-width: 1240px) {
                scale: 1.1;
              }
            }
          }

          > .prize-header {
            display: block;
            padding: 12px 4px 0 16px;
            font-size: 10px;

            p {
              margin-bottom: 8px;
            }

            > .prize-example {
              margin-top: 4px;
            }

            .txt-clr-title {
              color: #f5cd07;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.card-mania-pop-box {
  text-align: center;

  > div {
    display: block;
    > div {
      display: flex;
      justify-content: center;

      > img {
        width: 120px;
        height: 120px;
      }

      > span {
        width: 40px;
        margin-top: 16px;
        font-size: 20px;
        font-weight: bold;
        color: var(--v-secondary-base);
      }
    }
  }

  > h3 {
    text-transform: uppercase;
  }

  > span {
    > b {
      font-size: 20px;
      color: var(--v-secondary-base);
    }
  }
}
</style>
