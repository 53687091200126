<template>
    <div class="transfer-page">
        <v-card :color="isNonBanking? '#5558FF': '#282F5B'"
                :class="['card_container', {'nonBanking' : isNonBanking, 'px-2' : $vuetify.breakpoint.mdAndDown && isNonBanking}]">
            <v-card-title class="pb-0" v-if="!isNonBanking">
                <label class="title_color--text px-5 font-weight-bold text-left text-uppercase">{{
                    $t(`label.transfer`) }}</label>
            </v-card-title>
            <v-card-title class="pb-0" v-else>
                <v-col cols="12" class="pa-0">
                    <label :class="['white--text font-weight-bold text-left text-uppercase', $vuetify.breakpoint.mdAndDown && isNonBanking ? 'px-0':'px-5']">{{
                        $t(`gameVendor.${provider.toLowerCase()}_long`) }} {{$t(`label.balance`)}}</label>
                </v-col>
                <v-col cols="12" class="pa-0">
                    <label :class="['navigator_active--text font-weight-bold text-left text-uppercase',  $vuetify.breakpoint.mdAndDown && isNonBanking ? 'px-0':'px-5']">{{
                        target_balance | currency }}</label>
                </v-col>
            </v-card-title>
            <v-card-subtitle :class="['caption', $vuetify.breakpoint.mdAndDown ? 'px-1' : 'px-3']"
                             v-if="ptGameInfo != null">
                <v-col cols="12" class="font-italic white--text pt-3 pb-0 px-lg-5">
                    {{$t(`label.ptlowbalance`, [provider])}}
                </v-col>
            </v-card-subtitle>
            <v-row>
                <v-col cols="12" :lg="isNonBanking ? 10 : 7" class="mx-auto">
                    <v-form ref="transferForm">
                        <div :class="isNonBanking ? 'pb-0': $vuetify.breakpoint.mdAndDown ? 'px-4 pb-2' : 'pb-2'">
                            <v-select
                                    class="input-field"
                                    outlined
                                    :label="$t(`field.from_wallet`)"
                                    v-model.trim='from_wallet'
                                    color="navigator_active"
                                    :items='walletList'
                                    item-value='walletCode'
                                    item-text='walletCode'
                                    @change="this.updateBalance"
                                    return-object
                            >
                                <template slot='selection' slot-scope='{ item }'>
                                    {{ $t(`gameVendor.${item.walletCode.toLowerCase()}_long`) }}
                                </template>
                                <template slot='item' slot-scope='{ item }'>
                                    {{ $t(`gameVendor.${item.walletCode.toLowerCase()}_long`) }} - {{ item.balance |
                                    currency }}
                                </template>
                            </v-select>
                        </div>
                        <div :class="isNonBanking ? 'pb-0': $vuetify.breakpoint.mdAndDown ? 'px-4 pb-2' : 'pb-2'">
                            <v-select
                                    class="input-field"
                                    outlined
                                    :label="$t(`field.to_wallet`)"
                                    :rules="validator.walletTransferRules(from_wallet, to_wallet)"
                                    v-model.trim='to_wallet'
                                    color="navigator_active"
                                    :disabled="isNonBanking"
                                    :items='isNonBanking ? specificWalletList : walletList'
                                    item-value='walletCode'
                                    item-text='walletCode'
                                    return-object
                            >
                                <template slot='selection' slot-scope='{ item }'>
                                    {{ $t(`gameVendor.${item.walletCode.toLowerCase()}_long`) }}
                                </template>
                                <template slot='item' slot-scope='{ item }'>
                                    {{ $t(`gameVendor.${item.walletCode.toLowerCase()}_long`) }} - {{ item.balance |
                                    currency }}
                                </template>
                            </v-select>
                        </div>
                        <div :class="isNonBanking ? 'pb-0': $vuetify.breakpoint.mdAndDown ? 'px-4 pb-2' : 'pb-2'">
                            <app-form-field
                                    v-model="transfer_amount"
                                    :label="$t(`field.transfer_amount`)"
                                    :rules="validator.transferAmountRules(2)"
                            ></app-form-field>
                        </div>
                        <div :class="isNonBanking ? 'pb-0': $vuetify.breakpoint.mdAndDown ? 'px-4 pb-4' : 'pb-4'">
                            <app-button :title="$t(`label.submit`)" :action="this.submitTransfer"></app-button>
                        </div>
                    </v-form>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
    import {formValidator, locale, uiHelper, errorCodeHelper} from '@/util'
    import {TRANSFER_WALLET} from "@/store/transfer.module";
    import {RESET_TRANSFER_WALLET} from "@/store/transfer.module";
    import {MEMBER_WALLET} from "@/store/member.module";

    export default {
        props: {
            ptGameInfo: {
                type: Object,
                default: null
            },
            provider: {
                type: String,
                default: '',
            },
            isNonBanking: {
                type: Boolean,
                default: false
            }
        },
        name: "transfer",
        data: () => ({
            from_wallet: '',
            to_wallet: '',
            transfer_amount: 0,
            target_balance: 0,
            validator: formValidator,
        }),
        computed: {
            specificWalletList() {
                let wallets = this.$store.state.member.walletBalance;
                if (this.isNonBanking) {
                    wallets = _.filter(wallets.wallets, (el) => {
                        return el.walletCode == this.provider.toUpperCase();
                    })
                    return _.orderBy(wallets, 'isPrimary', 'desc');
                }
                return _.orderBy(wallets.wallets, 'isPrimary', 'desc');
            },
            walletList() {
                let wallets = this.$store.state.member.walletBalance;
                if (this.isNonBanking) {
                    wallets = _.filter(wallets.wallets, (el) => {
                        return el.walletCode != this.provider.toUpperCase();
                    })
                    return _.orderBy(wallets, 'isPrimary', 'desc');
                }
                return _.orderBy(wallets.wallets, 'isPrimary', 'desc');
            },
            specificWalletBalance() {
                this.target_balance = 0;
                return this.$store.state.member.walletBalance;
            },
            transferResponse() {
                return this.$store.state.transfer.transferResult.complete
            },
        },
        watch: {
            provider() {
                this.refreshLocalWallet(this.provider);
            },
            specificWalletBalance() {
                if (this.provider != '') {
                    let wallet = _.filter(this.$store.state.member.walletBalance.wallets, ['walletCode', this.provider.toUpperCase()]);
                    let first_wallet = _.first(wallet);
                    this.target_balance = first_wallet.balance;
                    this.from_wallet = this.walletList.find(item => item.balance > 0 && item.walletCode != this.provider.toUpperCase())
                    if (this.from_wallet == null) {
                        this.from_wallet = this.walletList.find(item => item.walletCode == "MAIN")
                    }
                }
                return this.$store.state.member.walletBalance;
            },
            walletList() {
                let wallets = this.$store.state.member.walletBalance;
                if (this.isNonBanking) {
                    wallets = _.filter(wallets.wallets, (el) => {
                        return el.walletCode != this.provider.toUpperCase();
                    })
                    return _.orderBy(wallets, 'isPrimary', 'desc');
                }
                return _.orderBy(wallets.wallets, 'isPrimary', 'desc');
            },
            specificWalletList() {
                let wallets = this.$store.state.member.walletBalance;
                if (this.isNonBanking) {
                    wallets = _.filter(wallets.wallets, (el) => {
                        return el.walletCode == this.provider.toUpperCase();
                    })
                    this.to_wallet = wallets.find(item => item.walletCode == this.provider.toUpperCase())
                    this.updateBalance()
                    return _.orderBy(wallets, 'isPrimary', 'desc');
                }
                return _.orderBy(wallets.wallets, 'isPrimary', 'desc');
            },
            transferResponse() {
                if (this.$store.state.transfer.transferResult.complete) {
                    if (this.$store.state.transfer.transferResult.success) {
                        this.popupTransferSuccess(this.$store.state.transfer.transferResult);
                    } else {
                        this.popupTransferFail(this.$store.state.transfer.transferResult);
                    }
                    this.$store.dispatch(`${RESET_TRANSFER_WALLET}`);
                }
            }
        },
        created() {
            this.$store.dispatch(MEMBER_WALLET)
        },
        methods: {
            refreshLocalWallet(lclProvider) {
                if (this.isNonBanking) {
                    let wallet = _.filter(this.$store.state.member.walletBalance.wallets, ['walletCode', lclProvider.toUpperCase()]);
                    let first_wallet = _.first(wallet);
                    this.target_balance = first_wallet.balance;
                }
            },
            popupTransferFail(data) {
                let dialog = this.initializePageDialogProperty()
                dialog.title = locale.getMessage(`label.transfer`)
                dialog.message.push(locale.getMessage(`message.transfer_fail`))
                dialog.message.push(locale.getMessage('field.error_code') + ' : ' + data.code)
                dialog.message.push(locale.getMessage('field.error_msg') + ' : ' + errorCodeHelper.getErrorCodeDesc(data.code))

                dialog.button.push({
                    title: locale.getMessage(`label.ok`),
                    action: () => this.refreshWallet()
                })

                this.openPageDialog(dialog, false)
            },
            openPageDialog(dialog, successTransfer) {
                if (this.ptGameInfo != null) {
                    if (successTransfer) {
                        this.$eventHub.$emit('close-quick-transfer-dialog', true)
                    } else {
                        this.$eventHub.$emit('open-page-dialog', dialog)
                    }
                } else {
                    this.$eventHub.$emit('open-page-dialog', dialog)
                }
            },
            closePageDialog() {
                this.$eventHub.$emit('close-page-dialog', true)
            },
            initializePageDialogProperty() {
                let initPageDialog = {
                    title: locale.getMessage(`label.system`),
                    dialogXButton: this.refreshWallet,
                    messageTitle: '',
                    message: [],
                    button: []
                }
                return initPageDialog
            },
            popupTransferSuccess(data) {
                let dialog = this.initializePageDialogProperty()
                dialog.title = locale.getMessage(`label.transfer`)
                dialog.message.push(locale.getMessage(`message.transfer_success`))
                dialog.message.push(locale.getMessage('field.trx_code') + ' : ' + data.trx_code)
                dialog.message.push(locale.getMessage('field.from_wallet') + ' : ' + data.from_wallet)
                dialog.message.push(locale.getMessage('field.to_wallet') + ' : ' + data.to_wallet)
                dialog.message.push(locale.getMessage('field.transfer_amount') + ' : ' + data.amount)

                dialog.button.push({
                    title: locale.getMessage(`label.ok`),
                    action: () => this.refreshWallet()
                })

                this.openPageDialog(dialog, true)
            },
            refreshWallet() {
                this.closePageDialog()
                if (this.isNonBanking) {
                    this.transfer_amount = 0;
                } else {
                    this.$refs.transferForm.reset()
                }
                this.$store.dispatch(MEMBER_WALLET)
            },
            updateBalance() {
                this.transfer_amount = isNaN(parseInt(this.from_wallet.balance)) ? 0 : parseInt(this.from_wallet.balance)
            },
            getWalletName(data) {
                return locale.getMessage(`gameVendor.${data.walletCode.toLowerCase()}_long`) + '-' + this.$options.filters.currency(data.balance);
            },
            updateMoneyAmount(data) {
                this.transfer_amount = data.replace(/^0+/, '')
            },
            submitTransfer() {
                if (this.$refs.transferForm.validate()) {
                    let obj = {
                        from_wallet: this.from_wallet.walletCode,
                        to_wallet: this.to_wallet.walletCode,
                        amount: this.transfer_amount
                    }
                    this.$store.dispatch(`${TRANSFER_WALLET}`, {obj})
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .card_container {
        margin-top: 70px;
        border-radius: 20px;

        &.nonBanking {
            margin-top: 0;
        }
    }

    @media (max-width: 599px) {
        .card_container {
            margin-top: 20px;
            border-radius: 20px;
        }
    }
</style>