import { locale } from '@/util'

export default function genderDesc(value) {
  let desc = ''
  if (value != undefined && value != null) {
    desc = locale.getMessage(`enumGender[0].${value}`)
  }

  return desc
}
