<template>
  <v-app>
    <component :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script>
import {uiHelper, locale} from '@/util'
import {SHARED, CACHE_KEY, SESSION} from '@/constants/constants'
import {GAME_PROVIDERS_TYPE_CONFIG} from '@/store/game.module'
import {ROUTE_NAME} from "@/constants/route.constants";

export default {
  name: 'App',
  // metaInfo: {
  //   title: `${locale.getMessage('meta.default_title')}`,
  //   titleTemplate: `%s | ${uiHelper.getHost()}`,
  //   meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.default_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.default_keyword')}` }]
  // },
  computed: {
    layout() {
      return this.$route.meta.layout || SHARED.BLANK_LAYOUT
    }
  },
  data: () => ({
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`)
  }),
  async created() {
    this.browserDetec()
    await this.getGameProviderTypeInfo()
    this.initializePageLanguage()
  },
  methods: {
    browserDetec() {
      let browser_detail = navigator.userAgent
      if (
        browser_detail.indexOf('MSIE') > -1 ||
        browser_detail.indexOf('MZBrowser') > -1 ||
        browser_detail.indexOf('UBrowser') > -1 ||
        browser_detail.indexOf('.NET') > -1
      ) {
        window.location.href = '/static/html/error/browser-not-support.html'
      }
    },
    async getGameProviderTypeInfo() {
      if(!this.storageGameProviderType){
        let providerTypeConfigObj = {
          currency: uiHelper.getCurrency(),
          platform: uiHelper.getPlatform()
        }
        await this.$store.dispatch(`${GAME_PROVIDERS_TYPE_CONFIG}`, {providerTypeConfigObj})
      }
    },
    initializePageLanguage() {
      let language = uiHelper.getCookie('language') || uiHelper.getLanguage();
      let path = window.location.pathname;
      let reg = /(en|bn|hi|ne)-[a-zA-Z]{2}/g;
      let currCd = uiHelper.getCurrency()
      let arrReg = path.match(reg); // Use match() to find all matches

      if (arrReg && arrReg.length > 0) {
        let regionLocale = arrReg[0];
        language = regionLocale.substring(0, 2);
        let region = regionLocale.substring(3).toUpperCase();
        // Replace 'bn' with 'bd'
        if (language === 'bn' && region === 'BD') {
          language = 'bd';
        } else if (language === 'en' && region === 'IN') {
          language = 'enin'; // Change 'en' to 'enin' for 'en-IN'
        } else if (language === 'en' && region === 'NP') {
          language = 'enne'; // Change 'en' to 'enin' for 'en-IN'
        }
        if (region === 'BD') {
          currCd = 'BDT'
        } else if (region === 'IN') {
          currCd = 'INR'
        } else if (region === 'NP') {
          currCd = 'NPR'
        }

        if(!uiHelper.isAuthenticated()){
          //before login with lang-cur path
          uiHelper.setCurrency(currCd);
          // Set the current language and cookies
          uiHelper.removeCookie('regionLocale');
          uiHelper.removeCookie('language');
          locale.setCurrentLanguage(language);
          uiHelper.setCookie(SESSION.REGION_LOCALE, regionLocale);
          uiHelper.setCookie(SESSION.LANGUAGE, language);
        }else{
          //loggedin with lang-cur path
          let memberRegionLocale = uiHelper.getCookie(SESSION.REGION_LOCALE);
          locale.setCurrentLanguage(language);
          uiHelper.setCookie(SESSION.LANGUAGE, language);
          // this.$router.push({name: ROUTE_NAME.HOME, params: { lang: memberRegionLocale } });

        }
      } else {
        //without any lang-cur path
        // Fallback to the default language
        locale.setCurrentLanguage(language);
        uiHelper.setCookie(SESSION.LANGUAGE, language);
      }
    }
  }
}
</script>

<style lang="scss">
// html {
//   scroll-behavior: smooth;
//   overflow-y: auto !important;
// }

* {
  word-break: normal;
}

.page {
  background-color: #2e2e2e;
}

.full-width {
  width: 100%;
}

.full-height,
html,
body,
#app,
.page {
  overflow: auto;
}

.dialog-card {
  overflow-x: hidden;
}

/* Pagination Css */
.v-pagination {
  margin: 15px 0px;

  li {
    button {
      margin: 0px;
      border-radius: 0px !important;
      box-shadow: none !important;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-left: 1px solid;
      border-color: var(--v-base_2-base);
      min-height: 40px;
      min-width: 40px !important;
      font-size: 16px;
    }

    &:last-child {
      button {
        border-right: 1px solid;
        border-color: var(--v-base_2-base);
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
    }

    &:first-child {
      button {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
      }
    }
  }
}

/* Table CSS */
// .v-data-table__wrapper {
//   table {
//     .v-data-table-header-mobile {
//       display: none;
//     }
//     .v-data-table-header {
//       tr {
//         background-color: var(--v-base_3-base);
//         th {
//           span {
//             color: var(--v-base-base);
//           }
//         }
//       }
//     }
//     tbody {
//       tr:nth-child(2n) {
//         background-color: #2d3454 !important;
//       }
//     }
//     td,
//     th {
//       border-bottom: 0px !important;
//     }
//   }
// }

/* Pagination Css */
.v-pagination {
  margin: 15px 0px;

  li {
    button {
      margin: 0px;
      border-radius: 0px !important;
      box-shadow: none !important;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-left: 1px solid;
      border-color: var(--v-base_2-base);
      min-height: 40px;
      min-width: 40px !important;
      font-size: 16px;
    }

    &:last-child {
      button {
        border-right: 1px solid;
        border-color: var(--v-base_2-base);
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
    }

    &:first-child {
      button {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
      }
    }
  }
}
</style>
