<template>
  <v-dialog persistent :value="dialogShow" :max-width="maxWidth" style="position:relative;" content-class="box_instant">

    <v-icon size="30px" :class="['dialog-close-icon white--text close_instant']" color="white" @click="closeAction" v-if="closeAction != null">mdi-close-circle-outline</v-icon>
    <v-card class="dialog-card" style="border-radius:10px;" color="white">
      <v-row no-gutters class="dialog-header">
        <v-carousel cycle height="auto" interval="8000" hide-delimiter-background :hide-delimiters="arrayInfo.length <= 1" :show-arrows="arrayInfo.length > 1" show-arrows-on-hover>
          <v-carousel-item v-for="(k, i) in arrayInfo" :key="i">
            <v-sheet height="100%" color="transparent">
              <v-row @click="goToPromoPage()" class="fill-height" align="center" justify="center" no-gutters>
                <label
                    class="dialog-title text-capitalize d-block full-width white--text primary pa-2 max_height_setting">
                  {{ $t(`label.important_announcement`) }}
                </label>
                <v-col cols="12" class="pa-0 popupImage" v-html="k.message.replace(/<[p>]*>/g, ' ').replace(/<[/p>]*>/g, ' ')"></v-col>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'

export default {
  name: 'InstantPopupDialog',
  props: {
    arrayInfo: {
      type: Array,
      required: false,
      default: null
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 500
    },
    closeAction: {
      required: false,
      default: null
    },
    dialogShow: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  methods: {
    goToPromoPage() {
      this.closeAction()
      this.$router.push({
        name: ROUTE_NAME.PROMOTION
      })
    }
  }
}
</script>

<style>
.box_instant_login {
  height: 450px !important;
  overflow: auto !important;
  box-shadow: none !important;
}

.close_instant {
  position: absolute;
  width: auto;
  float: right;
  top: 17px;
  right: 2%;
  z-index: 99999;
}

.v-dialog.box_instant {
  box-shadow: none !important;
  height: 544px;
}

.popupImage {
  text-align: center;
}

.popupImage > img {
  text-align: center;
  width: 430px;
  height: auto;
  padding: 5px;
  border-radius: 20px;
}
.popupImage img {
  text-align: center;
  width: 430px;
  height: auto;
  padding: 5px;
  border-radius: 20px;
}

@media (max-width: 750px) {
  .popupImage > img {
    width: 385px;
  }
  .popupImage img {
    width: 385px;
  }
  .close_instant {
    right: 20px;
  }
}

@media (max-width: 450px) {
  .popupImage > img {
    width: 305px;
  }
  .popupImage img {
    width: 305px;
  }
  .close_instant {
    right: 5px;
  }
}
</style>
