import { historyService } from '@/services'
import { SHARED_LOADING, SHARED_UNLOADING } from '@/store/shared.module'
import { SESSION, SHARED } from '@/constants/constants'
import router from '@/router'

const MODULE_NAME = 'history/'

const A_GET_HISTORY_OVERVIEW = 'getHistoryOverview'
const A_GET_HISTORY_DETAIL = 'getHistoryDetail'

export const HISTORY_OVERVIEW = MODULE_NAME + A_GET_HISTORY_OVERVIEW
export const HISTORY_DETAIL = MODULE_NAME + A_GET_HISTORY_DETAIL

const M_GET_HISTORY_OVERVIEW = 'setHistoryOverview'
const M_GET_HISTORY_DETAIL = 'setHistoryDetail'

const state = {
  historyOverview: {
    data: [],
    pagination: {}
  },
  historyDetail: {
    data: [],
    pagination: {},
    count: {}
  }
}

const getters = {}

const actions = {
  [A_GET_HISTORY_OVERVIEW]({ dispatch, commit }, { playerHistoryOverviewObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    historyService.getHistoryOverview(playerHistoryOverviewObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_GET_HISTORY_OVERVIEW, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_GET_HISTORY_OVERVIEW, { result })
      }
    )
  },
  [A_GET_HISTORY_DETAIL]({ dispatch, commit }, { playerHistoryDetailObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    historyService.getHistoryDetail(playerHistoryDetailObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_GET_HISTORY_DETAIL, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_GET_HISTORY_DETAIL, { result })
      }
    )
  }
}

const mutations = {
  [M_GET_HISTORY_OVERVIEW](state, { result }) {
    if (result.success) {
      state.historyOverview.data = result.data
      state.historyOverview.pagination = result.pagination
    } else {
      state.historyOverview.data = []
      state.historyOverview.pagination = {}
    }
  },
  [M_GET_HISTORY_DETAIL](state, { result }) {
    if (result.success) {
      state.historyDetail.data = result.data
      state.historyDetail.count = result.count
      state.historyDetail.pagination = result.pagination
    } else {
      state.historyDetail.data = []
      state.historyDetail.count = {}
      state.historyDetail.pagination = {}
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
