import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import HistoryIcon from '@/components/CustomIcon/HistoryIcon.vue'
import SpecialIcon from '@/components/CustomIcon/SpecialIcon.vue'
import RewardsIcon from '@/components/CustomIcon/RewardsIcon.vue'
import SocialIcon from '@/components/CustomIcon/SocialIcon.vue'
import SettingIcon from '@/components/CustomIcon/SettingIcon.vue'
import TierIcon from '@/components/CustomIcon/TierIcon.vue'
import BdtIcon from '@/components/CustomIcon/BDTIcon.vue'
import InrIcon from '@/components/CustomIcon/INRIcon.vue'
import NprIcon from '@/components/CustomIcon/NPRIcon.vue'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      customSpecial: {
        component: SpecialIcon
      },
      customHistory: {
        component: HistoryIcon
      },
      customRewards: {
        component: RewardsIcon
      },
      customSocial: {
        component: SocialIcon
      },
      customSetting: {
        component: SettingIcon
      },
      customTier: {
        component: TierIcon
      },
      customBDT: {
        component: BdtIcon
      },
      customINR: {
        component: InrIcon
      },
      customNPR: {
        component: NprIcon
      }
    }
  },
  theme: {
    dark: false,
    themes: {
      light: {
        base: '#EBEBEB', //light grey
        base_2: '#D5D5D5', //lightgray
        base_3: '#000000', //whitesmoke
        base_4: '#fafafa', //snow
        primary: '#3A3A3A', //darkgrey
        primary_2: '#363AA2', //lightseagree
        primary_3: '#252A6F', //mediumaquamarine
        secondary: '#A48749', //teal
        secondary_2: '#ff0000', //red
        secondary_3: '#0B5376', //gainsboro,
        buttonPrimary: '#FDDD04', //darkcyan
        buttonHover: '#3c7591', //steelblue
        buttonPressed: '#093a52', //darkslategray,
        buttonDisabled: '#d5d5d5', //lightgray
        buttonWhite: '#ffffff',
        title_color: '#000000',
        title_color2: '#ACACAC',
        footer_color: '#EBEBEB',
        newMobilePrimary: '#FFCE01',
        newMobileSecondary: '#0066D1'
      },
      dark: {
        footer_color: '#0D1126',
        base_4: '#333333', //darkslategray
        base_3: '#D5D5D5', //lightgray
        base_2: '#F2F2F2', //whitesmoke
        base: '#fafafa', //snow
        title_color: '#9c9ff3',
        primary: '#4B4FE5', //darkcyan
        primary_2: '#363AA2', //lightseagree
        primary_3: '#252A6F', //mediumaquamarine
        secondary: '#0B5376', //teal
        secondary_2: '#ff0000', //red
        secondary_3: '#0B5376', //gainsboro,
        buttonPrimary: '#0B5376', //darkcyan
        buttonHover: '#3c7591', //steelblue
        buttonPressed: '#093a52', //darkslategray,
        buttonDisabled: '#d5d5d5', //lightgray
        buttonGold_primary: '#FFA15C',
        buttonGold_secondary: '#E2D200',
        navigator_active: '#FFCE5E'
      }
    },
    options: {
      customProperties: true
    }
  },
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 850,
      md: 1024,
      lg: 1350,
      xl: 1650
    },
    scrollBarWidth: 15
  }
})
