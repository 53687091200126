import {cmsService, gameService} from '@/services'
import {SHARED_LOADING, SHARED_UNLOADING} from '@/store/shared.module'
import {locale, uiHelper} from '@/util'
import {CACHE_KEY} from "@/constants/constants";

//define module name
const MODULE_NAME = 'cms/'
//define actions name
const A_GET_ACTIVE_BANNER = 'getActiveBanner'
const A_GET_ACTIVE_BONUS_CONTENT = 'getActiveBonusContent'
const A_GET_ANGPAO_CHANCE = 'getAngpaoChance'
const A_CLAIM_ANGPAO = 'claimAngpao'
const A_RESET_CLAIM_ANGPAO = 'resetClaimAngpao'
const A_GET_BONUS_CMS_CATEGORIES = 'getBonusCmsCategories'

//define dispatch action
export const CMS_BANNER_ACTIVE = MODULE_NAME + A_GET_ACTIVE_BANNER
export const CMS_BONUS_ACTIVE = MODULE_NAME + A_GET_ACTIVE_BONUS_CONTENT
export const ANGPAO_CHANCE_GET = MODULE_NAME + A_GET_ANGPAO_CHANCE
export const ANGPAO_CLAIM = MODULE_NAME + A_CLAIM_ANGPAO
export const RESET_ANGPAO_CLAIM = MODULE_NAME + A_RESET_CLAIM_ANGPAO
export const CMS_CATEGORIES_LIST = MODULE_NAME + A_GET_BONUS_CMS_CATEGORIES

const M_SET_BANNER_ACTIVE = 'setBannerActive'
const M_SET_BONUS_ACTIVE = 'setBonusActive'
const M_FAILURE = 'setFailure'
const M_SET_ANGPAO_CHANCE = 'setAngpaoChance'
const M_SET_CLAIM_ANGPAO = 'setAngpaoClaim'
const M_RESET_CLAIM_ANGPAO = 'resetAngpaoClaim'
const M_SET_BONUS_CATEGORIES = 'setBonusCategories'

const state = {
    bannerContent: [],
    bonusContent: [],
    cmsBonusCategories: [],
    angpaoChance: {
        complete: false,
        success: false,
        chances: 0
    },
    angpaoClaim: {
        complete: false,
        success: false,
        code: 0,
        has_claimed: 0,
        claimed_at: '',
        claim_trx_id: '',
        prize_value: 0,
    }
}

const getters = {}

const actions = {
    [A_CLAIM_ANGPAO]({dispatch, commit}) {
        cmsService.claimAngpao().then(
            data => {
                let result = data
                commit(M_SET_CLAIM_ANGPAO, {result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            },
            error => {
                let result = error
                commit(M_SET_CLAIM_ANGPAO, {result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            }
        )
    },
    [A_RESET_CLAIM_ANGPAO]({commit}) {
        commit(M_RESET_CLAIM_ANGPAO)
    },
    [A_GET_ANGPAO_CHANCE]({dispatch, commit}) {
        cmsService.getAngpaoChance().then(
            data => {
                let result = data
                commit(M_SET_ANGPAO_CHANCE, {result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            },
            error => {
                let result = error
                commit(M_SET_ANGPAO_CHANCE, {result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            }
        )
    },
    [A_GET_ACTIVE_BONUS_CONTENT]({dispatch, commit}, {obj}) {
        let language = obj.language
        let cmsBonusCategoryId = obj.cmsBonusCategoryId
        const currency = obj.currency
        cmsService.getCmsBonus({ cmsBonusCategoryId, currency }).then(
            data => {
                let result = data
                commit(M_SET_BONUS_ACTIVE, {language, result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            },
            error => {
                let result = error
                commit(M_SET_BONUS_ACTIVE, {language, result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            }
        )
    },
    [A_GET_ACTIVE_BANNER]({dispatch, commit}, {obj}) {
        let language = obj.language
        cmsService.getBanner().then(
            data => {
                let result = data
                commit(M_SET_BANNER_ACTIVE, {language, result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            },
            error => {
                let result = error
                commit(M_SET_BANNER_ACTIVE, {language, result})
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            }
        )
    },
    [A_GET_BONUS_CMS_CATEGORIES]({dispatch, commit}, { currencyCode }) {
        cmsService.getBonusCategories({ currency: currencyCode }).then(
            data => {
                let result = data
                commit(M_SET_BONUS_CATEGORIES, { result })
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            },
            error => {
                let result = error
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
            }
        )
    }
}

const mutations = {
    [M_SET_CLAIM_ANGPAO](state, {result}) {
        let d = result.data
        state.angpaoClaim.complete = true;
        state.angpaoClaim.success = result.success;
        state.angpaoClaim.code = result.code;
        if(result.success){
            state.angpaoClaim.claim_trx_id = d.claim_trx_id;
            state.angpaoClaim.claimed_at = d.claimed_at;
            state.angpaoClaim.has_claimed = d.has_claimed;
            state.angpaoClaim.prize_value = d.prize_value;
        }
    },
    [M_RESET_CLAIM_ANGPAO](state) {
        state.angpaoClaim.complete = false;
        state.angpaoClaim.success = false;
        state.angpaoClaim.code = 0;
        state.angpaoClaim.claim_trx_id = '';
        state.angpaoClaim.claimed_at = '';
        state.angpaoClaim.has_claimed = 0;
        state.angpaoClaim.prize_value = 0;
    },
    [M_SET_ANGPAO_CHANCE](state, {result}) {
        if (result.success) {
            let d = result.data
            state.angpaoChance.complete = true
            state.angpaoChance.success = true
            state.angpaoChance.chances = d
        }
    },
    [M_SET_BONUS_CATEGORIES](state, {result}) {
        if (result.success) {
            let d = result.data
            state.cmsBonusCategories = d
        }
    },
    [M_SET_BONUS_ACTIVE](state, {language, result}) {
        let p = []
        if (result.success) {
            let d = result.data
            d.forEach(bonus => {
                let bonus_title = ""
                let bonus_desc = ""
                let bonus_img_desktop = ""
                let bonus_img_mobile = ""
                let bonus_details = null

                let objLocale = bonus.localization[0]
                let bonusImg = bonus.res_img_source[0]

                if (typeof bonus.localization.find(x => x.language == language) != "undefined") {
                    objLocale = bonus.localization.find(x => x.language == language)
                }

                bonus_title = objLocale["title"]
                bonus_desc = objLocale["description"]

                if (typeof bonus.res_img_source.find(x => x.language == language) != "undefined") {
                    bonusImg = bonus.res_img_source.find(x => x.language == language)
                }

                bonus_img_desktop = bonusImg["resource"]["desktop"]["imageurl"]
                bonus_img_mobile = bonusImg["resource"]["mobile"]["imageurl"]

                let has_apply_button = bonus.has_apply_button
                let redirect_to_deposit  = bonus.redirect_to_deposit

                p.push({
                    bonus_id: bonus.bonus_id,
                    title: bonus_title,
                    desc: bonus_desc,
                    desktop_img: bonus_img_desktop,
                    mobile_img: bonus_img_mobile,
                    bonus_details: bonus.bonus_details,
                    content: bonus.content_path,
                    has_apply_button,
                    redirect_to_deposit,
                    bonus_categories: bonus.bonus_categories[0]
                })
            })
            state.bonusContent = p
        }
    },
    [M_SET_BANNER_ACTIVE](state, {language, result}) {
        let p = []

        if (result.success) {
            let d = result.data

            d.forEach(banner => {
                let bannerTitle = ""
                let bannerDescription = ""
                let bannerAction = null
                let bannerActionUrl = ""
                let desktop_banner = ""
                let mobile_banner = ""
                let objLocale = banner.localization[0]
                let bannerObj = banner.res_img_source[0]
                if (typeof banner.localization.find(x => x.languageCode == language) != "undefined") {
                    objLocale = banner.localization.find(x => x.languageCode == language)
                }
                bannerTitle = objLocale['bannerTitle'];
                bannerDescription = objLocale['bannerDesc'];
                bannerAction = banner.banner_action[0];
                bannerActionUrl = banner.banner_action_url
                if (typeof banner.res_img_source.find(x => x.language == language) != "undefined") {
                    bannerObj = banner.res_img_source.find(x => x.language == language)
                }
                desktop_banner = bannerObj["resource"]["desktop"]["imageurl"]
                mobile_banner = bannerObj["resource"]["mobile"]["imageurl"]

                p.push({
                    title: bannerTitle,
                    desc: bannerDescription,
                    action: bannerAction,
                    action_url: bannerActionUrl,
                    desktop_banner: desktop_banner,
                    mobile_banner: mobile_banner
                })
            })
        }
        state.bannerContent = p
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
