<template>
  <div>
    <router-view></router-view>

    <app-dialog :dialogShow="registerDialogShow" :max-width="600" :title="$t(`label.signUp`)" :closeAction="this.closeRegisterDialog">
      <pop-up-register :openLoginDialog="this.openLogin"></pop-up-register>
    </app-dialog>

    <app-dialog :dialogShow="loginDialogShow" :max-width="600" :title="$t(`label.login`)" :closeAction="this.closeLoginDialog">
      <app-login :openRegisterDialog="this.openRegister"></app-login>
    </app-dialog>

    <app-dialog :dialogShow="pageDialogShow" :max-width="450" :title="pageDialog.title" :closeAction="this.pageDialog.dialogXButton">
      <app-page-dialog :dialogMessageTitle="pageDialog.messageTitle" :dialogMessage="pageDialog.message" :dialogButton="pageDialog.button"></app-page-dialog>
    </app-dialog>

    <app-loading-progress ref="loading"></app-loading-progress>
  </div>
</template>
<script>
import AppRegister from '@/components/member/register.vue'
import AppLogin from '@/components/member/login.vue'
import AppPageDialog from '@/components/layout/PageDialog.vue'
import AppDialog from '@/components/dialog.vue'
import AppLoadingProgress from '@/components/layout/LoadingProgress.vue'
import { SESSION } from '@/constants/constants'
import { locale, errorCodeHelper, uiHelper } from '@/util'
import { MEMBER_RESET_LOGIN_STORE, MEMBER_LOGOUT, MEMBER_REFRESH_TOKEN, MEMBER_RESET_LOGOUT_STORE, MEMBER_CHECK_TOKEN_VALIDITY, MEMBER_WALLET } from '@/store/member.module'
import { ROUTE_NAME } from '@/constants/route.constants'
import PopUpRegister from '@/components/member/popupRegister.vue'

export default {
  name: 'blankLayoutMaster',
  components: {
    PopUpRegister,
    AppRegister,
    AppLogin,
    AppPageDialog,
    AppDialog,
    AppLoadingProgress
  },
  data: () => ({
    registerDialogShow: false,
    loginDialogShow: false,
    pageDialogShow: false,
    pageDialog: {
      title: '',
      closePageDialog: () => {},
      messageTitle: '',
      message: [],
      button: []
    },
    memberLink: [
      {
        link: '/account',
        text_key: 'profile',
        requiredLogin: true,
        showOnlyAfterLogin: false
      },
      {
        link: '/affiliate',
        text_key: 'affiliate',
        requiredLogin: false,
        showOnlyAfterLogin: false
      },
      {
        link: '/vip',
        text_key: 'vip',
        requiredLogin: false,
        showOnlyAfterLogin: false
      },
      {
        link: '/bet-record',
        text_key: 'betRecord',
        requiredLogin: true,
        showOnlyAfterLogin: true
      },
      {
        link: '/transactions',
        text_key: 'transactions',
        requiredLogin: true,
        showOnlyAfterLogin: true
      },
      {
        link: '/bank-card',
        text_key: 'bankCard',
        requiredLogin: true,
        showOnlyAfterLogin: true
      },
      {
        link: '/help-center',
        text_key: 'helpCenter',
        requiredLogin: false,
        showOnlyAfterLogin: false
      }
    ]
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    loginResponseComplete() {
      return this.$store.state.member.loginResponse.complete
    },
    logoutResponseComplete() {
      return this.$store.state.member.logoutResponse.complete
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    memberWallet() {
      return this.$store.state.member.walletBalance
    }
  },
  watch: {
    loginResponseComplete() {
      let response = this.$store.state.member.loginResponse
      if (response.complete) {
        this.loginResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_LOGIN_STORE}`)
      }
    },
    logoutResponseComplete() {
      let response = this.$store.state.member.logoutResponse
      if (response.complete) {
        this.logoutResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_LOGOUT_STORE}`)
      }
    }
  },
  created() {
    this.pageDialog = this.initializePageDialogProperty()
    this.memberTokenRoutineChecker()
  },
  methods: {
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    memberTokenRoutineChecker() {
      if (localStorage.getItem(SESSION.TOKEN)) {
        let requiredLoading = true
        this.validateMemberToken()
      }
    },
    validateMemberToken() {
      if (new Date(parseInt(localStorage.getItem(SESSION.TOKEN_EXPIRY))) - new Date() < 60000) {
        // millisecond * second * minutes
        if (new Date() - new Date(parseInt(localStorage.getItem(SESSION.LAST_ACTIVITY_TIME))) > 10800000) {
          const logoutObj = {}
          let sessionTimedOut = true
          this.$store.dispatch(MEMBER_LOGOUT, { logoutObj, sessionTimedOut })
        } else {
          this.$store.dispatch(MEMBER_REFRESH_TOKEN)
        }
      } else {
        let requiredLoading = false
        this.$store.dispatch(MEMBER_CHECK_TOKEN_VALIDITY, { requiredLoading })
      }
    },
    logout() {
      const logoutObj = {
        domain: uiHelper.getHostname(),
        platform: uiHelper.getPlatform()
      }
      let sessionTimedOut = false
      this.$store.dispatch(MEMBER_LOGOUT, { logoutObj, sessionTimedOut })
    },
    getMemberWallet() {
      this.$store.dispatch(MEMBER_WALLET)
    },
    openRegisterDialog() {
      this.registerDialogShow = true
      this.closeLoginDialog()
    },
    closeRegisterDialog() {
      this.registerDialogShow = false
    },
    openLogin() {
      this.closeRegisterDialog(false)
      this.$router.push({ name: ROUTE_NAME.LOGIN })
    },
    openRegister() {
      this.closeLoginDialog()
      this.$router.push({ name: ROUTE_NAME.REGISTER })
    },
    openLoginDialog() {
      this.loginDialogShow = true
      this.closeRegisterDialog()
    },
    closeLoginDialog() {
      this.loginDialogShow = false
    },
    openPageDialog(dialog) {
      this.pageDialog = dialog
      this.pageDialogShow = true
    },
    closePageDialog() {
      this.pageDialogShow = false
      this.initializePageDialogProperty()
    },
    loginResponseCompleteDialog(response) {
      if (response.code != 0) {
        let dialog = this.initializePageDialogProperty()
        dialog.title = locale.getMessage(`label.${response.action.toLowerCase()}`)
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))

        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
        this.openPageDialog(dialog)
      } else {
        this.closeLoginDialog()
        this.memberTokenRoutineChecker()
        this.getMemberWallet()
        this.closeRegisterDialog()
      }
    },
    logoutResponseCompleteDialog(response) {
      if (response.isSessionTimedOut) {
        let dialog = this.initializePageDialogProperty()
        dialog.title = locale.getMessage(`label.${response.action}`)
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))

        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })

        this.openPageDialog(dialog)
      }
    }
  }
}
</script>
