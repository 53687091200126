import { wheelService } from '@/services'
import { SHARED_LOADING, SHARED_UNLOADING } from '@/store/shared.module'

const MODULE_NAME = 'wheel/'

//define actions name
const A_WHEEL = 'getWheel'
const A_MULTIPLEWHEEL = 'getMultipleWheel'
const A_GET_CHANCES = 'getChances'
const A_GET_LOGS = 'getLogs'
const A_GET_MY_LOGS = 'getMyLogs'
const A_SPIN_WHEEL = 'spinWheel'
const A_RESET_SPIN_WHEEL = 'resetSpinWheel'
const A_RESET_MULTIPLE_WHEEL_STATUS = 'resetMultipleWheelStatus'

//define dispatch action
export const WHEEL_MODULE = MODULE_NAME + A_WHEEL
export const MULTIPLEWHEEL_MODULE = MODULE_NAME + A_MULTIPLEWHEEL
export const WHEEL_CHANCES = MODULE_NAME + A_GET_CHANCES
export const WHEEL_LOGS = MODULE_NAME + A_GET_LOGS
export const WHEEL_GET_MY_LOGS = MODULE_NAME + A_GET_MY_LOGS
export const WHEEL_SPIN = MODULE_NAME + A_SPIN_WHEEL
export const RESET_WHEEL_SPIN = MODULE_NAME + A_RESET_SPIN_WHEEL
export const RESET_MULTIPLE_WHEEL_STATUS = MODULE_NAME + A_RESET_MULTIPLE_WHEEL_STATUS

//define mutations name
const M_WHEEL = 'setWheel'
const M_MULTIPLEWHEEL = 'setMultipleWheel'
const M_CHANCES = 'setChances'
const M_LOGS = 'setLogs'
const M_MY_LOGS = 'setMyLogs'
const M_WHEEL_SPIN = 'setSpin'
const M_RESET_WHEEL_SPIN = 'resetSpin'
const M_RESET_MULTIPLE_WHEEL_STATUS = 'resetMultipleWheelStatus'

const state = {
  multipleWheel: null,
  wheelChances: {
    complete: false,
    success: false,
    code: 0,
    chances: 0
  },
  wheelLogs: [],
  wheelMyLogs: [],
  wheelSpin: {
    complete: false,
    sucess: false,
    code: 0,
    win_amount: 0,
    reward_id: 0,
    error: ''
  },
  wheel: {
    id: 0,
    name: '',
    start_date: '',
    end_date: '',
    event_setting: {
      event_id: 0,
      coin_required: 0,
      no_of_rewards: 0,
      main_image_url: '',
      frame_image_url: '',
      start_button_image_url: '',
      home_gif_url: ''
    },
    event_rewards: []
  },
  isMultipleActive: ''
}

//to retrieve data from store state (called by component)
const getters = {}

//to perform some actions related with updating state (called by component)
const actions = {
  [A_RESET_MULTIPLE_WHEEL_STATUS]({ commit }) {
    commit(M_RESET_MULTIPLE_WHEEL_STATUS, { result: null })
  },
  [A_MULTIPLEWHEEL]({ commit }, { filter }) {
    wheelService.getMultipleWheel(filter).then(
      data => {
        let result = data
        commit(M_MULTIPLEWHEEL, { result })
      },
      error => {
        let result = error
        commit(M_MULTIPLEWHEEL, { result })
      }
    )
  },
  [A_SPIN_WHEEL]({ dispatch, commit }, { filter }) {
    wheelService.spinWheel(filter).then(
      data => {
        let result = data
        commit(M_WHEEL_SPIN, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      },
      error => {
        let result = error
        commit(M_WHEEL_SPIN, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      }
    )
  },
  [A_GET_CHANCES]({ dispatch, commit }, { filter }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    wheelService.getChances(filter).then(
      data => {
        let result = data
        commit(M_CHANCES, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      },
      error => {
        let result = error
        commit(M_CHANCES, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      }
    )
  },
  [A_RESET_SPIN_WHEEL]({ commit }) {
    commit(M_RESET_WHEEL_SPIN)
  },
  [A_GET_MY_LOGS]({ dispatch, commit }, { filter }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    wheelService.getMyLogs(filter).then(
      data => {
        let result = data
        commit(M_MY_LOGS, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      },
      error => {
        let result = error
        commit(M_MY_LOGS, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      }
    )
  },
  [A_GET_LOGS]({ dispatch, commit }, { filter }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    wheelService.getLogs(filter).then(
      data => {
        let result = data
        commit(M_LOGS, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      },
      error => {
        let result = error
        commit(M_LOGS, { result })
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
      }
    )
  },
  [A_WHEEL]({ commit }, { filter }) {
    wheelService.getWheel(filter).then(
      data => {
        let result = data
        commit(M_WHEEL, { result })
      },
      error => {
        let result = error
        commit(M_WHEEL, { result })
      }
    )
  }
}

//to update state values (called by actions)
const mutations = {
  [M_MULTIPLEWHEEL](state, { result }) {
    if (result.success) {
      if (result.data != null) {
        // debugger
        state.multipleWheel = result.data
        console.log('is multiWheel result.data.length : ' + result.data.length);
        if (result.data.length > 1) {
          state.isMultipleActive = true
        } else {
          state.isMultipleActive = false
          console.log(state.wheel)
        }
      }
    }
  },
  [M_WHEEL_SPIN](state, { result }) {
    let k = result.data
    if (result.success) {
      state.wheelSpin = {
        complete: true,
        success: result.success,
        code: result.code,
        win_amount: k['event_reward']['win_amount'],
        reward_id: k['event_reward']['id']
      }
    } else {
      state.wheelSpin = {
        complete: true,
        success: false,
        code: 0,
        error: result.message
      }
    }
  },
  [M_RESET_WHEEL_SPIN](state) {
    state.wheelSpin = {
      complete: false,
      success: false,
      code: 0,
      win_amount: 0,
      reward_id: 0
    }
  },
  [M_RESET_MULTIPLE_WHEEL_STATUS](state) {
    state.isMultipleActive = ''
  },
  [M_CHANCES](state, { result }) {
    if (result.success) {
      let k = result.data
      state.wheelChances = {
        complete: true,
        success: result.success,
        code: result.code,
        chances: k.chances
      }
    }
  },
  [M_LOGS](state, { result }) {
    state.wheelLogs = result.data
  },
  [M_MY_LOGS](state, { result }) {
    state.wheelMyLogs = result.data
  },
  [M_WHEEL](state, { result }) {
    if (result.success) {
      // debugger
      let k = result.data
      state.wheel = {
        id: k.id,
        name: k.name,
        start_date: k.start_date,
        end_date: k.end_date,
        event_setting: {
          event_id: k.event_setting.event_id,
          coin_required: k.event_setting.coin_required,
          no_of_rewards: k.event_setting.no_of_rewards,
          main_image_url: k.event_setting.main_image_url,
          frame_image_url: k.event_setting.frame_image_url,
          start_button_image_url: k.event_setting.start_button_image_url,
          home_gif_url: k.event_setting.home_gif_url
        },
        event_rewards: k.event_rewards
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
