import {transferService} from '@/services'
import {SHARED_LOADING, SHARED_UNLOADING} from '@/store/shared.module'

const MODULE_NAME = 'transfer/'

const A_TYPE = 'getTransferType'
const A_HISTORY = 'getTransferHistory'
const A_RESET_HISTORY = 'resetTransactionHistory'
const A_TRANSFER = 'makeTransfer'
const A_RESET_TRANSFER = 'resetTransfer'
const A_SEMI_TRANSFER = 'semiTransfer'
const A_RESTORE = 'restore'
const A_GET_TRANSACTION_HISTORY = 'getTransactionHistory'

export const TRANSFER_TYPE = MODULE_NAME + A_TYPE
export const TRANSFER_HISTORY = MODULE_NAME + A_HISTORY
export const TRANSFER_RESET_HISTORY = MODULE_NAME + A_RESET_HISTORY
export const TRANSFER_WALLET = MODULE_NAME + A_TRANSFER
export const RESET_TRANSFER_WALLET = MODULE_NAME + A_RESET_TRANSFER
export const SEMI_TRANSFER = MODULE_NAME + A_SEMI_TRANSFER
export const WITHDRAWAL_RESTORE = MODULE_NAME + A_RESTORE
export const TRANSACTION_HISTORY = MODULE_NAME + A_GET_TRANSACTION_HISTORY

const M_FAILURE = 'setFailure'
const M_TYPE = 'setTransferType'
const M_HISTORY = 'setTransferHistory'
const M_RESET_HISTORY = 'resetTransactionHistory'
const M_TRANSFER = 'setTransferResult'
const M_RESET_TRANSFER = 'resetTransferResult'
const M_SEMI_TRANSFER = 'setSemiTransfer'
const M_RESTORE = 'setRestore'
const M_SET_TRANSACTION_HISTORY = 'setTransactionHistory'

const state = {
    types: [],
    history: [],
    pagination: {
        pageNumber: 0,
        pageSize: 0,
        lastPage: 0,
        tableRowsCount: 0
    },
    transferResult: {
        success: false,
        complete: false,
        code: 0,
        trx_code: "",
        from_wallet: "",
        to_wallet: "",
        amount: "",
    },
    semiTransferResult: {},
    restoreReult: {},
    transactionHistory: {
        pagination: {},
        data: []
    }
}

const getters = {}

const actions = {
    [A_RESET_TRANSFER]({commit}) {
        commit(M_RESET_TRANSFER)
    },
    [A_TRANSFER]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        transferService.transferWallet(obj).then(
            data => {
                let result = data;
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_TRANSFER, {result})
            },
            error => {
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_FAILURE, {error})
            }
        )
    },
    [A_TYPE]({commit}) {
        transferService.getTransferType().then(
            data => {
                let result = data
                commit(M_TYPE, {result})
            },
            error => {
                commit(M_FAILURE, {error})
            }
        )
    },
    [A_HISTORY]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        let isPagination = obj.isPagination
        transferService.getTransferHistory(obj).then(
            data => {
                let result = data
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_HISTORY, {result, isPagination})
            },
            error => {
                let result = error
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_HISTORY, {result, isPagination})
            }
        )
    },
    [A_RESET_HISTORY]({commit}) {
        commit(M_RESET_HISTORY)
    },
    [A_SEMI_TRANSFER]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        return transferService.semiTransfer(obj).then(
            data => {
                let result = data;
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                console.log("success A_SEMI_TRANSFER")
                commit(M_SEMI_TRANSFER, {result})
            },
            error => {
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                console.log("success A_SEMI_TRANSFER")
                commit(M_FAILURE, {error})
            }
        )
    },
    async [A_RESTORE]({dispatch, commit}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        await transferService.withdrawalRestore().then(
                data => {
                    let result = data
                    console.log("success restore")
                    dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                    commit(M_RESTORE, {result})
                },
                error => {
                    dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                    commit(M_FAILURE, {error})
                }
            )
    },
    [A_GET_TRANSACTION_HISTORY]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        return transferService.getTransactionHistory(obj).then(
            data => {
                let result = data;
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_SET_TRANSACTION_HISTORY, {result})
            },
            error => {
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_SET_TRANSACTION_HISTORY, {error})
            }
        )
    },
}

const mutations = {
    [M_FAILURE](state, {error}) {
        state.transferResult = {
            complete: true,
            success: false,
            code: error.code,
            error: error.error
        };
        console.log(error)
    },
    [M_RESET_TRANSFER](state) {
        state.transferResult = {
            complete: false,
            success: false,
            code: 0,
            amount: 0,
            from_wallet: '',
            to_wallet: '',
            trx_code: ''
        };
    },
    [M_TRANSFER](state, {result}) {
        if (result.success) {
            let d = result.data;
            state.transferResult.complete = true;
            state.transferResult.success = result.success;
            state.transferResult.code = result.code
            state.transferResult.amount = d.amount;
            state.transferResult.from_wallet = d.from_wallet;
            state.transferResult.to_wallet = d.to_wallet;
            state.transferResult.trx_code = d.trx_code;
        }
    },
    [M_TYPE](state, {result}) {
        let p = []
        if (result.success) {
            let d = result.data
            d.forEach(x => {
                p.push({
                    id: x.id,
                    code: x.key.toLowerCase()
                })
            })
        }

        state.types = p
    },
    [M_HISTORY](state, {result, isPagination}) {
        if (result.success) {
            let d = result.data
            state.pagination = {
                pageNumber: result.pagination.current_page,
                pageSize: Number(result.pagination.per_page),
                lastPage: result.pagination.last_page,
                tableRowsCount: result.pagination.total
            }

            let p = []
            d.forEach(x => {
                p.push({
                    transactionId: x.trx_code,
                    amount: x.amount,
                    status: x.status.toLowerCase() == 'success' ? 'success' : 'fail',
                    type: x.type.toLowerCase(),
                    fromSource: x.from_wallet,
                    toSource: x.to_wallet,
                    date: x.created_at,
                    actualStatus: x.status.toLowerCase() == 'success' ? 'success' : 'fail'
                })
            })

            if (isPagination) {
                state.history = p
            } else {
                state.history.concat(p)
            }
        }
    },
    [M_RESET_HISTORY](state) {
        state.history = []
    },
    [M_SEMI_TRANSFER](state, {result}) {
        if (result.success) {
            state.semiTransferResult = result
        }
    },
    [M_RESTORE](state, {result}) {
        if (result.success) {
            state.semiTransferResult = result
        }
    },
    [M_SET_TRANSACTION_HISTORY](state, {result}) {
        if(result.success) {
            state.transactionHistory.data = result.data
            state.transactionHistory.pagination = result.pagination
        } else {
            state.transactionHistory = {
                data: [],
                pagination: {}
            }
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
