import currencySymbolMapp from 'currency-symbol-map'

export default function currencyFormat(value, currency) {
  let result = ''
  let currencySymbol = currencySymbolMapp(currency)

  value = parseFloat(value)

  if (value == 0) {
    result = currencySymbol + ` ${value.toFixed(2).toString()}`
  } else {
    result =
      currencySymbol +
      ` ${value
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  }

  return result
}
