import { apiHelper } from '@/util'
import { SESSION } from '@/constants/constants'

export const userService = {
  changePassword
}

//API urls
const USER = {
  USER: '/user',
  PASSWORD: 'password',
  MEMBER: '/member/details'
}

function changePassword(currentPwd, newPwd) {
  const requestOptions = {
    password: newPwd,
    old_password: currentPwd,
    username: localStorage.getItem(SESSION.USERNAME)
  }

  return apiHelper().patch(`${USER.USER}/${USER.PASSWORD}`, requestOptions)
}
